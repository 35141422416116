import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { treeItemClasses, useTreeItem } from '@mui/x-tree-view/TreeItem';
import CircularProgress from "@mui/material/CircularProgress";
import { Collapse, Typography } from "@mui/material";
import clsx from "clsx";
import "../TreeViewMenu.css";

import {
  useDrawerMenu,
  ACTIONS as DRAWER_MENU_ACTIONS,
} from "../../../providers/DrawerMenuProvider";
import { useNavigate } from "react-router-dom";
import { sectionService } from "../../../services/SectionsService";
import uuid from "react-uuid";
import AreaBlackIcon from "../../../assets/areaBlackIcon.png";
import SectorBlackIcon from "../../../assets/sectorBlackIcon.png";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = {
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  };

  return (
    <div style={style}>
      <Collapse {...props} />
    </div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const CustomContent = forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const navigate = useNavigate();
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const goTo = (path) => {
    navigate(path);
  };

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionAreaClick = (event) => {
      goTo(`/area/${nodeId}`);
      handleSelection(event);
  };

  const handleSelectionSectorClick = (event) => {
      goTo(`/sector/${nodeId}`);
      handleSelection(event);
  };

  const handleSelectionClick = (event) => {
    // setDrawerMenuContext({
    //   action: DRAWER_MENU_ACTIONS.CLOSE_ALL_DRAWER_MENU,
    // });
    if (icon) {
      if (icon.type === "img") {
        let node = nodeId.replace(/\"/g, "").replace(/'/g, '"');
        goTo(`/${icon.props.alt}/${node.split("_")[1]}`);
      }
    }
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div id={nodeId} onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      {label.startsWith("area-") ? (
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          <b>{label.split("area-")[1]}</b>
        </Typography>
      ) : (
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      )}
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired,
};

const StyledTreeItem = styled((props) => (
  <TreeItem
    {...props}
    TransitionComponent={TransitionComponent}
    ContentComponent={CustomContent}
  />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const expandAll = (data, expanded) => {
  for (let i = 0; i < data.length; i++) {
    expanded.push(JSON.stringify(data[i].id));
    if (data[i].children?.length) {
      expandAll(data[i].children, expanded);
    }
  }
  return expanded;
};

const defatultTreeData = [
  {
    id: "area-1",
    nombre: "Áreas",
    children: [],
  },
  {
    id: "sector-2",
    nombre: "Sectores",
    children: [],
  },
];

const SectionMenu = ({ refreshMenu, setRefreshMenu }) => {
  const [data, setData] = useState(defatultTreeData);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [expandedItems] = useState(expandAll(defatultTreeData, []));

  useEffect(() => {
    loadMenu();
  }, []);

  useEffect(() => {
    if (refreshMenu) {
      loadMenu();
      setRefreshMenu(false);
    }
  }, [refreshMenu]);

  const loadMenu = () => {
    setLoadingMenu(true);
    sectionService.getAllAreas().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        let areas = res.data.areas.map(p => ({
          id: `${uuid()}_${p.id}`,
          nombre: p.nombre,
          label: p.nombre,
          icon: (
              <img src={AreaBlackIcon} alt="area" width={18} height={18} />
          ),
        }))
        let treeCopy = [...data];
        treeCopy[0].children = areas;
        setData(treeCopy);
      } else {
        console.error(res);
      }
    });
    sectionService.getAllSectores().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        let sectores = res.data.sectores.map(p => ({
          id: `${uuid()}_${p.id}`,
          nombre: p.nombre,
          label: p.nombre,
          icon: (
              <img src={SectorBlackIcon} alt="sector" width={18} height={18} />
          ),
        }))
        let treeCopy = [...data];
        treeCopy[1].children = sectores;
        setData(treeCopy);
      } else {
        console.error(res);
      }
    });
    setLoadingMenu(false);
  };

  const renderTree = (nodes) => (
      <StyledTreeItem key={nodes.id} nodeId={JSON.stringify(nodes.id)} label={nodes.nombre} icon={nodes.icon || null}>
        {nodes.children?.length
            ? nodes.children.map((node) => renderTree(node, false))
            : null}
      </StyledTreeItem>
  )

  return (
    <>
      {loadingMenu ? (
        <div className="loading">
          <CircularProgress size={20} />
        </div>
      ) : (
        <TreeView
          id="styled-tree-view"
          aria-label="customized"
          defaultExpanded={expandedItems}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
        >
          {data?.map((child) => renderTree(child, true))}
        </TreeView>
      )}
    </>
  );
};

export default SectionMenu;
