const errorHandle = (e) => {
    if (e.message === 'Network Error') {
        return handleMsg({ 'response': { 'status': 'offline' } });
    }
    switch (e.response.status) {
        case 500:
        case 503:
        case 401:
        case 404:
        case 406:
        case 400:
        case 403:
        case 422:
        case 420: {
            return handleMsg(e);
        }
        default:
            return 'Network Error';
    }
};
const handleMsg = (error) => {
    return {
        "message": error.response.data !== undefined ? error.response.data : "Network Error",
        "status": error.response['status']
    }
};

export default errorHandle;