import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import ElementoMenuBtn from "../../../../Dialogs/PlanesCarga/Esteril/ElementoMenuBtn";
import {TableFooter, TablePagination} from "@mui/material";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {toast} from "react-toastify";
import {planesCargaService} from "../../../../../services/PlanesCargaService";
import ImportarDialog from "../../../../Dialogs/PlanesCarga/Esteril/ImportarDialog";
import MostrarPtosLevantamiento from "../MostrarPtosLevantamiento";
import {
    MostrarDetallesProtocoloAvanceEsteril
} from "../../../../Dialogs/PlanesCarga/Esteril/MostrarDetallesProtocoloAvanceEsteril";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#343434",
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const ProtocolosAvance = ({capaPlanCarga}) => {
    const [currentElemento, setCurrentElemento] = useState(null);
    const [currentProtocol, setCurrentProtocol] = useState(null);
    const [currentSelectedProtocol, setCurrentSelectedProtocol] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const [puntosLevantamientoAvance, setPuntosLevantamientosAvance] = useState()
    const [openImportarPtosLevantamientoDialog, setOpenImportarPtosLevantamientoDialog] = useState(false);
    const [openMostrarPtosLevantamientoDialog, setOpenMostrarPtosLevantamientoDialog] = useState(false);
    const [openMostrarDetalles, setOpenMostrarDetalles] = useState(false);
    const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });

    const toastId = React.useRef(null);
    const notify = (mensaje) =>
        (toastId.current = toast(`${mensaje}`, { autoClose: false }));
    const update = (mensaje, tipo) => {
        toast.update(toastId.current, {
            render: `${mensaje}`,
            type: tipo ? tipo : toast.TYPE.INFO,
            autoClose: 5000,
        });
    };

    const handleClickMostrarDetalles = async (punto, index) => {
        setCurrentSelectedProtocol({protocolo: punto, protocoloIndex: index});
        var initDate = moment(capaPlanCarga?.fechaCreacion).subtract(1, "days").format("YYYY-MM-DD");
        var endDate = moment(punto?.fechaEmision).add(2, "days").format("YYYY-MM-DD");
        await planesCargaService.getFilterPlanEsterilById({
            id: capaPlanCarga.id,
            desde: initDate.toString(),
            hasta: endDate.toString()
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentProtocol(res.data.capa.protocolosAvance);
                setOpenMostrarDetalles(true);
            } else {
                console.log(res)
                setOpenMostrarDetalles(false);
                setCurrentProtocol(null);
                setCurrentSelectedProtocol(null);
            }
        });
    }

    const handleCloseMostrarDetalles = () => {
        setOpenMostrarDetalles(false)
    }

    const handleClickImportarPuntosLevantamiento = (punto) => {
        setCurrentElemento(punto);
        setOpenImportarPtosLevantamientoDialog(true);
    }

    const handleClickMostrarPuntosLevantamiento = async (punto) => {
        planesCargaService.getPuntosLevantamientoAvance({
            capaId: capaPlanCarga.id,
            fechaEmision: punto.fechaEmision
        })
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setPuntosLevantamientosAvance(res.data.puntosLevantamientoAvance);
                    setOpenMostrarPtosLevantamientoDialog(true);
                } else {
                    console.error(res);
                }
            })
    }

    const handleCloseImportarDialog = () => {
        setOpenImportarPtosLevantamientoDialog(false);
    };

    const handleCloseMostrarPtosLevantamientoDialog = () => {
        setOpenMostrarPtosLevantamientoDialog(false);
    }

    const handleImportarPtosLevantamiento = ({ficheros}) => {
        handleCloseImportarDialog(false)
        setImportandoDatos({ importando: true, status: "Importando" })
        const formData = new FormData()
        formData.append("FechaEmisionProtocolo", moment.utc(currentElemento.fechaEmision).format("MM/DD/yyyy"))
        formData.append("UploadFile", ficheros)
        notify("Importando Puntos de Levantamiento...")
        planesCargaService.importarPuntosLevantamientoAvance({
            capaId: capaPlanCarga.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                update(
                    "Los Puntos de Levantamiento se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los Puntos de Levantamiento  🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - capaPlanCarga.protocolosAvance.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderTabla = () => {
        if (rowsPerPage > 0) {
            return <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" key="avance1">Id</StyledTableCell>
                            <StyledTableCell align="center" key="avance2">Fecha Emision</StyledTableCell>
                            <StyledTableCell align="center" key="avance3">Observaciones</StyledTableCell>
                            <StyledTableCell align="center" key="avance4">Acciones</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? capaPlanCarga.protocolosAvance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : capaPlanCarga.protocolosAvance
                        ).map((punto, index) => (
                            <StyledTableRow key={punto.id}>
                                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                <StyledTableCell align="center">{punto.fechaEmision ? moment(punto.fechaEmision).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                                <StyledTableCell align="center">{punto.observaciones ? punto.observaciones : '-'}</StyledTableCell>

                                <StyledTableCell align="right">
                                    <ElementoMenuBtn
                                        detallesAction={() => handleClickMostrarDetalles(punto, index)}
                                        importarPuntosLevAction={() => handleClickImportarPuntosLevantamiento(punto)}
                                        verPuntosLevAction={() => handleClickMostrarPuntosLevantamiento(punto)}
                                        disabled={importandoDatos.importando}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>))
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: capaPlanCarga.protocolosAvance.length }]}
                                colSpan={12}
                                count={capaPlanCarga.protocolosAvance.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        } else {
            return <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">No existen Planes de Cargas</StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        }
    }

    return (
        <>
            {renderTabla()}

            {openImportarPtosLevantamientoDialog ?
                <ImportarDialog
                    open={openImportarPtosLevantamientoDialog}
                    handleClose={handleCloseImportarDialog}
                    handleSubmit={handleImportarPtosLevantamiento}
                /> : null}
            {openMostrarPtosLevantamientoDialog ?
                <MostrarPtosLevantamiento
                    open={openMostrarPtosLevantamientoDialog}
                    handleClose={handleCloseMostrarPtosLevantamientoDialog}
                    puntosResponse={puntosLevantamientoAvance}
                /> : null}
            {openMostrarDetalles && (
                <MostrarDetallesProtocoloAvanceEsteril
                    open={openMostrarDetalles}
                    handleClose={handleCloseMostrarDetalles}
                    currentProtocolo={currentProtocol}
                    selectedProtocolo={currentSelectedProtocol}
                />
            )}
        </>
    )
}