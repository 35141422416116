import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const EditGrupoEstacionesDialog = ({ open, handleClose, handleEditEstMet, estacionMetGroup }) => {
  const [name, setName] = useState(estacionMetGroup?.nombre);

  const resetValues = () => {
    setName("");
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const close = () => {
    resetValues();
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Editar Grupo de Estaciones Meteorológicas</DialogTitle>
        <DialogContent style={{ paddingTop: "1rem" }}>
          <TextField
            margin="dense"
            id="name"
            label="Nombre"
            fullWidth
            value={name}
            onChange={handleChangeName}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() =>
                handleEditEstMet({
                ...estacionMetGroup,
                nombre: name,
              })
            }
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default EditGrupoEstacionesDialog;
