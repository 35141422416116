export const truncThree = (number) => {
    const multiplied = number * 1000;
    const trunc = Math.trunc(multiplied).toString();
    const position = trunc.length - 3;
    return (trunc.slice(0, position).length > 0 ? trunc.slice(0, position) : '0') + '.' + trunc.slice(position);
}

export const hexToRGB = (hex, a) => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (a) {
        return {
            r,
            g,
            b,
            a
        }
    } else {
        return {
            r,
            g,
            b
        }
    }
}

export const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}