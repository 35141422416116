import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import './Sector.css';
import Information from '../../../../assets/informationBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import EditSectorDialog from '../../../Dialogs/EditSectorDialog';
import { useParams } from 'react-router-dom';
import { sectionService } from '../../../../services/SectionsService';
import { useNavigate } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import Reload from "../../../../assets/reloadIconBlack.png";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Sector = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sector, setSector] = useState();
  const [area, setArea] = useState();
  const [loadingSector, setLoadingSector] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openEditSectorDialog, setOpenEditSectorDialog] = useState(false);
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    loadSector();
  }, [id]);

  const loadSector = () => {
    setLoadingSector(true);
    sectionService.getSector(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setSector(res.data);
        } else {
          console.error(res);
        }
        setLoadingSector(false);
      })
  };

  useEffect(() => {
    if (sector) {
      sectionService.getArea(sector.areaId)
          .then(res => {
            if (res.status >= 200 && res.status < 300) {
              setArea(res.data);
            } else {
              console.error(res);
            }
            setLoadingSector(false);
          })
    }
  }, [sector])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //eliminar
  const handleCloseDleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleClickDeleteItem = () => {
    handleClose();
    setOpenDeleteItemDialog(true);
  };

  const handleDeleteItem = () => {
    handleCloseDleteItemDialog();
    sectionService.deleteSector(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
          navigate('/');
        } else {
          console.error(res)
        }
      })
  };
  //edit
  const handleCloseEditSectorDialog = () => {
    setOpenEditSectorDialog(false);
  };

  const handleClickEditSector = () => {
    handleClose();
    setOpenEditSectorDialog(true);
  };

  const handleEditSector = (sectorEdited) => {
    handleCloseEditSectorDialog();
    setLoadingSector(true);
    sectionService.updateSector(sectorEdited)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          loadSector();
        } else {
          console.error(res)
        }
      })
  };

  const renderTabla = () => (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID.</StyledTableCell>
              <StyledTableCell align="center">Nombre</StyledTableCell>
              {(area) && <StyledTableCell align="center">Area</StyledTableCell>}
              <StyledTableCell align="center">Ubicación X</StyledTableCell>
              <StyledTableCell align="center">Ubicación Y</StyledTableCell>
              <StyledTableCell align="center">Descripción</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key={id}>
              <StyledTableCell align="center">{id}</StyledTableCell>
              <StyledTableCell align="center">
                {sector?.nombre ? sector.nombre : "-"}
              </StyledTableCell>
              {(area) &&
                  <StyledTableCell align="center">
                    {sector?.areaId ? area.nombre : "-"}
                  </StyledTableCell>
              }
              <StyledTableCell align="center">
                {sector?.ubicacion?.x ? sector.ubicacion.y : "-"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {sector?.ubicacion?.y ? sector.ubicacion.x : "-"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {sector?.descripcion ? sector.descripcion : "-"}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
  );

  return (
    <div className="sector-container">
      {loadingSector ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-sector">
            <h2 className="name-sector">{sector ? sector.nombre : 'Sector inexistente'}</h2>
            {sector ?
                <div className="actionsButtons">
                  <Button
                      onClick={loadSector}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <Button
                    onClick={handleClick}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    <img src={Information} />
                  </Button>
                </div> : null
            }
          </div>
          {sector ?
            <>
              <div className="container-table">{renderTabla()}</div>
              <Menu
                id="section-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                keepMounted
              >

                <MenuItem onClick={handleClickEditSector}>Editar</MenuItem>
                <MenuItem onClick={handleClickDeleteItem}>Eliminar</MenuItem>
              </Menu>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditSectorDialog ?
        <EditSectorDialog
          open={openEditSectorDialog}
          handleClose={handleCloseEditSectorDialog}
          handleEditSector={handleEditSector}
          sector={sector}
        /> : null}

    </div>
  );
}

export default Sector;