import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { treeItemClasses, useTreeItem } from '@mui/x-tree-view/TreeItem';
import { Collapse, Typography } from '@mui/material';
import CoordinationBlackIcon from '../../../assets/coordinationBlack.png';
import clsx from 'clsx';
import '../TreeViewMenu.css'
import { useSystemReferences } from '../../../providers/SystemReferencesProvider';
import { useDrawerMenu, ACTIONS as DRAWER_MENU_ACTIONS } from '../../../providers/DrawerMenuProvider';
import { useNavigate } from "react-router-dom";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function SysRefIcon(props) {
    return (
        <img src={CoordinationBlackIcon} alt="" width={18} height={18}/>
    )
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = {
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    };

    return (
        <div style={style}>
            <Collapse {...props} />
        </div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const CustomContent = forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const navigate = useNavigate();
    const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

    const goTo = (path) => {
        navigate(path);
    }

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {

        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        setDrawerMenuContext({
            action: DRAWER_MENU_ACTIONS.CLOSE_ALL_DRAWER_MENU
        });
        goTo(`/sysRef/${nodeId}`);
        handleSelection(event);
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            // className='custom-tree-item'
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                <b>{label}</b>
            </Typography>
        </div>
    );
});

CustomContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * className applied to the root element.
     */
    className: PropTypes.string,
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label.
     */
    icon: PropTypes.node,
    /**
     * The tree node label.
     */
    label: PropTypes.node,
    /**
     * The id of the node.
     */
    nodeId: PropTypes.string.isRequired,
};

const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} ContentComponent={CustomContent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

const expandAll = (data, expanded) => {
    for (let i = 0; i < data.length; i++) {
        expanded.push(data[i].id);
        if (data[i].sectors?.length) {
            expandAll(data[i].sectors, expanded)
        }
    }
    return expanded;
}

const SysRefMenu = () => {
    const [sysRefContext, setSysRefContext] = useSystemReferences();
    const [data, setData] = useState(sysRefContext.systemReferences);

    const [expandedItems, setExpandedItems] = useState(expandAll(data, []));

    useEffect(() => {
        const { systemReferences } = sysRefContext;
        setData(systemReferences);
    }, [sysRefContext.systemReferences])

    const renderTree = (nodes) => (
        <StyledTreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} icon={nodes.icon || null}>
            {nodes.sectors?.length
                ? nodes.sectors.map((node) => renderTree(node, false))
                : null}
        </StyledTreeItem>
    );

    return (
        <TreeView
            id="styled-tree-view"
            aria-label="customized"
            defaultExpanded={expandedItems}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<SysRefIcon />}
            // defaultEndIcon={<MinusSquare />}
            sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
            {data?.map((child) => renderTree(child, true))}
        </TreeView>
    );
}

export default SysRefMenu;