import React from "react";
import Grid from "@mui/material/Grid";
import '../Index.css'
import {Card, CardContent, Divider, Skeleton} from "@mui/material";
import {    
    QueryBuilder,
    WifiTetheringOffOutlined,
    WifiTetheringOutlined
} from "@mui/icons-material";
import moment from "moment";
import SismoIcon from "../../assets/earthquakeBlack.png";
import RevanchaBlackIcon from "../../assets/slopeBlack.png";

const DashboardRigth = ({aclerometroDataDashBoard, sismosDataDashboard, revanchasDataDashboard}) => { 
    return ( 
        <>
        <div className="dashboard-card-right-top">
                                        <div className="dashboard-info-card-acelerometro">
                                            <h3>Acelerómetros</h3>

                                            {aclerometroDataDashBoard && aclerometroDataDashBoard.length > 0
                                                ?
                                                <Grid container spacing={{xs: 2}} columns={{xs: 4, sm: 8, md: 12}}>
                                                    {aclerometroDataDashBoard.map((acelerometro, index) => (
                                                        <Grid item xs={2} key={index}>
                                                            <Card>
                                                                <CardContent className="card-acelerometros">
                                                                    <div className="card-acelerometros-header">
                                                                        <span>Estado</span>

                                                                        {(acelerometro.estadoActualizaciones)
                                                                            ? <span style={{
                                                                                paddingLeft: "10px",
                                                                                color: "#00B712",
                                                                                fontWeight: "bold"
                                                                            }}>En Línea</span>
                                                                            : <span style={{
                                                                                paddingLeft: "10px",
                                                                                color: "#CD0000",
                                                                                fontWeight: "bold"
                                                                            }}>F. de Línea</span>
                                                                        }
                                                                    </div>
                                                                    <div className="card-acelerometros-body">
                                                                        <span>{acelerometro.etiqueta}</span>
                                                                        {(acelerometro.estadoActualizaciones)
                                                                            ? <WifiTetheringOutlined
                                                                                style={{
                                                                                    color: "#00B712",
                                                                                    fontWeight: "bold"
                                                                                }}/>
                                                                            : <WifiTetheringOffOutlined
                                                                                style={{
                                                                                    color: "#CD0000",
                                                                                    fontWeight: "bold"
                                                                                }}/>}


                                                                    </div>
                                                                    <Divider/>
                                                                    <div className="card-acelerometros-footer">
                                                                        <QueryBuilder sx={{fontSize: 11}}/>
                                                                        <span
                                                                            style={{paddingLeft: "5px"}}>{moment(acelerometro.latestUpdate).utc().format('DD-MM-YYYY')}</span>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                :
                                                <Grid container spacing={{xs: 2}} columns={{xs: 4, sm: 8, md: 12}}>
                                                    {Array.from(Array(6)).map((_, index) => (
                                                        <Grid item xs={2} key={index}>
                                                            <Card>
                                                                <CardContent className="card-acelerometros">
                                                                    <Skeleton>
                                                                        <div className="card-acelerometros-header">
                                                                        </div>
                                                                    </Skeleton>

                                                                    <Skeleton>
                                                                        <div className="card-acelerometros-body">
                                                                            <span>AC_1</span>
                                                                            <WifiTetheringOutlined/>
                                                                        </div>
                                                                    </Skeleton>

                                                                    <Divider/>
                                                                    <Skeleton>
                                                                        <div className="card-acelerometros-footer">
                                                                            <QueryBuilder sx={{fontSize: 11}}/>
                                                                            <span
                                                                                style={{paddingLeft: "5px"}}>2-04-2024</span>
                                                                        </div>
                                                                    </Skeleton>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }
                                        </div>
                                    </div>

                                    <div className="dashboard-info-card-order">
                                        <div className="dashboard-info-card-first">
                                            <h3>Sismos</h3>

                                            {sismosDataDashboard && sismosDataDashboard.length > 0
                                                &&
                                                <Grid container spacing={{xs: 2}} columns={{xs: 4, sm: 8, md: 12}}>
                                                    <Grid item xs={5}>
                                                        <Card>
                                                            <CardContent className="card-sismos">
                                                                <div className="card-sismos-header">
                                                                    <span>Acomulados hoy</span>
                                                                </div>
                                                                <div className="card-sismos-body">
                                                                    <span>{sismosDataDashboard.length}</span>
                                                                    <img src={SismoIcon} alt="sismos"
                                                                         style={{height: '30px'}}/>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Card>
                                                            <CardContent className="card-acelerometros">
                                                                <div className="card-acelerometros-header">
                                                                    <span>Último registrado</span>
                                                                </div>
                                                                <div className="card-acelerometros-body">
                                                                    <span>{sismosDataDashboard[sismosDataDashboard.length - 1].magnitud.value}</span>
                                                                    <span>{sismosDataDashboard[sismosDataDashboard.length - 1].magnitud.unit}</span>
                                                                </div>
                                                                <Divider/>
                                                                <div className="card-acelerometros-footer">
                                                                    <QueryBuilder sx={{fontSize: 11}}/>
                                                                    <span
                                                                        style={{paddingLeft: "5px"}}>{moment(sismosDataDashboard[sismosDataDashboard.length - 1].fecha).local().format('DD-MM-YYYY hh:mm:ss')}</span>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </div>

                                        <div className="dashboard-info-card-second">
                                            <h3>Revanchas</h3>

                                            {revanchasDataDashboard && revanchasDataDashboard.length > 0
                                                &&
                                                <Grid container spacing={{xs: 2}} columns={{xs: 4, sm: 8, md: 12}}>
                                                    {revanchasDataDashboard.map((revancha, index) => (
                                                        <Grid item xs={5} key={index}>
                                                            <Card>
                                                                <CardContent className="card-acelerometros">
                                                                    <div className="card-acelerometros-header">
                                                                        <span >{revancha.nombre}</span>
                                                                    </div>
                                                                    <div className="card-acelerometros-body">
                                                                        <span>{(revancha.revanchas) ? revancha.revanchas[0].puntosRevancha : "-"}</span>
                                                                        <img src={RevanchaBlackIcon} alt="revanchas"
                                                                             style={{height: '30px'}}/>
                                                                    </div>
                                                                    <Divider/>
                                                                    <div className="card-acelerometros-footer">
                                                                        <QueryBuilder sx={{fontSize: 11}}/>
                                                                        <span
                                                                            style={{paddingLeft: "5px"}}>
                                                                            {(revancha.revanchas) ? moment(revancha.revanchas[0].ultimaActualizacion)
                                                                                .utc().format('DD-MM-YYYY hh:mm:ss') : "-"
                                                                            }</span>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }
                                        </div>
                                    </div>
        </>
    )
}

export default DashboardRigth;