import React, { useState, useRef } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./AdicionarRevanchaDialog.css";
import { FormControl, MenuItem, Select } from "@mui/material";

const AdicionarRevanchaDialog = ({
  open,
  handleClose,
  handleSubmit,
  tiposRevanchas,
}) => {
  const [umbral, setUmbral] = useState(null);
  const [tipoRevancha, setTipoRevancha] = useState(null);

  const handleChange = (event) => {
    const newUmbral = event.target.value !== '' ? parseFloat(event.target.value) : null
    setUmbral(newUmbral);
  };

  const handleChangeTipo = (event) => {
    setTipoRevancha(event.target.value);
  };

  const close = () => {
    handleClose();
  };

  const uploadFiles = () => {
    handleSubmit({ umbral, tipoRevanchaId: tipoRevancha });
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Adicionar Revancha</DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <FormControl sx={{ marginTop: 1, minWidth: 120, width: "100%" }}>
                <InputLabel id="item-type-label">Tipo de Revancha</InputLabel>
                <Select
                  labelId="item-type-select-label"
                  id="item-type-select"
                  value={tipoRevancha}
                  label="Tipo de Revancha"
                  onChange={handleChangeTipo}
                >
                  {tiposRevanchas?.map((tipo, index) => (
                    <MenuItem key={index} value={tipo?.id ? tipo?.id : 0}>
                      {tipo?.nombre ? tipo.nombre : "-"}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  margin="dense"
                  id="umbral"
                  label="Umbral"
                  fullWidth
                  type='number'
                  value={umbral}
                  onChange={handleChange}
                />
              </FormControl>
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button disabled={!tipoRevancha || !umbral} onClick={uploadFiles}>
            Subir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AdicionarRevanchaDialog;
