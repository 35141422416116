import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import "./AddPiezometroDialog.css";
import SeleccionarCoordenadas from "../SeleccionarCoordenadas";
import { getCoordenadas } from "../../../utils/ll2utm";

const AddTipoPiezometroDialog = ({
  open,
  handleClose,
  handleSubmit
}) => {
  const [nombre, setNombre] = useState();

  const handleChangeNombre = (event) => {
    const { id, value } = event.target;
    setNombre(value);
  };

  const close = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>
          Adicionar Tipo de Piezómetro
        </DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <div className="piezometro-container">
                <TextField
                  margin="dense"
                  id="nombre"
                  label="nombre"
                  fullWidth
                  value={nombre}
                  onChange={handleChangeNombre}
                />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button onClick={() => { handleSubmit({ nombre: nombre }); close(); }} >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddTipoPiezometroDialog;
