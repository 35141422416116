// import axios from 'axios';
import axios from "../config/axiosInstance";
import { dtmBaseUrl } from "../config/config";
import errorHandle from "./tratarError";
import { toast } from "react-toastify";

export const dtmService = {
  crearGrupo: async ({ nombre }) => {
    try {
      const result = await axios("/modelos-terreno-digital/api/modelo-terreno-digital/grupos/", {
        method: "post",
        data: { nombre, areaId: 1 },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGrupos: async () => {
    return axios("/modelos-terreno-digital/api/modelo-terreno-digital/grupos", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${id}`, {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: async ({ id, nombre }) => {
    try {
      const result = await axios(`/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${id}`, {
        data: { id, nombre },
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  eliminarGrupo: async (id) => {
    try {
      const result = await axios(`/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${id}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getDTMs: async () => {
    return axios("/modelos-terreno-digital/api/modelo-terreno-digital/grupos", {      
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getDTM: async (id) => {
    try {
      const result = await axios(`/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${id}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  updateDTM: async (data) => {
    try {
      const result = await axios(`/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${data.dtmId}`, {
        data,
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getDtmById: async (id) => {
    return axios(`/api/modelo-terreno-digital/modelo/${id}`, {
      baseURL: dtmBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarDTM: (data) => {
    return axios(
      `/api/modelo-terreno-digital/grupo/${data.grupoId}/modelo/${data.dtmId}`,
      {
        baseURL: dtmBaseUrl,
        method: "delete",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearDTM: async (data) => {
    try {
      const result = await axios("/modelos-terreno-digital/api/modelo-terreno-digital/grupos", {
        method: "post",
        data,
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  descargarDTM: async ({ grupoId, modeloId }) => {
    try {
      const result = await axios(
        `/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${grupoId}/descargar-modelo/${modeloId}`,
        {
          method: "get",
        }
      );
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  /**Capas */
  publicarCapa: async ({ modeloTerrenoDigitalId, nombre, url, creado }) => {
    try {
      const result = await axios("/modelos-terreno-digital/api/modelo-terreno-digital/capas", {
        method: "post",
        data: {
          modeloTerrenoDigitalId,
          nombre,
          url,
          fechaCreado: creado,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  uploadDTM: async ({ idGrupo, formData }) => {
    const toastId = toast.loading("Importando datos");
    return axios(
      `/modelos-terreno-digital/api/modelo-terreno-digital/grupos/${idGrupo}/upload-modelo-terreno-digital`,
      {
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "text/plain",
        },
      }
    )
      .then((result) => {
        toast.update(toastId, {
          render: "Los datos se importaron correctamente",
          type: "success",
          isLoading: false,
        });
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        toast.update(toastId, {
          render: err.message,
          type: "error",
          isLoading: false,
        });
        return err;
      });
  },
  getCapas: async () => {
    return axios("/modelos-terreno-digital/api/modelo-terreno-digital/capas", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`/modelos-terreno-digital/api/modelo-terreno-digital/capas/${id}`, {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, fechaCreado, modeloTerrenoDigitalId}) => {
    return axios(`/modelos-terreno-digital/api/modelo-terreno-digital/capas/${id}`, {
      method: "put",
      data: {
        id,
        nombre,
        url,
        modeloTerrenoDigitalId,
        fechaCreado
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`/modelos-terreno-digital/api/modelo-terreno-digital/capas/${id}`, {
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
