import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const FILE_TYPES = ".xls, .xlsx";
const FILE_TEXT = "Seleccione un archivo (*.xls, *.xlsx)";

const ImportDataEstacionesDialog = ({ open, handleClose, handleSubmit }) => {

  const [fichero, setFichero] = useState();

  const hiddenFileInput = useRef(null);

  const close = () => {
    handleClose();
  }

  const handleClickImportarFicheros = (event) => {
    hiddenFileInput.current.click();
  };

  const cargarArchivos = (event) => {
    setFichero(event.target.files[0]);
  }

  const uploadFiles = () => {
    handleSubmit({ fichero:fichero });
    close();
  }

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Importar datos</DialogTitle>
        <DialogContent className="add-dialog-content" style={{paddingTop: '1rem'}}>
          <input
              ref={hiddenFileInput}
              type='file'
              style={{display: 'none'}}
              accept={FILE_TYPES}
              onChange={cargarArchivos}
          />
          <Button
              size="sm"
              className="rounded mr-2"
              color={"primary"}
              variant="contained"
              onClick={handleClickImportarFicheros}
              style={{marginRight: '8px'}}
          >
            Selecionar
          </Button>
          <span>{fichero ? fichero.name : FILE_TEXT}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            close();
          }}>
            Cancelar
          </Button>
          <Button onClick={uploadFiles}>
            Importar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ImportDataEstacionesDialog;