import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Step, StepButton, StepLabel, Stepper} from "@mui/material";
import Grid from "@mui/material/Grid";

const steps = ['Paso 1', 'Paso 2', 'Paso 3'];

const CreateExcelConfigDialog = ({ open, handleClose, handleSubmit, excelConfig }) => {
  const [noProtocoloRow, setNoProtocoloRow] = useState()
  const [noProtocoloCell,  setNoProtocoloCell] = useState()
  const [fechaEmisionRow, setFechaEmisionRow] = useState()
  const [fechaEmisionCell, setFechaEmisionCell] = useState()
  const [observacionesRow, setObservacionesRow] = useState()
  const [observacionesCell,  setObservacionesCell] = useState()
  const [promEvaluacionActualRow, setPromEvaluacionActualRow] = useState()
  const [promEvaluacionActualCell, setPromEvaluacionActualCell] = useState()
  const [promEspesorCapaRow, setPromEspesorCapaRow] = useState()
  const [promEspesorCapaCell,  setPromEspesorCapaCell] = useState()
  const [promAnchoCapaRow, setPromAnchoCapaRow] = useState()
  const [promAnchoCapaCell, setPromAnchoCapaCell] = useState()
  const [difEvaluacionActualRequeridaRow, setDifEvaluacionActualRequeridaRow] = useState()
  const [difEvaluacionActualRequeridaCell,  setDifEvaluacionActualRequeridaCell] = useState()
  const [puntosRecepcionStartRow, setPuntosRecepcionStartRow] = useState()
  const [kmAnalisisCell, setKmAnalisisCell] = useState()
  const [anteriorCell, setAnteriorCell] = useState()
  const [actualCell, setActualCell] = useState()
  const [espesorCapaCell, setEspesorCapaCell] = useState()
  const [anchoCapaCell,  setAnchoCapaCell] = useState()
  const [diferenciaElevacionActualRequeridaCell, setDiferenciaElevacionActualRequeridaCell] = useState()
  const [toleranciaCell, setToleranciaCell] = useState()

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  useEffect(() => {
    if(excelConfig){
      var config = excelConfig;
      setNoProtocoloRow(config.noProtocoloRow)
      setNoProtocoloCell(config.noProtocoloCell)
      setPromEspesorCapaRow(config.promEspesorCapaRow)
      setPromEspesorCapaCell(config.promEspesorCapaCell)
      setPromAnchoCapaRow(config.promAnchoCapaRow)
      setPromAnchoCapaCell(config.promAnchoCapaCell)
      setPromEvaluacionActualRow(config.promEvaluacionActualRow)
      setPromEvaluacionActualCell(config.promEvaluacionActualCell)
      setFechaEmisionRow(config.fechaEmisionRow)
      setFechaEmisionCell(config.fechaEmisionCell)
      setObservacionesRow(config.observacionesRow)
      setObservacionesCell(config.observacionesCell)
      setDifEvaluacionActualRequeridaRow(config.difEvaluacionActualRequeridaRow)
      setDifEvaluacionActualRequeridaCell(config.difEvaluacionActualRequeridaCell)
      setPuntosRecepcionStartRow(config.puntosRecepcionStartRow)
      setKmAnalisisCell(config.kmAnalisisCell)
      setAnteriorCell(config.anteriorCell)
      setActualCell(config.actualCell)
      setEspesorCapaCell(config.espesorCapaCell)
      setAnchoCapaCell(config.anchoCapaCell)
      setDiferenciaElevacionActualRequeridaCell(config.diferenciaElevacionActualRequeridaCell)
      setToleranciaCell(config.toleranciaCell)
    }
  }, [excelConfig])

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleNoProtocolRow = (event) => {
    setNoProtocoloRow(parseInt(event.target.value));
  }

  const handleNoProtocolCell = (event) => {
    setNoProtocoloCell(parseInt(event.target.value));
  }

  const handlePromEspesorCapaRow = (event) => {
    setPromEspesorCapaRow(parseInt(event.target.value));
  };

  const handlePromEspesorCapaCell = (event) => {
    setPromEspesorCapaCell(parseInt(event.target.value));
  };

  const handlePromAnchoCapaRow = (event) => {
    setPromAnchoCapaRow(parseInt(event.target.value));
  };

  const handlePromAnchoCapaCell = (event) => {
    setPromAnchoCapaCell(parseInt(event.target.value));
  };

  const handlePromEvaluacionActualRow = (event) => {
    setPromEvaluacionActualRow(parseInt(event.target.value));
  };

  const handlePromEvaluacionActualCell = (event) => {
    setPromEvaluacionActualCell(parseInt(event.target.value));
  };

  const handleFechaEmisionRow = (event) => {
    setFechaEmisionRow(parseInt(event.target.value));
  };

  const handleFechaEmisionCell = (event) => {
    setFechaEmisionCell(parseInt(event.target.value));
  };

  const handleObservacionesRow = (event) => {
    setObservacionesRow(parseInt(event.target.value));
  };

  const handleObservacionesCell = (event) => {
    setObservacionesCell(parseInt(event.target.value));
  };

  const handleDifEvaluacionActualRequeridaRow = (event) => {
    setDifEvaluacionActualRequeridaRow(parseInt(event.target.value))
  }

  const handleDifEvaluacionActualRequeridaCell = (event) => {
    setDifEvaluacionActualRequeridaCell(parseInt(event.target.value))
  }

  const handlePuntosRecepcionStartRow = (event) => {
    setPuntosRecepcionStartRow(parseInt(event.target.value))
  }

  const handleKmAnalisisCell = (event) => {
    setKmAnalisisCell(parseInt(event.target.value))
  }

  const handleAnteriorCell = (event) => {
    setAnteriorCell(parseInt(event.target.value))
  }

  const handleActualCell = (event) => {
    setActualCell(parseInt(event.target.value))
  }

  const handleEspesorCapaCell = (event) => {
    setEspesorCapaCell(parseInt(event.target.value))
  }

  const handleAnchoCapaCell = (event) => {
    setAnchoCapaCell(parseInt(event.target.value))
  }

  const handleDiferenciaElevacionActualRequeridaCell = (event) => {
    setDiferenciaElevacionActualRequeridaCell(parseInt(event.target.value))
  }

  const handleToleranciaCell = (event) => {
    setToleranciaCell(parseInt(event.target.value))
  }

  const close = () => {
    handleClose();
  }

  const crearConfiguration = () => {
    handleSubmit({noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
        observacionesCell, promEvaluacionActualRow, promEvaluacionActualCell, promEspesorCapaRow,
        promEspesorCapaCell, promAnchoCapaRow, promAnchoCapaCell, difEvaluacionActualRequeridaRow,
        difEvaluacionActualRequeridaCell, puntosRecepcionStartRow, kmAnalisisCell, anteriorCell,
        actualCell, espesorCapaCell, anchoCapaCell, diferenciaElevacionActualRequeridaCell, toleranciaCell});
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>{(excelConfig) ? 'Editar' : 'Crear' } Configuración del Excel</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {(activeStep) === 0 &&
                      <div>
                        <div>&nbsp;</div>

                        <TextField
                            margin="dense"
                            id="noProtocoloRow"
                            label="Fila No. Protocolo"
                            fullWidth
                            type="number"
                            value={noProtocoloRow}
                            onChange={handleNoProtocolRow}
                        />

                        <TextField
                            margin="dense"
                            id="noProtocoloCell"
                            label="Celda No. Protocolo"
                            fullWidth
                            type="number"
                            value={noProtocoloCell}
                            onChange={handleNoProtocolCell}
                        />


                          <div>&nbsp;</div>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="fechaEmisionRow"
                                label="Fila Fecha Emisión"
                                fullWidth
                                type="number"
                                value={fechaEmisionRow}
                                onChange={handleFechaEmisionRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="fechaEmisionCell"
                                label="Celda Fecha Emisión"
                                fullWidth
                                type="number"
                                value={fechaEmisionCell}
                                onChange={handleFechaEmisionCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="observacionesRow"
                                label="Fila Observaciones"
                                fullWidth
                                type="number"
                                value={observacionesRow}
                                onChange={handleObservacionesRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="observacionesCell"
                                label="Celda Observaciones"
                                fullWidth
                                type="number"
                                value={observacionesCell}
                                onChange={handleObservacionesCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="promEvaluacionActualRow"
                                label="Fila Prom. Eval. Actual"
                                fullWidth
                                type="number"
                                value={promEvaluacionActualRow}
                                onChange={handlePromEvaluacionActualRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="promEvaluacionActualCell"
                                label="Celda Prom. Eval. Actual"
                                fullWidth
                                type="number"
                                value={promEvaluacionActualCell}
                                onChange={handlePromEvaluacionActualCell}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    }
                    {(activeStep) === 1 &&
                        <div>
                          <div>&nbsp;</div>
                          <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid xs={5}>
                              <TextField
                                  margin="dense"
                                  id="promEspesorCapaRow"
                                  label="Fila Prom. Espesor"
                                  fullWidth
                                  type="number"
                                  value={promEspesorCapaRow}
                                  onChange={handlePromEspesorCapaRow}
                              />
                            </Grid>

                            <Grid xs={5}>
                              <TextField
                                  margin="dense"
                                  id="promEspesorCapaCell"
                                  label="Celda Prom. Espesor"
                                  fullWidth
                                  type="number"
                                  value={promEspesorCapaCell}
                                  onChange={handlePromEspesorCapaCell}
                              />
                            </Grid>
                          </Grid>

                          <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid xs={5}>
                              <TextField
                                  margin="dense"
                                  id="promAnchoCapaRow"
                                  label="Fila Promedio Ancho"
                                  fullWidth
                                  type="number"
                                  value={promAnchoCapaRow}
                                  onChange={handlePromAnchoCapaRow}
                              />
                            </Grid>

                            <Grid xs={5}>
                              <TextField
                                  margin="dense"
                                  id="promAnchoCapCell"
                                  label="Celda Promedio Ancho"
                                  fullWidth
                                  type="number"
                                  value={promAnchoCapaCell}
                                  onChange={handlePromAnchoCapaCell}
                              />
                            </Grid>
                          </Grid>

                          <TextField
                              margin="dense"
                              id="difEvaluacionActualRequeridaRow"
                              label="Fila Dif. Eval. Actual y Requerida"
                              fullWidth
                              type="number"
                              value={difEvaluacionActualRequeridaRow}
                              onChange={handleDifEvaluacionActualRequeridaRow}
                          />

                          <TextField
                              margin="dense"
                              id="difEvaluacionActualRequeridaCell"
                              label="Celda Dif. Eval. Actual y Requerida"
                              fullWidth
                              type="number"
                              value={difEvaluacionActualRequeridaCell}
                              onChange={handleDifEvaluacionActualRequeridaCell}
                          />

                          <TextField
                              margin="dense"
                              id="puntosRecepcionStartRow"
                              label="Fila Puntos Recepción"
                              fullWidth
                              type="number"
                              value={puntosRecepcionStartRow}
                              onChange={handlePuntosRecepcionStartRow}
                          />
                        </div>
                    }
                    {(activeStep) === 2 &&
                      <div>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="kmAnalisisCell"
                                label="Celda Km/s Análisis"
                                fullWidth
                                type="number"
                                value={kmAnalisisCell}
                                onChange={handleKmAnalisisCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="anteriorCell"
                                label="Celda Dato Anterior"
                                fullWidth
                                type="number"
                                value={anteriorCell}
                                onChange={handleAnteriorCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="actualCell"
                                label="Celda Dato Actual"
                                fullWidth
                                type="number"
                                value={actualCell}
                                onChange={handleActualCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="espesorCapaCell"
                                label="Celda Espesor Capa"
                                fullWidth
                                type="number"
                                value={espesorCapaCell}
                                onChange={handleEspesorCapaCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="anchoCapaCell"
                                label="Celda Ancho Capa"
                                fullWidth
                                type="number"
                                value={anchoCapaCell}
                                onChange={handleAnchoCapaCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="diferenciaElevacionActualRequeridaCell"
                                label="Celda Dif. Evaluaciones"
                                fullWidth
                                type="number"
                                value={diferenciaElevacionActualRequeridaCell}
                                onChange={handleDiferenciaElevacionActualRequeridaCell}
                            />
                          </Grid>
                        </Grid>

                        <TextField
                            margin="dense"
                            id="toleranciaCell"
                            label="Celda Tolerancia"
                            fullWidth
                            type="number"
                            value={toleranciaCell}
                            onChange={handleToleranciaCell}
                        />
                      </div>
                    }
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button onClick={close}>
                      Cancelar
                    </Button>

                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext}>
                      {activeStep !== steps.length - 1 && 'Next'}
                    </Button>

                    {activeStep === steps.length - 1 &&
                        <Button onClick={crearConfiguration}>
                          Aceptar
                        </Button>
                    }
                  </Box>
                </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default CreateExcelConfigDialog;