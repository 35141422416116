// import axios from 'axios';
import axios from "../config/axiosInstance";
import {batimetriasUrl} from "../config/config";
import errorHandle from "./tratarError";

export const batimetriasService = {
  /* GRUPOS MISIONES BATIMETRIAS */
  crearGrupoBatimetria: async ({ nombre }) => {
    try {
      const result = await axios("/api/GrupoMisionBatimetria", {
        baseURL: batimetriasUrl,
        method: "post",
        data: { nombre, areaId: 1 },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGruposBatimetrias: async () => {
    return axios("/api/GrupoMisionBatimetria", {
      baseURL: batimetriasUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupoBatimetriaById: async (grupoId) => {
    return axios(`/api/GrupoMisionBatimetria/${grupoId}`, {
      baseURL: batimetriasUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  actualizarGrupo: async ({ grupoId, nombre }) => {
    try {
      const result = await axios(`/api/GrupoMisionBatimetria/${grupoId}`, {
        baseURL: batimetriasUrl,
        data: { grupoId, nombre, areaId: 1 },
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  eliminarGrupo: async (grupoId) => {
    try {
      const result = await axios(`/api/GrupoMisionBatimetria/${grupoId}`, {
        baseURL: batimetriasUrl,
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  /* MISIONES BATIMETRIS */
  crearMisionBatimetria: async ({ grupoId, fechaMedicion }) => {
    try {
      const result = await axios(`/api/MisionBatimetria/grupo/${grupoId}`, {
        baseURL: batimetriasUrl,
        method: "post",
        data: { grupoId, fechaMedicion },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getMisionesBatimetriaByGrupoId: async (grupoId) => {
    return axios(`/api/MisionBatimetria/grupo/${grupoId}`, {
      baseURL: batimetriasUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarMisionBatimetria: async (misionId) => {
    try {
      const result = await axios(`/api/MisionBatimetria/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  /* Datos Batimetrias */
  importarOrtofoto: ({ misionId, formData }) => {
    return axios(`/api/Ortofoto/${misionId}/upload-ortofoto`, {
          baseURL: batimetriasUrl,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS"
          },
        })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  importarIsobata: ({ misionId, formData }) => {
    return axios(`/api/Isobata/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearMapaDistancia: async ({ grupoId, misionId, itemId, urlStorage,
    distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7 }) => {
    try {
      const result = await axios(`/api/MapaDistancia/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "post",
        data: {
          grupoId, misionId, itemId, urlStorage,
          distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  editarMapaDistancia: async ({ grupoId, misionId, itemId, urlStorage,
                               distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7 }) => {
    try {
      const result = await axios(`/api/MapaDistancia/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "put",
        data: {
          grupoId, misionId, itemId, urlStorage,
          distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  importarMapaDistancia: ({ misionId, formData }) => {
    return axios(`api/MapaDistancia/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearMapaFactorForma: async ({ grupoId, misionId, itemId, urlStorage,
    area, largo, anchoPromedio, factorForma }) => {
    try {
      const result = await axios(`/api/MapaFactorForma/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "post",
        data: {
          grupoId, misionId, itemId, urlStorage,
          area, largo, anchoPromedio, factorForma
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  editarMapaFactorForma: async ({ grupoId, misionId, itemId, urlStorage,
                                 area, largo, anchoPromedio, factorForma }) => {
    try {
      const result = await axios(`/api/MapaFactorForma/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "put",
        data: {
          grupoId, misionId, itemId, urlStorage,
          area, largo, anchoPromedio, factorForma
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  importarMapaFactorForma: ({ misionId, formData }) => {
    return axios(`api/MapaFactorForma/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearMapaSuperficieRelave: async ({ grupoId, misionId, itemId, urlStorage, superficieOcupacionRelave }) => {
    try {
      const result = await axios(`/api/MapaSuperficie/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "post",
        data: {
          grupoId, misionId, itemId, urlStorage, superficieOcupacionRelave
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  editarMapaSuperficieRelave: async ({ grupoId, misionId, itemId, urlStorage, superficieOcupacionRelave }) => {
    try {
      const result = await axios(`/api/MapaSuperficie/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "put",
        data: {
          grupoId, misionId, itemId, urlStorage, superficieOcupacionRelave
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  importarMapaSuperficieRelave: ({ misionId, formData }) => {
    return axios(`api/MapaSuperficie/${misionId}/publicar`, {
      baseURL: batimetriasUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        Accept: "text/plain",
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearDatosSuperficie: async ({ grupoId, misionId, itemIdAgua, urlStorageAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
    superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
    cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
    volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro }) => {
    try {
      const result = await axios(`/api/DatosSuperficie/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "post",
        data: {
          grupoId, misionId, urlStorageAgua, itemIdAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
          superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
          cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
          volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  editarDatosSuperficie: async ({ grupoId, misionId, itemIdAgua, urlStorageAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
                                 superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
                                 cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
                                 volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro }) => {
    try {
      const result = await axios(`/api/DatosSuperficie/grupo/${grupoId}/mision/${misionId}`, {
        baseURL: batimetriasUrl,
        method: "put",
        data: {
          grupoId, misionId, urlStorageAgua, itemIdAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
          superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
          cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
          volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  importarDatosSuperficie: async ({ misionId, formData }) => {
    try {
      const result = await axios(`/api/DatosSuperficie/${misionId}/publicar`, {
        baseURL: batimetriasUrl,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          Accept: "text/plain",
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
};
