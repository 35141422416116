import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useMsal } from "@azure/msal-react";
import LogoutIcon from '@mui/icons-material/Logout';

const UsersMenu = () => {
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  }

  const userName = instance.getActiveAccount()?.username;

  return (
    <>
      <span>Nombre: </span> <span>{userName}</span>

      <p></p>
      <Button onClick={handleLogoutRedirect} variant="outlined" startIcon={<LogoutIcon />}>
        Cerrar Sessión
      </Button>
    </>
  );
};

export default UsersMenu;
