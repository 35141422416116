import React, { useState, useRef } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./ImportArchivoDialog.css";

const FILE_TYPES = ".zip";
const FILE_TEXT = "Seleccione un archivo (*.zip)";

const ImportarSerieTiempoDialog = ({ open, handleClose, handleSubmit }) => {
  const [archivo, setArchivo] = useState();
  const [fechaCaptura, setFechaCaptura] = React.useState(new Date());
  const hiddenArchivo = useRef(null);

  const close = () => {
    handleClose();
  };

  const handleClickImportarArchivo = (event) => {
    hiddenArchivo.current.click();
  };

  const cargarArchivo = (event) => {
    setArchivo(event.target.files[0]);
  };

  const handleChangeFecha = (newFecha) => {
    setFechaCaptura(newFecha);
  };

  const uploadFiles = () => {
    handleSubmit({ fechaCaptura, ficheros: archivo });
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Subir archivo</DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Fecha"
                inputFormat="MM/dd/yyyy"
                value={fechaCaptura}
                onChange={handleChangeFecha}
                renderInput={(params) => <TextField {...params} />}
              />
              <div>
                <InputLabel id="select-datos-label">Archivo</InputLabel>
                <div className="select-datos">
                  <input
                    ref={hiddenArchivo}
                    type="file"
                    style={{ display: "none" }}
                    accept={FILE_TYPES}
                    onChange={cargarArchivo}
                  />
                  <Button
                    size="sm"
                    className="rounded mr-2"
                    color={"primary"}
                    variant="contained"
                    onClick={handleClickImportarArchivo}
                    style={{ marginRight: "8px" }}
                  >
                    Selecionar
                  </Button>
                  <span>{`${archivo ? `${archivo.name}` : FILE_TEXT}`}</span>
                </div>
              </div>
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button disabled={!archivo} onClick={uploadFiles}>
            Subir
          </Button>
          <Button onClick={close}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ImportarSerieTiempoDialog;
