import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {TableFooter, TablePagination} from "@mui/material";
import {MostrarDatosDuperficieDialog} from "./DatosBatimetrias/MostrarDatosSuperficieDialog";
import {MostrarDatosMapaDistanciaDialog} from "./DatosBatimetrias/MostrarDatosMapaDistanciaDialog";
import {MostrarDatosMapaFactorFormaDialog} from "./DatosBatimetrias/MostrarDatosMapaFactorFormaDialog";
import {MostrarOtrosDatosMisionDialog} from "./DatosBatimetrias/MostrarOtrosDatosMisionDialog";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const MostrarDatosMision = ({open, handleClose, misionBatimetriaActual}) => {
    //console.log("MISION ACTUAL", misionBatimetriaActual);
    const [datosSuperficie, setDatosSuperficie] = useState(
        (misionBatimetriaActual) ?
            misionBatimetriaActual.datosSuperficieAgua :
            null);
    const [datosSuperficieRelave, setDatosSuperficieRelave] = useState(
        (misionBatimetriaActual) ?
        misionBatimetriaActual.mapaSuperficieRelave :
        null);
    const [datosMapaDistancia, setDatosMapaDistancia] = useState(
        (misionBatimetriaActual) ?
            misionBatimetriaActual.mapaDistancia :
            null);
    const [datosMapaFactorForma, setDatosMapaFactorForma] = useState(
        (misionBatimetriaActual) ?
            misionBatimetriaActual.mapaFactorForma :
            null);
    const [datosOrtofoto, setDatosOrtofoto] = useState(
        (misionBatimetriaActual) ?
            misionBatimetriaActual.ortofoto :
            null);
    const [datosIsobata, setDatosIsobata] = useState(
        (misionBatimetriaActual) ?
            misionBatimetriaActual.isobata :
            null);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const close = () => {
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={close} maxWidth="lg">
                <DialogTitle>Datos de la Misión de Batimetría de {misionBatimetriaActual.fechaMedicion ? moment(misionBatimetriaActual.fechaMedicion).utc().format("DD-MM-YYYY") : '-'}</DialogTitle>
                <DialogContent>
                    <div>
                        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Superficie" {...a11yProps(0)} />
                                <Tab label="Mapa Distancia" {...a11yProps(1)} />
                                <Tab label="Mapa Factor Forma" {...a11yProps(2)} />
                                <Tab label="Otros Datos" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} className="table-captura">
                            <MostrarDatosDuperficieDialog datosSuperficieActual={datosSuperficie}/>
                        </TabPanel>
                        <TabPanel value={value} index={1} className="table-captura">
                            <MostrarDatosMapaDistanciaDialog mapaDistanciaActual={datosMapaDistancia}/>
                        </TabPanel>
                        <TabPanel value={value} index={2} className="table-captura">
                            <MostrarDatosMapaFactorFormaDialog mapaFactorFormaActual={datosMapaFactorForma}/>
                        </TabPanel>
                        <TabPanel value={value} index={3} className="table-captura">
                            <MostrarOtrosDatosMisionDialog
                                superficieRelaveActual={datosSuperficieRelave}
                                datosIsobata={datosIsobata}
                                datosOrtofoto={datosOrtofoto}
                            />
                        </TabPanel>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
