import axios from "../config/axiosInstance";
import { sismosBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const sismosService = {
  getSismos: async () => {
    try {
      const result = await axios("/sismos/sismos", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getSismosRangoFecha: async ({ desde, hasta }) => {
    try {
      const result = await axios(`/sismos/sismos/desde/${desde}/hasta/${hasta}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  publicarSismo: async (data) => {
    const { lugar, fecha, ubicacion, magnitud, profundidad } = data;
    try {
      const result = await axios("/sismos/sismos", {
        method: "post",
        data: {
          lugar,
          fecha,
          ubicacion,
          magnitud,
          profundidad,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  publicarSismoFecha: async (fecha) => {
    try {
      const result = await axios(`/sismos/sismos/${fecha}/publicar`, {
        method: "post",
        data: {
          date: fecha,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  //Umbrales de magnitud
  getUmbralMagnitud: async ({ sismoId }) => {
    try {
      const result = await axios(`/sismos/umbrales-magnitud/${sismoId}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getUmbralesMagnitud: async () => {
    try {
      const result = await axios("/sismos/umbrales-magnitud", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getUltimoUmbralMagnitud: async () => {
    try {
      const result = await axios("/sismos/umbrales-magnitud/ultimo", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  editarUmbralMagnitud: async ({ id, magnitud }) => {
    try {
      const result = await axios(`/sismos/umbrales-magnitud/${id}`, {
        method: "put",
        data: {
          id,
          magnitud,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  crearUmbralMagnitud: async ({ magnitud }) => {
    try {
      const result = await axios("/sismos/umbrales-magnitud", {
        method: "post",
        data: {
          magnitud,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  //Umbrales de distancia
  getUmbralDistancia: async ({ sismoId }) => {
    try {
      const result = await axios(`/sismos/umbrales-distancia/${sismoId}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getUmbralesDistancia: async () => {
    try {
      const result = await axios("/sismos/umbrales-distancia", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getUltimoUmbralDistancia: async () => {
    try {
      const result = await axios("/sismos/umbrales-distancia/ultimo", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  editarUmbralDistancia: async ({ id, distancia, origen }) => {
    try {
      const result = await axios(`/sismos/umbrales-distancia/${id}`, {
        method: "put",
        data: {
          distancia,
          origen,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  crearUmbralDistancia: async ({ distancia, origen, creado }) => {
    try {
      const result = await axios("/sismos/umbrales-distancia", {
        method: "post",
        data: { distancia, origen, creado },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getReceptoresEmail: () => {
    return axios("receptores-email", {
      baseURL: sismosBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getReceptoresEmailById: ({id}) => {
    return axios(`receptores-email/${id}`, {
      baseURL: sismosBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearReceptoresEmail: ({ email }) => {
    return axios("receptores-email", {
      baseURL: sismosBaseUrl,
      method: "post",
      data: { email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateReceptoresEmail: ({ id, email }) => {
    return axios(`receptores-email/${id}`, {
      baseURL: sismosBaseUrl,
      method: "put",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deleteReceptoresEmail: ({ id, email }) => {
    return axios(`receptores-email/${id}`, {
      baseURL: sismosBaseUrl,
      method: "delete",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
