import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const EditarCapaDialog = ({ open, handleClose, handleEditarCapa, capa }) => {

  const [nombre, setNombre] = useState(capa.nombre)
  const [url, setUrl] = useState(capa.url)

  const resetValues = () => {
    setNombre("");
    setUrl("")
  }

  const handleChangeName = (event) => {
    setNombre(event.target.value);
  }

  const handleChangeUrl = (event) => {
    setUrl(event.target.value);
  }

  const close = () => {
    resetValues();
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Editar Capa Modelo de Terreno Digital (DTM)</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>
          <TextField
            margin="dense"
            id="name"
            label="Nombre"
            fullWidth
            value={nombre}
            onChange={handleChangeName}
          />

          <TextField
              margin="dense"
              id="url"
              label="Item ID"
              fullWidth
              value={url}
              onChange={handleChangeUrl}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            close();
          }}>
            Cancelar
          </Button>
          <Button disabled={!nombre && !url}
            onClick={() => handleEditarCapa(nombre, url)}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditarCapaDialog;