import axios from "../config/axiosInstance";
import { curvasNivelBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const curvasNivelService = {
  crearGrupo: async ({ nombre, areaId }) => {
    try {
      const result = await axios("/api/curva-nivel/grupo", {
        baseURL: curvasNivelBaseUrl,
        method: "post",
        data: { nombre, areaId },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGrupos: async () => {
    return axios("/api/curva-nivel/grupos", {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/api/curva-nivel/grupo/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: async ({ id, nombre, areaId }) => {
    try {
      const result = await axios(`/api/curva-nivel/grupo/${id}`, {
        baseURL: curvasNivelBaseUrl,
        data: { id, nombre, areaId },
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  eliminarGrupo: async (id) => {
    try {
      const result = await axios(`/api/curva-nivel/grupo/${id}`, {
        baseURL: curvasNivelBaseUrl,
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  eliminarCurvasNivel: ({ grupoId, curvaId }) => {
    return axios(`/api/curva-nivel/grupo/${grupoId}/curva/${curvaId}`, {
      baseURL: curvasNivelBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCurvaNivel: async (id) => {
    return axios(`/api/curva-nivel/curva/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearCurvasNivel: async ({ idGrupo, formData }) => {
    try {
      const result = await axios(`/api/curva-nivel/grupo/${idGrupo}/upload-curva-nivel`, {
        baseURL: curvasNivelBaseUrl,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
      });
      return result;
    } catch (error) {
      const err = errorHandle(error);
      return err;
    }
  },
  descargarCurvasNivel: async ({ grupoId, curvaId }) => {
    try {
      const result = await axios(
        `/api/curva-nivel/grupos/${grupoId}/descargar-curva/${curvaId}`,
        {
          baseURL: curvasNivelBaseUrl,
          method: "get",
        }
      );
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  publicarCapa: async ({ curvaNivelId, nombre, url, fechaCreado }) => {
    try {
      const result = await axios("/api/curva-nivel/capa", {
        baseURL: curvasNivelBaseUrl,
        method: "post",
        data: {
          curvaNivelId,
          nombre,
          url,
          fechaCreado,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getCapas: async () => {
    return axios("/api/curva-nivel/capas", {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`/api/curva-nivel/capa/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado, curvaNivelId}) => {
    return axios(`/api/curva-nivel/capa/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "put",
      data: {
        curvaNivelId,
        id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`/api/curva-nivel/capa/${id}`, {
      baseURL: curvasNivelBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
