import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

export const MostrarDatosMapaDistanciaDialog = ({mapaDistanciaActual}) => {
    const [mapaDistancia, setMapaDistancia] = useState((mapaDistanciaActual) ? mapaDistanciaActual : null);

    return (
        <Stack spacing={3}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Espígot 2->A [m]: </b>
                        <u>{(mapaDistanciaActual.distanciaSpigot2) ? mapaDistanciaActual.distanciaSpigot2 : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Espígot 3->B [m]: </b>
                        <u>{(mapaDistanciaActual.distanciaSpigot3) ? mapaDistanciaActual.distanciaSpigot3 : "-" }</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Espígot 6->C [m]: </b>
                        <u>{(mapaDistanciaActual.distanciaSpigot6) ? mapaDistanciaActual.distanciaSpigot6 : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Espígot 7->D [m]: </b>
                        <u>{(mapaDistanciaActual.distanciaSpigot7) ? mapaDistanciaActual.distanciaSpigot7 : "-" }</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>ArcGIS ItemId Mapa Distancia: </b>
                        <u>{(mapaDistanciaActual.itemId) ? mapaDistanciaActual.itemId : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Blob Storage Url Mapa Distancia: </b>
                        <u>{(mapaDistanciaActual.urlStorage) ? mapaDistanciaActual.urlStorage : "-" }</u>
                    </Grid>
                </Grid>
            </div>
        </Stack>
    )
}
