import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { insarService } from '../../../../services/InsarService';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddPeriodoInsarDialog from '../../../Dialogs/SeriesInSAR/AddPeriodoInsarDialog';
import './InSar.css';
import DeformacionesAcumuladasMenuBtn from '../../../Dialogs/SeriesInSAR/DeformAcumMenuBtn';
import { CircularProgress } from '@mui/material';
import ImportarDeformacionAcumuladaDialog from "../../../Dialogs/SeriesInSAR/ImportarDeformacionAcumuladaDialog";
import {toast} from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DeformacionesAcumuladas = ({ serieInsar, loadSerieInsar, periodos }) => {
  const [loadingAdicionarPeriodo, setLoadingAdicionarPeriodo] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openAddPeriodoDialog, setOpenAddPeriodoDialog] = useState(false);
  const [openImportarDeformacionDialog, setOpenImportarDeformacionDialog] = useState(false);
  const [periodoActual, setPeriodoActual] = useState();

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  const handleClickAddPeriodo = (row) => {
    if (row?.periodo) {
      setPeriodoActual(row);
      periodos[row.periodo].activo = false
    }
    setOpenAddPeriodoDialog(true);
  };

  const handleClickImportarDeformacion = (row) => {
    if (row?.periodo) {
      setPeriodoActual(row);
      periodos[row.periodo].activo = false
    }
    setOpenImportarDeformacionDialog(true);
  };

  const handleCloseAddPeriodoDialog = () => {
    setOpenAddPeriodoDialog(false);
    if (periodoActual?.periodo) {
      periodos[periodoActual.periodo].activo = true
      setPeriodoActual();
    }
  };

  const handleClickDeleteItem = (row) => {
    setPeriodoActual(row);
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleCloseImportarDatosDialog = () => {
    setOpenImportarDeformacionDialog(false);
  };

  const handleDeletePeriodo = () => {
    handleCloseDeleteItemDialog();
    insarService.eliminarDeformacionesAcumuladas(periodoActual.id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          loadSerieInsar();
        } else {
          console.log(res)
        }
      })
    setPeriodoActual();
  };
  const handleAddPeriodoInSar = ({ id, periodo, url, storageUrl, escala }) => {
    setLoadingAdicionarPeriodo(true);
    if (id) {
      insarService.editarDeformacionesAcumuladas({
        deformacionId: id,
        periodo,
        url,
        storageUrl,
        escala
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          loadSerieInsar();
          periodos[periodo].activo = true
        } else {
          console.error(res)
          periodos[periodo].activo = false
        }
      })
    } else {
      insarService.crearDeformacionesAcumuladas({
        capturaId: serieInsar.id,
        periodo,
        url,
        storageUrl,
        escala
      }).then(res => {
        if (res.status >= 200 && res.status < 300) {
          loadSerieInsar();
          periodos[periodo].activo = true
        } else {
          console.error(res)
          periodos[periodo].activo = false
        }
      })
    }
    handleCloseAddPeriodoDialog();
    setLoadingAdicionarPeriodo(false)
    setPeriodoActual();
  }

  const handleImportar = ({ periodo, ficheros }) => {
    handleCloseImportarDatosDialog();
    setLoadingAdicionarPeriodo(true);
    notify("Importando...");

    const formData = new FormData();
    formData.append("UploadFile", ficheros);
    formData.append("Periodo", periodo);
    insarService
        .importarDeformacionAcumulada({
          capturaId: serieInsar.id,
          formData,
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            update("Los datos se importaron 👌", toast.TYPE.SUCCESS);
            loadSerieInsar();
          } else {
            update(
                `Ha ocurrido un error importando 🤯 ${res.message}`,
                toast.TYPE.ERROR
            );
          }
        });
    setLoadingAdicionarPeriodo(false);
  };


  return (
    <div>
      <div>
        {/*<Button*/}
        {/*    variant="contained"*/}
        {/*    className="btn-add-insar"*/}
        {/*    onClick={handleClickAddPeriodo}*/}
        {/*>{loadingAdicionarPeriodo && (*/}
        {/*    <CircularProgress*/}
        {/*        size={24}*/}
        {/*        sx={{*/}
        {/*          position: 'absolute',*/}
        {/*          top: '50%',*/}
        {/*          left: '50%',*/}
        {/*          marginTop: '-12px',*/}
        {/*          marginLeft: '-12px',*/}
        {/*        }}*/}
        {/*    />*/}
        {/*)}Adicionar*/}
        {/*</Button>*/}

        <Button
            variant="contained"
            className="btn-add-insar"
            onClick={handleClickImportarDeformacion}
        >{loadingAdicionarPeriodo && (
            <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
            />
        )}Importar
        </Button>
      </div>


      <div className="container-table">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Deformaciones Acumuladas</StyledTableCell>
                <StyledTableCell align="center">Escala</StyledTableCell>
                <StyledTableCell align="center" width="5%"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serieInsar?.periodos?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{periodos[row.periodo].etiqueta}</StyledTableCell>
                  <StyledTableCell>
                    <div className="grupo-escalas">
                      {
                        row.escala.map(color => {
                          const { r, g, b, a } = color.color;
                          return (
                            <div className="grupo-datos-escala">
                              <div style={{ backgroundColor: `rgba(${r},${g},${b},${a})` }} className="escala-min"></div>
                              <div className="datos-escala">
                                <div>{color.rango.minimo}</div>
                                <div>:</div>
                                <div>{color.rango.maximo}</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <DeformacionesAcumuladasMenuBtn
                      disabled={loadingAdicionarPeriodo}
                      editarAction={() => { handleClickAddPeriodo(row) }}
                      eliminarAction={() => { handleClickDeleteItem(row) }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeletePeriodo} />

      {openAddPeriodoDialog ?
        <AddPeriodoInsarDialog
          open={openAddPeriodoDialog}
          handleClose={handleCloseAddPeriodoDialog}
          handleSubmit={handleAddPeriodoInSar}
          periodoActual={periodoActual}
          periodosActivos={periodos}
        /> : null}

      {openImportarDeformacionDialog ?
          <ImportarDeformacionAcumuladaDialog
              open={openImportarDeformacionDialog}
              handleClose={handleCloseImportarDatosDialog}
              handleSubmit={handleImportar}
          /> : null}
      {/* <NotificacionMensaje
        open={error}
        onClose={handleCloseAlert}
        message="Ha fallado la subida de los archivos"
      /> */}
    </div>
  );
}
export default DeformacionesAcumuladas;
