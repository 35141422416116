import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import { insarService } from "../../../../services/InsarService";
import moment from "moment";
import SeriesMenuBtn from "../../../Dialogs/SeriesInSAR/SeriesMenuBtn";
import EditarSerieTiempoDialog from "../../../Dialogs/SeriesInSAR/EditarSerieTiempoDialog";
import "./InSar.css";
import NotificacionMensaje from "../../../NotificacionMensaje";
import ImportarSerieTiempoDialog from "../../../Dialogs/SeriesInSAR/ImportarSerieTiempoDialog";
import {toast} from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SeriesTiempo = ({ serieInsar, loadSerieInsar }) => {

  const [importandoDatos, setImportandoDatos] = useState({
    importando: false,
    status: "Importando",
  });
  const [openImportarDatosDialog, setOpenImportarDatosDialog] = useState(false);
  const [openEditarSerieDialog, setOpenEditarSerieDialog] = useState(false);
  const [serieCapturaActual, setSerieCapturaActual] = useState();

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  const handleClickImportarDatos = () => {
    setOpenImportarDatosDialog(true);
  };
  const handleCloseImportarDatosDialog = () => {
    setOpenImportarDatosDialog(false);
  };

  const handleCloseEditarEscalaDialog = () => {
    setOpenEditarSerieDialog(false);
    setSerieCapturaActual();
  };

  const editarEscalaDeCaptura = (row) => {
    setSerieCapturaActual(row);
    setOpenEditarSerieDialog(true);
  };

  const handleEditarSerieTiempo = async ({ fecha, escala }) => {
    handleCloseEditarEscalaDialog();
    let res = { status: 0 };
    res = await insarService.editarEscalaColores(
      serieInsar.id,
      escala
    );
    res = await insarService.editarSerieTiempo(
        serieInsar.url,
        serieInsar.storageUri,
        fecha,
        serieInsar.id
    );
    if (res.status >= 200 && res.status < 300) {
      loadSerieInsar();
    } else {
      console.log(res);
    }
  };

  const handleImportar = ({ fechaCaptura, ficheros }) => {
    handleCloseImportarDatosDialog();
    notify("Importando...");
    // const formData = new FormData();
    // formData.append("UploadFile", new File(ficheros, ficheros.name));
    // formData.append("FechaCreado", new Date(fechaCaptura).toISOString());
    // console.log("FORMDATA", formData);
    insarService
        .importarSerieTiempo({
          capturaId: serieInsar.id,
          fechaCaptura: new Date(fechaCaptura).toISOString(),
          uploadFile: ficheros
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            update("Los datos se importaron 👌", toast.TYPE.SUCCESS);
            loadSerieInsar();
          } else {
            update(
                `Ha ocurrido un error importando 🤯 ${res.message}`,
                toast.TYPE.ERROR
            );
          }
        });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setImportandoDatos({ ...importandoDatos, status: "Importando" });
  };

  return (
    <div>
      <Button
        disabled={importandoDatos.importando}
        variant="contained"
        className="btn-add-insar"
        onClick={handleClickImportarDatos}
      >
        {importandoDatos.importando && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        {(serieInsar === null) ? <>Adicionar</> : <>Actualizar</>}
      </Button>

      {(serieInsar?.serieTiempo !== null) &&
          <div className="container-table">
            <TableContainer component={Paper}>
              <Table
                  sx={{ minWidth: 700 }}
                  size="small"
                  aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Fecha Captura</StyledTableCell>
                    <StyledTableCell align="center">Nombre</StyledTableCell>
                    <StyledTableCell align="center">Escalas</StyledTableCell>
                    <StyledTableCell align="center" width="5%"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={serieInsar?.id}>
                    <StyledTableCell align="center">
                      {moment(serieInsar?.serieTiempo.fechaCaptura).utc().format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {serieInsar?.etiqueta}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div>
                        <div className="grupo-escalas">
                          {serieInsar?.serieTiempo.escala?.map((color) => {
                            const { r, g, b, a } = color.color;
                            return (
                                <div className="grupo-datos-escala">
                                  <div
                                      style={{
                                        backgroundColor: `rgba(${r},${g},${b},${a})`,
                                      }}
                                      className="escala-min"
                                  ></div>
                                  <div className="datos-escala">
                                    <div>{color.rango.minimo}</div>
                                    <div>:</div>
                                    <div>{color.rango.maximo}</div>
                                  </div>
                                </div>
                            );
                          })}
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center" width="5%">
                      <SeriesMenuBtn
                          disabled={importandoDatos.importando}
                          editarAction={() => editarEscalaDeCaptura(serieInsar?.serieTiempo)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
      }

      {openImportarDatosDialog ? (
          <ImportarSerieTiempoDialog
              open={openImportarDatosDialog}
              handleClose={handleCloseImportarDatosDialog}
              handleSubmit={handleImportar}
          />
      ) : null}
      {openEditarSerieDialog ? (
        <EditarSerieTiempoDialog
          open={openEditarSerieDialog}
          handleClose={handleCloseEditarEscalaDialog}
          handleSubmit={handleEditarSerieTiempo}
          serieActual={serieCapturaActual}
        />
      ) : null}
      <NotificacionMensaje
        open={importandoDatos.status === "Fallido"}
        onClose={handleCloseAlert}
        message="Ha fallado la subida de los archivos"
      />
    </div>
  );
};
export default SeriesTiempo;
