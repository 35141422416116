// import axios from 'axios';
import axios from "../config/axiosInstance";
import {auxiliaresBaseUrl} from "../config/config";
import errorHandle from "./tratarError";
import { toast } from "react-toastify";

export const auxiliaresService = {
  crearGrupo: async ({ nombre }) => {
    try {
      const result = await axios("/auxiliares/api/auxiliares/grupos", {
        method: "post",
        data: { nombre, areaId: 1 },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGrupos: async () => {
    return axios("/auxiliares/api/auxiliares/grupos", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`/auxiliares/api/auxiliares/grupos/${id}`, {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  actualizarGrupo: async ({ id, nombre }) => {
    try {
      const result = await axios(`/auxiliares/api/auxiliares/grupos/${id}`, {
        data: { id, nombre, areaId: 1 },
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  eliminarGrupo: async (id) => {
    try {
      const result = await axios(`/auxiliares/api/auxiliares/grupos/${id}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getAuxiliares: async () => {
    return axios("/auxiliares/api/auxiliares/grupos", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getAuxiliar: async (id) => {
    try {
      const result = await axios(`/auxiliares/api/auxiliares/grupos/${id}/auxiliares`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getAuxiliarById: (id) => {
    return axios(`/api/auxiliares/auxiliar/${id}`, {
      baseURL: auxiliaresBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateAuxiliar: (data) => {
    return axios(`/api/auxiliares/grupo/${data.auxiliarId}`, {
      baseURL: auxiliaresBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarAuxiliar: async ({ grupoId, auxiliarId }) => {
    try {
      const result = await axios(`/auxiliares/api/auxiliares/grupos/${grupoId}/auxiliar/${auxiliarId}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  descargarAuxiliar: async ({ grupoId, auxiliarId }) => {
    try {
      const result = await axios(
        `/auxiliares/api/auxiliares/grupos/${grupoId}/descargar-auxiliar/${auxiliarId}`,
        {
          method: "get",
        }
      );
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  publicarCapaOrtofoto: async ({ auxiliarId, nombre, url, creado }) => {
    try {
      const result = await axios("/auxiliares/api/auxiliares/capas", {
        method: "post",
        data: {
          auxiliarId,
          nombre,
          url,
          creado,
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  uploadAuxiliares: async ({ idGrupo, formData }) => {
    const toastId = toast.loading("Importando datos");
    return axios(`/auxiliares/api/auxiliares/grupos/${idGrupo}/upload-auxiliar`, {
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    })
      .then((result) => {
        toast.update(toastId, {
          render: "Los datos se importaron correctamente",
          type: "success",
          isLoading: false,
        });
        return result;
      })
      .catch((error) => {
        const err = errorHandle(error);
        toast.update(toastId, {
          render: err.message,
          type: "error",
          isLoading: false,
        });
        return err;
      });
  },
  getCapas: async () => {
    return axios("/auxiliares/api/auxiliares/capas", {
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`/auxiliares/api/auxiliares/capa/${id}`, {
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado, auxiliarId}) => {
    return axios(`/auxiliares/api/auxiliares/capa/${id}`, {
      method: "put",
      data: {
        auxiliarId,
        id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`/auxiliares/api/auxiliares/capa/${id}`, {
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
