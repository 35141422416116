import React, { useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import './AddElementoDialog.css'

const FILE_TYPES = ".csv";
const FILE_TEXT = "Seleccione un archivo (*.csv)";

const ImportarDialog = ({ open, handleClose, handleSubmit, }) => {
    const [fichero, setFichero] = useState();

    const hiddenFileInput = useRef(null);

    const close = () => {
        handleClose();
    }

    const handleClickImportar = (event) => {
        hiddenFileInput.current.click();
    };

    const cargarFichero = (event) => {
        setFichero(event.target.files[0]);
    }

    const uploadFiles = () => {
        handleSubmit({ ficheros: fichero })
    }

    return (
        <div>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Subir archivo</DialogTitle>
                <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
                    <Stack spacing={3}>
                        <div>
                            <InputLabel id="select-datos-label">Archivo</InputLabel>
                            <div className="select-datos">
                                <input
                                    ref={hiddenFileInput}
                                    type='file'
                                    style={{ display: 'none' }}
                                    // accept={FILE_TYPES}
                                    onChange={cargarFichero}
                                />
                                <Button
                                    size="sm"
                                    className="rounded mr-2"
                                    color={"primary"}
                                    variant="contained"
                                    onClick={handleClickImportar}
                                    style={{ marginRight: '8px' }}
                                >
                                    Selecionar
                                </Button>
                                <span>{fichero ? fichero.name : FILE_TEXT}</span>
                            </div>
                        </div>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        close();
                    }}>
                        Cancelar
                    </Button>
                    <Button disabled={!fichero}
                        onClick={uploadFiles}>
                        Subir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ImportarDialog;