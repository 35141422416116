import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import "./AddDialog.css";

const AddDialog = ({ open, handleClose, handleSubmit, magnitud }) => {
  const [valor, setValor] = useState(magnitud?.value ? magnitud.value : "");
  const [unit, setUnit] = useState(magnitud?.unit ? magnitud.unit : "");

  const handleChangeValor = (event) => {
    setValor(event.target.value);
  };

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  const close = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          {magnitud ? "Editar elemento" : "Adicionar elemento"}
        </DialogTitle>
        <DialogContent style={{ paddingTop: "1rem" }}>
          <TextField
            margin="dense"
            id="valor"
            label="Valor"
            fullWidth
            value={valor}
            onChange={handleChangeValor}
          />
          <TextField
            margin="dense"
            id="unit"
            label="Unidad de medida"
            fullWidth
            value={unit}
            onChange={handleChangeUnit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancelar</Button>
          <Button
            disabled={!valor || !unit}
            onClick={() => {
              handleSubmit({ value: valor, unit });
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddDialog;
