import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { treeItemClasses, useTreeItem } from '@mui/x-tree-view/TreeItem';
import { Collapse, Typography, CircularProgress } from "@mui/material";
import OrtoFotoBlackIcon from "../../../assets/ortofotoBlack.png";
import NubePuntosBlackIcon from "../../../assets/pointcloudBlack.png";
import RevanchaBlackIcon from "../../../assets/slopeBlack.png";
import DTMIcon from "../../../assets/terrain.png";
import InsarBlackIcon from "../../../assets/insarBlack.png";
import PiezometroBlackIcon from "../../../assets/hydrogeologyBlack.png";
import AcelerometroBlackIcon from "../../../assets/earthquakeBlack.png";
import EjesMuroBlackIcon from "../../../assets/ejemuroblackicon.png";
import PlanesCargaBlackIcon from "../../../assets/planescargaBlack.png";
import BatimetriasBlackIcon from "../../../assets/batimetriasBlack.png";
import EstacionMeteorlBlackIcon from "../../../assets/estacionMetBlack.png";
import clsx from "clsx";
import { LAYER_TYPES } from "../../../common/constants";
import { piezometersService } from "../../../services/PiezometersService";
import { insarService } from "../../../services/InsarService";
import { ortofotosService } from "../../../services/OrtofotosService";
import { dtmService } from "../../../services/DTMService";
import { mapaService } from "../../../services/MapaService";
import { revanchaService } from "../../../services/RevanchaService";

import {
  useDrawerMenu,
  ACTIONS as DRAWER_MENU_ACTIONS,
} from "../../../providers/DrawerMenuProvider";
import { useNavigate } from "react-router-dom";

import "../TreeViewMenu.css";
import { curvasNivelService } from "../../../services/CurvasNivelService";
import { acelerometrosService } from "../../../services/AcelerometrosService";
import { auxiliaresService } from "../../../services/AuxiliaresService";
import { puntosDescargaService } from "../../../services/PuntosDescargaService";
import { planesCargaService } from "../../../services/PlanesCargaService";
import {ejesmuroService} from "../../../services/EjesMuroService";
import {batimetriasService} from "../../../services/BatimetriasService";
import {grietasService} from "../../../services/GrietasService";
import {estacionesMetService} from "../../../services/EstacionesMeteorologicasService";
import errorHandle from "../../../services/tratarError";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = {
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  };

  return (
    <div style={style}>
      <Collapse {...props} />
    </div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const navigate = useNavigate();
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const goTo = (path) => {
    navigate(path);
  };

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    // setDrawerMenuContext({
    //   action: DRAWER_MENU_ACTIONS.CLOSE_ALL_DRAWER_MENU,
    // });
    if (icon.type === "img") {
      let node = nodeId.replace(/\"/g, "").replace(/'/g, '"');
      goTo(`/${icon.props.alt}/${node.split("_")[1]}`);
    }
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      {nodeId.startsWith("root-") ? (
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          <b>{label}</b>
        </Typography>
      ) : (
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      )}
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired,
};

const StyledTreeItem = styled((props) => (
  <TreeItem
    {...props}
    TransitionComponent={TransitionComponent}
    ContentComponent={CustomContent}
  />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const defatultTreeData = [
  {
    id: "root-1",
    name: "Topografía",
    children: [
      {
        id: "2",
        name: "Ortofotos",
        children: [],
      },
      {
        id: "5",
        name: "DTM",
        children: [],
      },
      {
        id: "7",
        name: "Mapa de elevaciones",
        children: [],
      },
      {
        id: "11",
        name: "Curvas de Nivel",
        children: [],
      },
    ],
  },
  {
    id: "root-13",
    name: "Deformaciones",
    children: [
      {
        id: "14",
        name: "InSAR",
        children: [],
      },
    ],
  },
  {
    id: "root-16",
    name: "Instrumentación",
    children: [
      {
        id: "17",
        name: "Piezómetros",
        children: [],
      },
      {
        id: "20",
        name: "Acelerómetros",
        children: [],
      },
      {
        id: "22",
        name: "Estaciones Meteorológicas",
        children: [],
      },
    ],
  },
  {
    id: "root-5",
    name: "Ejes de Muro",
    children: [
      {
        id: "23",
        name: "Ejes de Muro",
        children: [],
      },
    ],
  },
  {
    id: "root-17",
    name: "Control Operacional",
    children: [
      {
        id: "24",
        name: "Revancha",
        children: [],
      },
      {
        id: "25",
        name: "Auxiliares",
        children: [],
      },
      {
        id: "26",
        name: "Puntos de Descarga",
        children: [],
      },
      {
        id: "27",
        name: "Grietas",
        children: [],
      },
    ],
  },
  {
    id: "root-18",
    name: "Planes de Carga",
    children: [
      {
        id: "28",
        name: "Planes Esteril",
        children: [],
      },
      // {
      //   id: "29",
      //   name: "Planes Transición",
      //   children: [],
      // },
    ],
  },
  {
    id: "root-20",
    name: "Batimetrías",
    children: [
      {
        id: "30",
        name: "Batimetrías",
        children: [],
      }
    ],
  }
];

const LAYER_INDEXES = {
  TOPOGRAFIA: {
    index: 0,
    children: {
      ORTOFOTOS: 0,
      DTM: 1,
      ELEVACIONES: 2,
      CURVAS_NIVEL: 3,
    },
  },
  DEFORMACIONES: {
    index: 1,
    children: {
      INSAR: 0,
    },
  },
  INSTRUMENTACION: {
    index: 2,
    children: {
      PIEZOMETROS: 0,
      ACELEROMETROS: 1,
      ESTACIONES_MET: 2
    },
  },
  EJES_MURO: {
    index: 3,
    children: {
      EJES_MURO: 0,
    },
  },
  CONTROL_OPERACIONAL: {
    index: 4,
    children: {
      REVANCHA: 0,
      AUXILIARES: 1,
      PUNTOS_DESCARGA: 2,
      GRIETAS: 3,
    },
  },
  PLANES_CARGA: {
    index: 5,
    children: {
      ESTERIL: 0,
      // TRANSICION: 1
    },
  },
  BATIMETRIAS: {
    index: 6,
    children: {
      BATIMETRIAS: 0
    }
  }
};

const expandAll = (data, expanded) => {
  for (let i = 0; i < data.length; i++) {
    expanded.push(JSON.stringify(data[i].id));
    if (data[i].children?.length) {
      expandAll(data[i].children, expanded);
    }
  }
  return expanded;
};

const GruposMenu = ({ refreshLayers, setRefreshLayers }) => {
  const [treeData, setTreeData] = useState(defatultTreeData);
  const [expandedItems] = useState(expandAll(defatultTreeData, []));
  const [loadingCategorias, setLoadingCategorias] = useState(false);

  const getAllData = async () => {
    var promises = await Promise.all([
          piezometersService.getGroups(),
          acelerometrosService.getGroups(),
          estacionesMetService.getGroups(),
          insarService.getSeries(),
          ortofotosService.getGruposOrtofotos(),
          dtmService.getGrupos(),
          mapaService.getGrupos(),
          curvasNivelService.getGrupos(),
          ejesmuroService.getGruposEjesMuro(),
          revanchaService.getGrupos(),
          auxiliaresService.getGrupos(),
          puntosDescargaService.getGrupos(),
          grietasService.getGrupos(),
          planesCargaService.getPlanesEsteril(),
          batimetriasService.getGruposBatimetrias()
        ]
    )
        .then((result) => result)
        .catch((error) => errorHandle(error));
    return promises;
  }

  useEffect(() => {
    let refreshData = async () => {
      if (Object.keys(refreshLayers).length !== 0) {
        setLoadingCategorias(true);
        let treeCopy = [...treeData];

        var promise = await getAllData();

        const intrumentacionIndex = LAYER_INDEXES.INSTRUMENTACION.index;
        const deformacionesIndex = LAYER_INDEXES.DEFORMACIONES.index;
        const topografiaIndex = LAYER_INDEXES.TOPOGRAFIA.index;
        const controlOprIndex = LAYER_INDEXES.CONTROL_OPERACIONAL.index;
        const planesCargaIndex = LAYER_INDEXES.PLANES_CARGA.index;
        const batimetriasIndex = LAYER_INDEXES.BATIMETRIAS.index;
        const ejesMuroIndex = LAYER_INDEXES.EJES_MURO.index;
        if (refreshLayers[LAYER_TYPES?.PIEZOMETROS]) {
          const piezoIndex = LAYER_INDEXES.INSTRUMENTACION.children.PIEZOMETROS;
          treeCopy[intrumentacionIndex].children[piezoIndex].children = loadPiezometers(promise[0]);
        }
        if (refreshLayers[LAYER_TYPES?.ACELEROMETROS]) {
          const acelerometroIndex =
            LAYER_INDEXES.INSTRUMENTACION.children.ACELEROMETROS;
          treeCopy[intrumentacionIndex].children[acelerometroIndex].children = loadAcelerometros(promise[1]);
        }
        if (refreshLayers[LAYER_TYPES?.RADARES]) {
          const estacionesMetIndex = LAYER_INDEXES.INSTRUMENTACION.children.ESTACIONES_MET;
          treeCopy[intrumentacionIndex].children[estacionesMetIndex].children = loadEstacionesMeteorologicas(promise[2]);
        }
        if (refreshLayers[LAYER_TYPES?.INSAR]) {
          const insarIndex = LAYER_INDEXES.DEFORMACIONES.children.INSAR;
          treeCopy[deformacionesIndex].children[insarIndex].children = loadInsars(promise[3]);
        }
        if (refreshLayers[LAYER_TYPES?.ORTOFOTOS]) {
          const ortofotosIndex = LAYER_INDEXES.TOPOGRAFIA.children.ORTOFOTOS;
          treeCopy[topografiaIndex].children[ortofotosIndex].children = loadOrtofotos(promise[4]);
        }
        if (refreshLayers[LAYER_TYPES?.DTM]) {
          const dtmIndex = LAYER_INDEXES.TOPOGRAFIA.children.DTM;
          treeCopy[topografiaIndex].children[dtmIndex].children = loadDTM(promise[5]);
        }
        if (refreshLayers[LAYER_TYPES?.ELEVACIONES]) {
          const mapaIndex = LAYER_INDEXES.TOPOGRAFIA.children.ELEVACIONES;
          treeCopy[topografiaIndex].children[mapaIndex].children = loadMapa(promise[6]);
        }
        if (refreshLayers[LAYER_TYPES?.CURVAS_NIVEL]) {
          const curvasIndex = LAYER_INDEXES.TOPOGRAFIA.children.CURVAS_NIVEL;
          treeCopy[topografiaIndex].children[curvasIndex].children = loadCurvasNivel(promise[7]);
        }
        if (refreshLayers[LAYER_TYPES?.EJES_MURO]) {
          const ejesMuroChildernIndex = LAYER_INDEXES.EJES_MURO.children.EJES_MURO;
          treeCopy[ejesMuroIndex].children[ejesMuroChildernIndex].children = loadEjesMuro(promise[8]);
        }
        if (refreshLayers[LAYER_TYPES?.REVANCHA]) {
          const revanchaIndex =
            LAYER_INDEXES.CONTROL_OPERACIONAL.children.REVANCHA;
          treeCopy[controlOprIndex].children[revanchaIndex].children = loadRevanchas(promise[9]);
        }
        if (refreshLayers[LAYER_TYPES?.AUXILIARES]) {
          const auxiliaresIndex =
            LAYER_INDEXES.CONTROL_OPERACIONAL.children.AUXILIARES;
          treeCopy[controlOprIndex].children[auxiliaresIndex].children = loadAuxiliares(promise[10]);
        }
        if (refreshLayers[LAYER_TYPES?.PUNTOS_DESCARGA]) {
          const puntosDescargaIndex =
            LAYER_INDEXES.CONTROL_OPERACIONAL.children.PUNTOS_DESCARGA;
          treeCopy[controlOprIndex].children[puntosDescargaIndex].children = loadPuntosDescarga(promise[11]);
          treeCopy[controlOprIndex].children[puntosDescargaIndex].children = loadPuntosDescarga(promise[11]);
        }
        if (refreshLayers[LAYER_TYPES?.GRIETAS]) {
          const grietasIndex =
              LAYER_INDEXES.CONTROL_OPERACIONAL.children.GRIETAS;
          treeCopy[controlOprIndex].children[grietasIndex].children = loadGrietas(promise[12]);
        }
        if (refreshLayers[LAYER_TYPES?.PLANES_ESTERIL]) {
          const planesCargaEsterilIndex =
              LAYER_INDEXES.PLANES_CARGA.children.ESTERIL;
          treeCopy[planesCargaIndex].children[planesCargaEsterilIndex].children = loadPlanesCargaEsteril(promise[13]);
        }
        if (refreshLayers[LAYER_TYPES?.BATIMETRIAS]) {
          const gruposBatimetriasIndex =
              LAYER_INDEXES.BATIMETRIAS.children.BATIMETRIAS;
          treeCopy[batimetriasIndex].children[gruposBatimetriasIndex].children = loadGruposBatimetrias(promise[14]);
        }
        setTreeData(treeCopy);
        setRefreshLayers({});
        setLoadingCategorias(false);
      }
    };
    refreshData();
  }, [refreshLayers]);

  useEffect(() => {
    let load = async () => {
      setLoadingCategorias(true);
      let treeCopy = [...treeData];

      var promise = await getAllData();

      const intrumentacionIndex = LAYER_INDEXES.INSTRUMENTACION.index;
      const deformacionesIndex = LAYER_INDEXES.DEFORMACIONES.index;
      const topografiaIndex = LAYER_INDEXES.TOPOGRAFIA.index;
      const planesCargaIndex = LAYER_INDEXES.PLANES_CARGA.index;
      const batimetriasIndex = LAYER_INDEXES.BATIMETRIAS.index;
      const controlOprIndex = LAYER_INDEXES.CONTROL_OPERACIONAL.index;
      const ejesMuroIndex = LAYER_INDEXES.EJES_MURO.index;
      const piezoIndex = LAYER_INDEXES.INSTRUMENTACION.children.PIEZOMETROS;
      const acelerometroIndex = LAYER_INDEXES.INSTRUMENTACION.children.ACELEROMETROS;
      const estacionesMetIndex = LAYER_INDEXES.INSTRUMENTACION.children.ESTACIONES_MET;
      const insarIndex = LAYER_INDEXES.DEFORMACIONES.children.INSAR;
      const ortofotosIndex = LAYER_INDEXES.TOPOGRAFIA.children.ORTOFOTOS;
      const dtmIndex = LAYER_INDEXES.TOPOGRAFIA.children.DTM;
      const mapaIndex = LAYER_INDEXES.TOPOGRAFIA.children.ELEVACIONES;
      const ejesMuroChildernIndex = LAYER_INDEXES.EJES_MURO.children.EJES_MURO;
      const curvasIndex = LAYER_INDEXES.TOPOGRAFIA.children.CURVAS_NIVEL;
      const revanchaIndex = LAYER_INDEXES.CONTROL_OPERACIONAL.children.REVANCHA;
      const puntosDescargaIndex = LAYER_INDEXES.CONTROL_OPERACIONAL.children.PUNTOS_DESCARGA;
      const grietasIndex = LAYER_INDEXES.CONTROL_OPERACIONAL.children.GRIETAS;
      const auxiliaresIndex = LAYER_INDEXES.CONTROL_OPERACIONAL.children.AUXILIARES;
      const planesCargaEsterilIndex = LAYER_INDEXES.PLANES_CARGA.children.ESTERIL;
      const gruposBatimetriasIndex = LAYER_INDEXES.BATIMETRIAS.children.BATIMETRIAS;

      treeCopy[intrumentacionIndex].children[piezoIndex].children = loadPiezometers(promise[0]);
      treeCopy[intrumentacionIndex].children[acelerometroIndex].children = loadAcelerometros(promise[1]);
      treeCopy[intrumentacionIndex].children[estacionesMetIndex].children = loadEstacionesMeteorologicas(promise[2]);
      treeCopy[deformacionesIndex].children[insarIndex].children = loadInsars(promise[3]);
      treeCopy[topografiaIndex].children[ortofotosIndex].children = loadOrtofotos(promise[4]);
      treeCopy[topografiaIndex].children[dtmIndex].children = loadDTM(promise[5]);
      treeCopy[topografiaIndex].children[mapaIndex].children = loadMapa(promise[6]);
      treeCopy[ejesMuroIndex].children[ejesMuroChildernIndex].children = loadEjesMuro(promise[8]);
      treeCopy[topografiaIndex].children[curvasIndex].children = loadCurvasNivel(promise[7]);
      treeCopy[controlOprIndex].children[revanchaIndex].children = loadRevanchas(promise[9]);
      treeCopy[controlOprIndex].children[auxiliaresIndex].children = loadAuxiliares(promise[10]);
      treeCopy[controlOprIndex].children[puntosDescargaIndex].children = loadPuntosDescarga(promise[11]);
      treeCopy[controlOprIndex].children[grietasIndex].children = loadGrietas(promise[12]);
      treeCopy[planesCargaIndex].children[planesCargaEsterilIndex].children = loadPlanesCargaEsteril(promise[13]);
      treeCopy[batimetriasIndex].children[gruposBatimetriasIndex].children = await loadGruposBatimetrias(promise[14]);
      setTreeData(treeCopy);
      setRefreshLayers({});
      setLoadingCategorias(false);
    };
    load();
  }, []);

  const loadPiezometers = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={PiezometroBlackIcon}
            alt="piezometros"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };


  const loadEstacionesMeteorologicas = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={EstacionMeteorlBlackIcon}
            alt="estaciones_meteorologicas"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };

  const loadAcelerometros = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.name,
        icon: (
          <img
            src={AcelerometroBlackIcon}
            alt="acelerometros"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadInsars = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.series.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.etiqueta,
        icon: <img src={InsarBlackIcon} alt="insar" width={18} height={18} />,
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadOrtofotos = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img src={OrtoFotoBlackIcon} alt="ortofotos" width={18} height={18} />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadDTM = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: <img src={DTMIcon} alt="dtm" width={18} height={18} />,
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadMapa = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={OrtoFotoBlackIcon}
            alt="elevaciones"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadCurvasNivel = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={NubePuntosBlackIcon}
            alt="curvasnivel"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };

  const loadEjesMuro = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: <img src={EjesMuroBlackIcon} alt="ejesmuro" width={18} height={18} />
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadRevanchas = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img src={RevanchaBlackIcon} alt="revancha" width={18} height={18} />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadAuxiliares = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={RevanchaBlackIcon}
            alt="auxiliares"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };
  const loadPuntosDescarga = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={RevanchaBlackIcon}
            alt="puntosdescarga"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      return [];
    }
  };

  const loadGrietas = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={EjesMuroBlackIcon}
            alt="grietas"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      return [];
    }
  };

  const loadPlanesCargaEsteril = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.capasEsteril.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.noCapa,
        icon: (
          <img
            src={PlanesCargaBlackIcon}
            alt="planesesteril"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      return [];
    }
  };

  const loadGruposBatimetrias = (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data.grupos.map((p) => ({
        id: `${uuid()}_${p.id}`,
        name: p.nombre,
        icon: (
          <img
            src={BatimetriasBlackIcon}
            alt="batimetrias"
            width={18}
            height={18}
          />
        ),
      }));
    } else {
      //console.error(res)
      return [];
    }
  };

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={JSON.stringify(nodes.id)}
      label={nodes.name}
      icon={nodes.icon || null}
    >
      {nodes.children?.length
        ? nodes.children.map((node) => renderTree(node, false))
        : null}
    </StyledTreeItem>
  );

  if (loadingCategorias)
    return (
      <div className="loading">
        <CircularProgress size={20} />
      </div>
    );
  return (
    <TreeView
      id="styled-tree-view"
      aria-label="customized"
      defaultExpanded={expandedItems}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<MinusSquare />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {treeData.map((child) => renderTree(child, true))}
    </TreeView>
  );
};

export default GruposMenu;
