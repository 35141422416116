import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


const EditAreaDialog = ({ open, handleClose, handleEditArea, area }) => {

  console.log("AREA", area);
  const [name, setName] = useState(area?.nombre);
  const [description, setDescription] = useState(area?.descripcion);
  const [locationX, setLocationX] = useState(area?.ubicacion.x);
  const [locationY, setLocationY] = useState(area?.ubicacion.y);

  const resetValues = () => {
    setName("");
    setDescription("");
    setLocationX("");
    setLocationY("");
  }

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  }

  const handleChangeLocationX = (event) => {
    setLocationX(event.target.value);
  }

  const handleChangeLocationY = (event) => {
    setLocationY(event.target.value);
  }

  const close = () => {
    resetValues();
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Editar Área</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>

          <TextField
            margin="dense"
            id="name"
            label="Nombre"
            fullWidth
            value={name}
            onChange={handleChangeName}
          />
          <TextField
            margin="dense"
            id="description"
            label="Descripción"
            multiline
            rows={4}
            fullWidth
            value={description}
            onChange={handleChangeDescription}
          />
          <InputLabel id="location-group-label">Ubicación</InputLabel>
          <div className="location-group">
            <TextField
              margin="dense"
              id="location-x"
              label="X"
              fullWidth
              value={locationX}
              onChange={handleChangeLocationX}
            />
            <TextField
              margin="dense"
              id="location-y"
              label="Y"
              fullWidth
              value={locationY}
              onChange={handleChangeLocationY}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            close();
          }}>
            Cancelar
          </Button>
          <Button onClick={() => handleEditArea({
            ...area,
            nombre: name,
            descripcion: description,
            ubicacion: {
              x: locationX,
              y: locationY
            }
          })}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditAreaDialog;