import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";

const ImportDataPiezometerDialog = ({ open, handleClose, handleSubmit }) => {
  const [ficheros, setFicheros] = useState();
  const [endColumns, setEndColumns] = useState(new Date());

  const hiddenFileInput = useRef(null);
  const close = () => {
    handleClose();
  }
  const handleClickImportarFicheros = (event) => {
    hiddenFileInput.current.click();
  };
  const handleEndColumns = (event) => {
    const newData = event.target.value !== '' ? parseInt(event.target.value) : null
    setEndColumns(newData);
  }
  const cargarArchivos = (event) => {
    setFicheros(event.target.files[0]);
  }
  const cargarDatosPiezometros = () => {
    handleSubmit({ficheros, endColumns});
    close();
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Importar datos</DialogTitle>
        <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
          <input
            ref={hiddenFileInput}
            type='file'
            style={{ display: 'none' }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={cargarArchivos}
          />
          <Button
              size="sm"
              className="rounded mr-2"
              color={"primary"}
              variant="contained"
              onClick={handleClickImportarFicheros}
              style={{ marginRight: '8px'}}
          >
            Selecionar
          </Button>
          <span>{`${ficheros?.length > 0 ? `${ficheros[0].name}` : 'Ningún fichero seleccionado'}`}</span>

          <div>
            <span>&nbsp;&nbsp;&nbsp;</span>
          </div>

          <TextField
              margin="dense"
              id="endColumns"
              label="Columna Final de Datos"
              fullWidth
              type="number"
              value={endColumns}
              onChange={handleEndColumns}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { close(); }}>
            Cancelar
          </Button>
          <Button onClick={cargarDatosPiezometros}>
            Importar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ImportDataPiezometerDialog;