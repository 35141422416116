import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SismoIcon from "../../../assets/earthquakeBlack.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import "./Sismo.css";
import Sismos from "./Sismos";
import Magnitud from "./Magnitud";
import Distancia from "./Distancia";
import { sismosService } from "../../../services/SismosService";
import moment from "moment";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {toast} from "react-toastify";
import ReceptorEmailForm from "../../Dialogs/ReceptorEmailForm";
import DeleteItemDialog from "../../Dialogs/DeleteItemDialog";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import Reload from "../../../assets/reloadIconBlack.png";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GrupoSismos = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [grupoSismos, setGrupoSismos] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [rowEl, setRowEl] = useState(null);
  const [openRowEl, setOpenRowEl] = useState(null);
  // {
  //   sismos:[],
  //   distancia:{},
  //   magnitud:{}
  // }
  const [loadingGrupoSismos, setLoadingGrupoSismos] = useState(false);

  const [receptoresEmail, setReceptoresEmail] = useState([]);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [openEditEmailDialog, setOpenEditEmailDialog] = useState(false);
  const [openDeleteItemEmailDialog, setOpenDeleteItemEmailDialog] = useState(false);
  const [currentReceptorEmail, setCurrentReceptorEmail] = useState(null);

  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  const handleClose = () => {
    setRowEl(null);
  };

  useEffect(() => {
    let mounted = true;
    mounted && loadGrupoSismos();
    loadReceptoresEmail();
    return () => (mounted = false);
  }, [id]);

  const loadGrupoSismos = async () => {
    setLoadingGrupoSismos(true);
    const fecha = moment(new Date()).format("YYYY-MM-DD");
    const resSismos = await sismosService.getSismosRangoFecha({
      desde: fecha,
      hasta: fecha,
    });
    const resDistancia = await sismosService.getUltimoUmbralDistancia();
    const resMagnitud = await sismosService.getUltimoUmbralMagnitud();
    const dataSismos = {
      sismos: [],
      distancia: {},
      magnitud: {},
    };
    if (resSismos.status >= 200 && resSismos.status < 300) {
      const esArreglo = Array.isArray(resSismos?.data);
      dataSismos.sismos = esArreglo
        ? resSismos.data?.sort(
            (a, b) => new Date(a.fecha).getTime() < new Date(b.fecha).getTime()
          )
        : [];
    } else {
      console.error(resSismos);
    }
    if (resDistancia.status >= 200 && resDistancia.status < 300) {
      dataSismos.distancia = resDistancia.data;
    } else {
      console.error(resDistancia);
    }
    if (resMagnitud.status >= 200 && resMagnitud.status < 300) {
      dataSismos.magnitud = resMagnitud.data;
    } else {
      console.error(resMagnitud);
    }

    setGrupoSismos(dataSismos);
    setLoadingGrupoSismos(false);
  };

  const loadReceptoresEmail = () => {
    setLoadingGrupoSismos(true);
    sismosService.getReceptoresEmail().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setReceptoresEmail(res.data);
      } else {
        console.error(res);
      }
      setLoadingGrupoSismos(false);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseDeleteEmailItemDialog = () => {
    setOpenDeleteItemEmailDialog(false);
  };

  const handleClickDeleteEmailItem = () => {
    handleClose();
    handleCloseRowOption();
    setOpenDeleteItemEmailDialog(true);
  };

  const handleClickAddEmail = () => {
    handleCloseRowOption();
    setOpenAddEmailDialog(true);
  };
  const handleCloseAddEmailDialog = () => {
    setOpenAddEmailDialog(false);
    setCurrentReceptorEmail(null);
  };

  const handleAddReceptorEmail = ({email}) => {
    handleCloseAddEmailDialog();
    setLoadingGrupoSismos(true);
    sismosService
        .crearReceptoresEmail({email})
        .then((res) => {
          update(
              "Se añadío el Email correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadReceptoresEmail();
        })
        .catch((err) => {
          update(
              "Ocurrio un error al crear el receptor email. 🤯 ${res.message}",
              toast.TYPE.ERROR
          );
          console.error(err);
        });
  };

  const handleClickEditEmail = () => {
    handleCloseRowOption();
    setOpenEditEmailDialog(true);
  };
  const handleCloseEditEmailDialog = () => {
    setOpenEditEmailDialog(false);
    setCurrentReceptorEmail(null);
  };

  const handleEditReceptorEmail = ({id, email}) => {
    handleCloseEditEmailDialog();
    setLoadingGrupoSismos(true);
    sismosService
        .updateReceptoresEmail({id, email})
        .then((res) => {
          update(
              "Se actualizó el Email correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadReceptoresEmail();
        })
        .catch((err) => {
          update(
              "Ocurrio un error al editar el receptor email. 🤯 ${res.message}",
              toast.TYPE.ERROR
          );
          console.error(err);
        });
  };

  const handleDeleteEmailItem = () => {
    handleCloseDeleteEmailItemDialog();
    if (currentReceptorEmail) {
      sismosService
          .deleteReceptoresEmail({
            id: currentReceptorEmail.id,
          })
          .then((res) => {
            loadReceptoresEmail();
          })
          .catch((err) => {
            console.error(err);
          });
    }
  };

  const handleClickRowOption = (event) => {
    setRowEl(event.currentTarget);
    setOpenRowEl(event.currentTarget.getAttribute("aria-controls"));
  };
  const handleCloseRowOption = () => {
    setRowEl(null);
    setOpenRowEl(null);
  };

  return (
    <div className="area-container">
      {loadingGrupoSismos ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="head-area">
            <div className="head-name">
              <img
                src={SismoIcon}
                alt=""
                width={18}
                height={18}
                color="black"
              />
              <h2 className="name-area">Grupo Sismos</h2>
            </div>

            <div className="actionsButtons">
              <Button
                  onClick={loadGrupoSismos}
                  sx={{ mr: "0px", color: "white", display: "block" }}
              >
                <img src={Reload} alt="" />
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Sismos" {...a11yProps(0)} />
                <Tab label="Magnitud" {...a11yProps(1)} />
                <Tab label="Distancia" {...a11yProps(2)} />
                <Tab label="Receptores Email para Todos los Sismos" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="table-captura">
              <Sismos
                sismos={grupoSismos?.sismos}
                loadGrupoSismos={loadGrupoSismos}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className="table-captura">
              <Magnitud
                magnitud={grupoSismos?.magnitud}
                loadGrupoSismos={loadGrupoSismos}
              />
            </TabPanel>
            <TabPanel value={value} index={2} className="table-captura">
              <Distancia
                  umbralDistancia={grupoSismos?.distancia}
                  loadGrupoSismos={loadGrupoSismos}
              />
            </TabPanel>
            <TabPanel value={value} index={3} className="table-captura">
              <Button
                  variant="contained"
                  className="btn-add-acelerometro"
                  onClick={handleClickAddEmail}
              >
                Adicionar Email
              </Button>
              &nbsp;
              {receptoresEmail.length ? (
                  <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 700 }}
                        size="small"
                        aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Identificador
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Correo Electrónico
                          </StyledTableCell>
                          <StyledTableCell align="right"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {receptoresEmail.map((row) => {
                          return (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell align="left">
                                  {row.id || "-"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.email || "-"}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={row.id}
                                      aria-haspopup="true"
                                      onClick={handleClickRowOption}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                      id="long-menu"
                                      MenuListProps={{
                                        "aria-labelledby": "long-button",
                                      }}
                                      anchorEl={rowEl}
                                      open={openRowEl === row.id.toString()}
                                      onClose={handleCloseRowOption}
                                      keepMounted
                                  >
                                    <MenuItem
                                        onClick={() => {
                                          setCurrentReceptorEmail(row);
                                          handleClickEditEmail();
                                        }}
                                    >
                                      Editar
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                          setCurrentReceptorEmail(row);
                                          handleClickDeleteEmailItem();
                                        }}
                                    >
                                      Eliminar
                                    </MenuItem>
                                  </Menu>
                                </StyledTableCell>
                              </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
              ) : (
                  <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 700 }}
                        size="small"
                        aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            No existen receptores de email definidos.
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
              )}
            </TabPanel>
          </Box>
        </>
      )}

      {openDeleteItemEmailDialog ? (
          <DeleteItemDialog
              open={openDeleteItemEmailDialog}
              handleClose={handleCloseDeleteEmailItemDialog}
              handleDeleteItem={handleDeleteEmailItem}
          />
      ) : null}
      {openAddEmailDialog ? (
          <ReceptorEmailForm
              open={openAddEmailDialog}
              handleClose={handleCloseAddEmailDialog}
              handleReceptorEmail={handleAddReceptorEmail}
              edit={false}
              usuario={currentReceptorEmail}
          />
      ) : null}
      {openEditEmailDialog ? (
          <ReceptorEmailForm
              open={openEditEmailDialog}
              handleClose={handleCloseEditEmailDialog}
              handleReceptorEmail={handleEditReceptorEmail}
              edit={true}
              usuario={currentReceptorEmail}
          />
      ) : null}
    </div>
  );
};
export default GrupoSismos;
