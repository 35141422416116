import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Divider, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#343434",
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const titulos = [
    { titulo: "Línea de Control", clase: "row-padding tabla-header" },
    { titulo: "Km Analisis", clase: "row-padding tabla-header" },
    { titulo: "Eval Borde Norte", clase: "row-padding tabla-header" },
    { titulo: "Eval Centro 1", clase: "row-padding tabla-header" },
    { titulo: "Eval Centro 2", clase: "row-padding tabla-header" },
    { titulo: "Eval Borde 2 Sur", clase: "row-padding tabla-header" },
    { titulo: "Ancho Capa", clase: "row-padding tabla-header" },
    { titulo: "Ajustes", clase: "row-padding tabla-header" },
    { titulo: "Observaciones", clase: "row-padding tabla-header" }
];

export const MostrarDetallesProtocoloAvanceEsteril = ({open, handleClose, currentProtocolo, selectedProtocolo}) => {
    const [mainProtocolo, setMainProtocolo] = useState( null);

    useEffect(() => {
        if(currentProtocolo !== null && selectedProtocolo.protocolo !== null){
            const result = currentProtocolo.find(({ fechaEmision, noProtocolo }) => fechaEmision === selectedProtocolo.protocolo.fechaEmision && noProtocolo === selectedProtocolo.protocolo.noProtocolo);
            setMainProtocolo(result);
        }
    }, []);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>Datos del Protocolo de Avance Seleccionado</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className="add-dialog-content" style={{paddingTop: '1rem'}}>
                    {(mainProtocolo) &&
                        <div className="infoBox-content">
                            <div className="informacion-planCarga">
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid xs={4}>
                                        <b>Código</b>&nbsp;&nbsp;
                                        <span>
                                          {mainProtocolo.noProtocolo}
                                        </span>
                                    </Grid>

                                    <Grid xs={3}>
                                        <b>Fecha Emisión</b>&nbsp;&nbsp;
                                        <span>
                                          {moment(mainProtocolo.fechaEmision).utc().format("DD-MM-YYYY")}
                                        </span>
                                    </Grid>

                                    <Grid xs={4}>
                                        <b>Observaciones</b>&nbsp;&nbsp;
                                        <span>
                                          {mainProtocolo?.observaciones ? mainProtocolo.observaciones : "-"}
                                        </span>
                                    </Grid>
                                </Grid>
                            </div>
                            <br/>
                            <Divider light />
                            <div className="titulo">
                                <span>DATOS CAPTURADOS</span>
                            </div>
                            <div>
                                <TableContainer component={Paper} className="contenedor-tabla">
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow key={1}>
                                                {titulos.map((row, index) => {
                                                    return (
                                                        <StyledTableCell key={row.titulo} align="center" className={row.clase}>
                                                            {row.titulo}
                                                        </StyledTableCell>
                                                    );
                                                })}
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(mainProtocolo.puntosAvance !== undefined && mainProtocolo.puntosAvance !== null)
                                                && mainProtocolo.puntosAvance.map((row, index) => {
                                                    return (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="center" className="texto-tabla">
                                                                <div className="d-flex align-center justify-center">
                                                                    {row.lineaControl}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla">
                                                                <div className="d-flex align-center justify-center">
                                                                    {row.kmAnalisis}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla">
                                                                <div className="tipo">
                                                                    {row.evaluacionBorde1Norte ? row.evaluacionBorde1Norte.toFixed(2) : "-"}
                                                                </div>
                                                            </  StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla">
                                                                <div className="tipo">
                                                                    {row.evaluacionCentro1 ? row.evaluacionCentro1.toFixed(2) : "-"}
                                                                </div>
                                                            </  StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla">
                                                                <div className="tipo">
                                                                    {row.evaluacionCentro2 ? row.evaluacionCentro2.toFixed(2) : "-"}
                                                                </div>
                                                            </  StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla">
                                                                <div className="tipo">
                                                                    {row.evaluacionBorde2Sur ? row.evaluacionBorde2Sur.toFixed(2) : "-"}
                                                                </div>
                                                            </  StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla row-padding" >
                                                                <div className="d-flex align-center justify-center">
                                                                    {row.anchoCapa ? row.anchoCapa.toFixed(2) : "-"}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla row-padding" >
                                                                {row.ajustes ? "SI" : "NO"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" className="texto-tabla row-padding">
                                                                {(row.observaciones) ? "Cumple" : "No Cumple"}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )})}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div> }
                </DialogContent>
            </Dialog>
        </div>
    )
}
