import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import "./AddDialog.css";
import { Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const AddDialog = ({ open, handleClose, handleSubmit, distancia }) => {
  const [valor, setValor] = useState(
    distancia?.distancia ? distancia.distancia : ""
  );
  const { x, y } = distancia?.origen ? distancia.origen : { x: "", y: "" };
  const [origenX, setOrigenX] = useState(x);
  const [origenY, setOrigenY] = useState(y);
  const [fecha, setFecha] = useState(
      distancia?.creado ? Date.parse(new Date(distancia?.creado).toISOString()) : new Date());

  const handleChangeValor = (event) => {
    setValor(event.target.value);
  };

  const handleChangeX = (event) => {
    setOrigenX(event.target.value);
  };

  const handleChangeY = (event) => {
    setOrigenY(event.target.value);
  };

  const handleChangeFecha = (newFecha) => {
    setFecha(newFecha);
  };

  const close = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          {distancia ? "Editar elemento" : "Adicionar elemento"}
        </DialogTitle>
        <DialogContent style={{ paddingTop: "1rem" }}></DialogContent>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Fecha"
                inputFormat="MM/dd/yyyy"
                value={fecha}
                onChange={handleChangeFecha}
                renderInput={(params) => <TextField {...params} />}
              />
              <TextField
                margin="dense"
                id="valor"
                label="Distancia"
                fullWidth
                value={valor}
                onChange={handleChangeValor}
              />
              <div>
                <TextField
                  margin="dense"
                  id="origenX"
                  label="Coordenadas Origen X"
                  fullWidth
                  value={origenX}
                  onChange={handleChangeX}
                />
                <TextField
                  margin="dense"
                  id="origenY"
                  label="Coordenadas Origen Y"
                  fullWidth
                  value={origenY}
                  onChange={handleChangeY}
                />
              </div>
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancelar</Button>
          <Button
            disabled={!valor || !origenX || !origenY}
            onClick={() => {
              handleSubmit({ distancia: valor, fecha, origenX, origenY });
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddDialog;
