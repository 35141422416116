import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const CreateExcelConfigDialog = ({ open, handleClose, handleSubmit }) => {
  const [initialRow, setInitialRow] = useState('');
  const [etiquetaCell, setEtiquetaCell] = useState('');
  const [ubicacionNorteCell, setUbicacionNorteCell] = useState('');
  const [ubicacionEsteCell, setUbicacionEsteCell] = useState('');
  const [cotaInferiorCell, setCotaInferiorCell] = useState('');
  const [cotaSuperiorCell, setCotaSuperiorCell] = useState('');

  const handleInitialRow = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setInitialRow(newData);
  }

  const handleEtiquetaCell = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setEtiquetaCell(newData);
  }

  const handleUnicacionNorteCell = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setUbicacionNorteCell(newData);
  };

  const handleUnicacionEsteCell = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setUbicacionEsteCell(newData);
  };

  const handleCotaSuperiorCell = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setCotaSuperiorCell(newData);
  };

  const handleCotaInferiorCell = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setCotaInferiorCell(newData);
  };

  const close = () => {
    handleClose();
  }

  const crearConfiguration = () => {
    handleSubmit({initialRow, etiquetaCell, ubicacionNorteCell,
      ubicacionEsteCell, cotaInferiorCell, cotaSuperiorCell});
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Crear Configuración del Excel</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <TextField
                margin="dense"
                id="initialRow"
                label="Fila Inicial"
                fullWidth
                type="number"
                value={initialRow}
                onChange={handleInitialRow}
              />
              <TextField
                margin="dense"
                id="etiquetaCell"
                label="Celda de la Etiqueta"
                fullWidth
                type="number"
                value={etiquetaCell}
                onChange={handleEtiquetaCell}
              />
              <TextField
                  margin="dense"
                  id="ubicacionNorteCell"
                  label="Celda de Ubicación Norte"
                  fullWidth
                  type="number"
                  value={ubicacionNorteCell}
                  onChange={handleUnicacionNorteCell}
              />
              <TextField
                  margin="dense"
                  id="ubicacionEsteCell"
                  label="Celda de Ubicación Este"
                  fullWidth
                  type="number"
                  value={ubicacionEsteCell}
                  onChange={handleUnicacionEsteCell}
              />
              <TextField
                  margin="dense"
                  id="cotaSuperiorCell"
                  label="Celda de Cota Superior"
                  fullWidth
                  type="number"
                  value={cotaSuperiorCell}
                  onChange={handleCotaSuperiorCell}
              />
              <TextField
                  margin="dense"
                  id="cotaInferiorCell"
                  label="Celda de Cota Inferior"
                  fullWidth
                  type="number"
                  value={cotaInferiorCell}
                  onChange={handleCotaInferiorCell}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            Cancelar
          </Button>
          <Button onClick={crearConfiguration}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default CreateExcelConfigDialog;