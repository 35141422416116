import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Step, StepButton, StepLabel, Stepper} from "@mui/material";
import Grid from "@mui/material/Grid";

const steps = ['Paso 1', 'Paso 2'];

const CreateExcelAvanceConfigDialog = ({ open, handleClose, handleSubmit, excelConfig }) => {
  const [config, setConfig] = useState(excelConfig)
  const [noProtocoloRow, setNoProtocoloRow] = useState()
  const [noProtocoloCell,  setNoProtocoloCell] = useState()
  const [fechaEmisionRow, setFechaEmisionRow] = useState()
  const [fechaEmisionCell, setFechaEmisionCell] = useState()
  const [observacionesRow, setObservacionesRow] = useState()
  const [observacionesCell,  setObservacionesCell] = useState()
  const [puntosRecepcionStartRow, setPuntosRecepcionStartRow] = useState()
  const [lineaControlCell, setLineaControlCell] = useState()
  const [kmAnalisisCell, setKmAnalisisCell] = useState()
  const [evaluacionBorde1NorteCell, setEvaluacionBorde1NorteCell] = useState()
  const [evaluacionCentro1Cell, setEvaluacionCentro1Cell] = useState()
  const [evaluacionCentro2Cell, setEvaluacionCentro2Cell] = useState()
  const [evaluacionBorde2SurCell, setEvaluacionBorde2SurCell] = useState()
  const [anchoCapaCell,  setAnchoCapaCell] = useState()
  const [ajustesCell, setAjustesCell] = useState()
  const [observacionesPtoCell, setObservacionesPtoCell] = useState()

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  useEffect(() => {
    if(excelConfig){
      setNoProtocoloRow(config.noProtocoloRow)
      setNoProtocoloCell(config.noProtocoloCell)
      setFechaEmisionRow(config.fechaEmisionRow)
      setFechaEmisionCell(config.fechaEmisionCell)
      setObservacionesRow(config.observacionesRow)
      setObservacionesCell(config.observacionesCell)
      setPuntosRecepcionStartRow(config.puntosRecepcionStartRow)
      setLineaControlCell(config.lineaControlCell)
      setKmAnalisisCell(config.kmAnalisisCell)
      setEvaluacionBorde1NorteCell(config.evaluacionBorde1NorteCell)
      setEvaluacionCentro1Cell(config.evaluacionCentro1Cell)
      setEvaluacionCentro2Cell(config.evaluacionCentro2Cell)
      setEvaluacionBorde2SurCell(config.evaluacionBorde2SurCell)
      setAnchoCapaCell(config.anchoCapaCell)
      setAjustesCell(config.ajustesCell)
      setObservacionesPtoCell(config.observacionesPtoCell)
    }
  }, [excelConfig])

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleNoProtocolRow = (event) => {
    setNoProtocoloRow(parseInt(event.target.value));
  }

  const handleNoProtocolCell = (event) => {
    setNoProtocoloCell(parseInt(event.target.value));
  }

  const handleFechaEmisionRow = (event) => {
    setFechaEmisionRow(parseInt(event.target.value));
  };

  const handleFechaEmisionCell = (event) => {
    setFechaEmisionCell(parseInt(event.target.value));
  };

  const handleObservacionesRow = (event) => {
    setObservacionesRow(parseInt(event.target.value));
  };

  const handleObservacionesCell = (event) => {
    setObservacionesCell(parseInt(event.target.value));
  };

  const handlePuntosRecepcionStartRow = (event) => {
    setPuntosRecepcionStartRow(parseInt(event.target.value))
  }

  const handleLineaControlCell = (event) => {
    setLineaControlCell(parseInt(event.target.value))
  }

  const handleKmAnalisisCell = (event) => {
    setKmAnalisisCell(parseInt(event.target.value))
  }

  const handleEvaluacionBorde1NorteCell = (event) => {
    setEvaluacionBorde1NorteCell(parseInt(event.target.value))
  }

  const handleEvaluacionCentro1Cell = (event) => {
    setEvaluacionCentro1Cell(parseInt(event.target.value))
  }

  const handleEvaluacionCentro2Cell = (event) => {
    setEvaluacionCentro2Cell(parseInt(event.target.value))
  }

  const handleEvaluacionBorde2SurCell = (event) => {
    setEvaluacionBorde2SurCell(parseInt(event.target.value))
  }

  const handleAnchoCapaCell = (event) => {
    setAnchoCapaCell(parseInt(event.target.value))
  }

  const handleAjsutesCell = (event) => {
    setAjustesCell(parseInt(event.target.value))
  }

  const handleObservacionesPtoCell = (event) => {
    setObservacionesPtoCell(parseInt(event.target.value))
  }

  const close = () => {
    handleClose();
  }

  const crearConfiguration = () => {
      handleSubmit({noProtocoloRow, noProtocoloCell, fechaEmisionRow, fechaEmisionCell, observacionesRow,
        observacionesCell, puntosRecepcionStartRow, lineaControlCell, kmAnalisisCell, evaluacionBorde1NorteCell,
        evaluacionCentro1Cell, evaluacionCentro2Cell, evaluacionBorde2SurCell, anchoCapaCell, ajustesCell, observacionesPtoCell});
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>{(excelConfig) ? 'Editar' : 'Crear' } Configuración del Excel</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {(activeStep) === 0 &&
                      <div>
                          <div>&nbsp;</div>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="noProtocoloRow"
                                label="Fila No. Protocolo"
                                fullWidth
                                type="number"
                                value={noProtocoloRow}
                                onChange={handleNoProtocolRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="noProtocoloCell"
                                label="Celda No. Protocolo"
                                fullWidth
                                type="number"
                                value={noProtocoloCell}
                                onChange={handleNoProtocolCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="fechaEmisionRow"
                                label="Fila Fecha Emisión"
                                fullWidth
                                type="number"
                                value={fechaEmisionRow}
                                onChange={handleFechaEmisionRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="fechaEmisionCell"
                                label="Celda Fecha Emisión"
                                fullWidth
                                type="number"
                                value={fechaEmisionCell}
                                onChange={handleFechaEmisionCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="observacionesRow"
                                label="Fila Observaciones"
                                fullWidth
                                type="number"
                                value={observacionesRow}
                                onChange={handleObservacionesRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="observacionesCell"
                                label="Celda Observaciones"
                                fullWidth
                                type="number"
                                value={observacionesCell}
                                onChange={handleObservacionesCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="puntosRecepcionStartRow"
                                label="Fila Puntos Recepción"
                                fullWidth
                                type="number"
                                value={puntosRecepcionStartRow}
                                onChange={handlePuntosRecepcionStartRow}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="lineaControlCell"
                                label="Línea de Control"
                                fullWidth
                                type="number"
                                value={lineaControlCell}
                                onChange={handleLineaControlCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="kmAnalisisCell"
                                label="Celda Km/s Análisis"
                                fullWidth
                                type="number"
                                value={kmAnalisisCell}
                                onChange={handleKmAnalisisCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="evaluacionBorde1NorteCell"
                                label="Eval Borde Norte"
                                fullWidth
                                type="number"
                                value={evaluacionBorde1NorteCell}
                                onChange={handleEvaluacionBorde1NorteCell}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    }
                    {(activeStep) === 1 &&
                      <div>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="evaluacionCentro1Cell"
                                label="Eval Centr 1"
                                fullWidth
                                type="number"
                                value={evaluacionCentro1Cell}
                                onChange={handleEvaluacionCentro1Cell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="evaluacionCentro2Cell"
                                label="Eval Centr 2"
                                fullWidth
                                type="number"
                                value={evaluacionCentro2Cell}
                                onChange={handleEvaluacionCentro2Cell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="evaluacionBorde2SurCell"
                                label="Eval Borde Sur"
                                fullWidth
                                type="number"
                                value={evaluacionBorde2SurCell}
                                onChange={handleEvaluacionBorde2SurCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="anchoCapa"
                                label="Ancho Capa"
                                fullWidth
                                type="number"
                                value={anchoCapaCell}
                                onChange={handleAnchoCapaCell}
                            />
                          </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="ajustesCell"
                                label="Ajustes"
                                fullWidth
                                type="number"
                                value={ajustesCell}
                                onChange={handleAjsutesCell}
                            />
                          </Grid>

                          <Grid xs={5}>
                            <TextField
                                margin="dense"
                                id="observacionesPtoCell"
                                label="Observaciones Punto"
                                fullWidth
                                type="number"
                                value={observacionesPtoCell}
                                onChange={handleObservacionesPtoCell}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    }
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button onClick={close}>
                      Cancelar
                    </Button>

                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <Button onClick={handleNext}>
                      {activeStep !== steps.length - 1 && 'Next'}
                    </Button>

                    {activeStep === steps.length - 1 &&
                        <Button onClick={crearConfiguration}>
                          Aceptar
                        </Button>
                    }
                  </Box>
                </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default CreateExcelAvanceConfigDialog;