import React from "react";
import "../Index.css";
import { Card, CardContent, Skeleton } from "@mui/material";
import {
  CheckCircleOutline,
  WifiTetheringOffOutlined,
  NotificationsNone,
  WarningAmber,
} from "@mui/icons-material";
import moment from "moment";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';	
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const DashboardLeft = ({
  resumenDashBoard,
  planesCargaDashBoard
}) => {
  return (
    <>
      <h3>Piezómetros</h3>

      {resumenDashBoard ? (
        <div className="dashboard-card-left-top">
          <div className="dashboard-card-leftTop-left">
            <Card>
              <CardContent
                className={
                  resumenDashBoard.totalPiezometrosNormal > 0
                    ? "piezometro-state-normal"
                    : "piezometro-state-ndefault"
                }
              >
                <span>Normal</span>
                <div
                  className={
                    resumenDashBoard.totalPiezometrosNormal > 0
                      ? "piezometro-state-normal-info"
                      : "piezometro-state-ndefault-info"
                  }
                >
                  <p>{resumenDashBoard.totalPiezometrosNormal}</p>
                  <CheckCircleOutline />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="dashboard-card-leftTop-rigth">
            <div className="dashboard-card-leftTop-rigthInside">
              <Card>
                <CardContent
                  className={
                    resumenDashBoard.totalPiezometrosAdvertencia > 0
                      ? "piezometro-state-advert"
                      : "piezometro-state-default"
                  }
                >
                  <span>Advertencia</span>
                  <div className="piezometro-state-others-info">
                    <p>{resumenDashBoard.totalPiezometrosAdvertencia}</p>
                    <NotificationsNone />
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardContent
                  className={
                    resumenDashBoard.totalPiezometrosAlarma > 0
                      ? "piezometro-state-alarm"
                      : "piezometro-state-default"
                  }
                >
                  <span>Alarma</span>
                  <div className="piezometro-state-others-info">
                    <p>{resumenDashBoard.totalPiezometrosAlarma}</p>
                    {/*<img src={AlarmLightIcon} alt="" className="icon-img"/>*/}
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="dashboard-card-leftTop-rigthInside">
              <Card>
                <CardContent
                  className={
                    resumenDashBoard.totalPiezometrosAlerta > 0
                      ? "piezometro-state-alert"
                      : "piezometro-state-default"
                  }
                >
                  <span>Alerta</span>
                  <div className="piezometro-state-others-info">
                    <p>{resumenDashBoard.totalPiezometrosAlerta}</p>
                    <WarningAmber />
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardContent
                  className={
                    resumenDashBoard.totalPiezometrosOffline > 0
                      ? "piezometro-state-offline"
                      : "piezometro-state-default"
                  }
                >
                  <span>F. de Línea</span>
                  <div className="piezometro-state-others-info">
                    <p>{resumenDashBoard.totalPiezometrosOffline}</p>
                    <WifiTetheringOffOutlined />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboard-card-left-top">
          <div className="dashboard-card-leftTop-left">
            <Card style={{ boxShadow: "none" }}>
              <Skeleton>
                <CardContent className="piezometro-state-ndefault">
                  <div className="piezometro-state-ndefault-info">
                    <CheckCircleOutline />
                  </div>
                </CardContent>
              </Skeleton>
            </Card>
          </div>

          <div className="dashboard-card-leftTop-rigth">
            <div className="dashboard-card-leftTop-rigthInside">
              <Card style={{ boxShadow: "none" }}>
                <Skeleton>
                  <CardContent className="piezometro-state-default">
                    <div className="piezometro-state-others-info">
                      <WifiTetheringOffOutlined />
                    </div>
                  </CardContent>
                </Skeleton>
              </Card>
              <Card style={{ boxShadow: "none" }}>
                <Skeleton>
                  <CardContent className="piezometro-state-default">
                    <div className="piezometro-state-others-info">
                      <WifiTetheringOffOutlined />
                    </div>
                  </CardContent>
                </Skeleton>
              </Card>
            </div>
          </div>
          <div className="dashboard-card-leftTop-rigth">
            <div className="dashboard-card-leftTop-rigthInside">
              <Card style={{ boxShadow: "none" }}>
                <Skeleton>
                  <CardContent className="piezometro-state-default">
                    <div className="piezometro-state-others-info">
                      <WifiTetheringOffOutlined />
                    </div>
                  </CardContent>
                </Skeleton>
              </Card>
              <Card style={{ boxShadow: "none" }}>
                <Skeleton>
                  <CardContent className="piezometro-state-default">
                    <div className="piezometro-state-others-info">
                      <WifiTetheringOffOutlined />
                    </div>
                  </CardContent>
                </Skeleton>
              </Card>
            </div>
          </div>
        </div>
      )}

      <div className="dashboard-card-left-down">
        <h3>Últimos 5 Capas de Plan de Carga</h3>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }}>
                <TableHead>
                    <StyledTableRow key={1}>
                        <StyledTableCell>Capa</StyledTableCell>
                        <StyledTableCell align="center">Sector</StyledTableCell>
                        <StyledTableCell align="center">Elev. Requerida</StyledTableCell>
                        <StyledTableCell align="center">Esp. Objetivo</StyledTableCell>
                        <StyledTableCell align="center">Tipo</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                {planesCargaDashBoard.map((row) => (
                    <StyledTableRow key={row.id}>
                        <StyledTableCell width={100}>{row.noCapa}</StyledTableCell>
                        <StyledTableCell align="center">{row.sector}</StyledTableCell>
                        <StyledTableCell align="center">{row.elevacionRequerida} m</StyledTableCell>
                        <StyledTableCell align="center">{row.espesorObjetivo} m</StyledTableCell>
                        <StyledTableCell align="center">{row.tipo}</StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default DashboardLeft;
