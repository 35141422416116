export const LAYER_MENU = "CAPAS";
export const GROUP_MENU = "GRUPOS";
export const SECTION_MENU = "SECCIONAMIENTO";
export const REFRERENCE_SYSTEM_MENU = "SISTEMAS DE REFERENCIA";
export const LAYER_TYPES = {
  ORTOFOTOS: "ORTOFOTOS",
  DTM: "DTM",
  ELEVACIONES: "ELEVACIONES",
  NUBES_PUNTOS: "NUBES_PUNTOS",
  INSAR: "INSAR",
  PIEZOMETROS: "PIEZOMETROS",
  ACELEROMETROS: "ACELEROMETROS",
  RADARES: "RADARES",
  EJES_MURO: "EJES_MURO",
  CURVAS_NIVEL: "CURVAS_NIVEL",
  REVANCHA: "REVANCHA",
  PUNTOS_DESCARGA: "PUNTOS_DESCARGA",
  AUXILIARES: "AUXILIARES",
  GRIETAS: "GRIETAS",
  PLANES_ESTERIL: "PLANES_ESTERIL",
  PLANES_TRANSICION: "PLANES_TRANSICION",
  BATIMETRIAS: "BATIMETRIAS",
  ESTACIONES_METEOROLOGICAS: "ESTACIONES_METEOROLOGICAS",
  AREAS: "AREAS",
  SECTORES: "SECTORES"
};
export const SISMOS_MENU = "SISMOS";

export const USERS_MENU = "USERS";
