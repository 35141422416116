import React, { useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import '../AddElementoDialog.css'

const FILE_TYPES = ".tif";
const FILE_TEXT = "Seleccione un archivo (*.tif)";

const EditarMapaDistanciaDialog = ({ open, handleClose, handleSubmit, mapaDistancia}) => {
    const [fichero, setFichero] = useState();
    const [itemId, setItemId] = useState(mapaDistancia.itemId)
    const [urlStorage, setUrlStorage] = useState(mapaDistancia.urlStorage)
    const [distanciaSpigot2, setDistanciaSpigot2] = useState(mapaDistancia.distanciaSpigot2)
    const [distanciaSpigot3, setDistanciaSpigot3] = useState(mapaDistancia.distanciaSpigot3)
    const [distanciaSpigot6, setDistanciaSpigot6] = useState(mapaDistancia.distanciaSpigot6)
    const [distanciaSpigot7, setDistanciaSpigot7] = useState(mapaDistancia.distanciaSpigot7)

    const hiddenFileInput = useRef(null);

    const handleChangeDistanciaSpigot2 = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setDistanciaSpigot2(newData);
    }

    const handleChangeDistanciaSpigot3 = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setDistanciaSpigot3(newData);
    }

    const handleChangeDistanciaSpigot6 = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setDistanciaSpigot6(newData);
    }

    const handleChangeDistanciaSpigot7 = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setDistanciaSpigot7(newData);
    }

    const close = () => {
        handleClose();
    }

    const handleClickImportar = (event) => {
        hiddenFileInput.current.click();
    };

    const cargarFichero = (event) => {
        setFichero(event.target.files[0]);
    }

    const editarMapaDistancia = () => {
        handleSubmit({ itemId, urlStorage, distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7, fichero })
    }

    return (
        <div>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Editar Datos Mapa de Distancia</DialogTitle>
                <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
                    <Stack spacing={3}>
                        <div>
                            <TextField
                                margin="dense"
                                id="distanciaSpigot2"
                                label="Distancia Spigot 2"
                                fullWidth
                                value={distanciaSpigot2}
                                type="number"
                                onChange={handleChangeDistanciaSpigot2}
                            />

                            <TextField
                                margin="dense"
                                id="distanciaSpigot3"
                                label="Distancia Spigot 3"
                                fullWidth
                                value={distanciaSpigot3}
                                type="number"
                                onChange={handleChangeDistanciaSpigot3}
                            />

                            <TextField
                                margin="dense"
                                id="distanciaSpigot6"
                                label="Distancia Spigot 6"
                                fullWidth
                                value={distanciaSpigot6}
                                type="number"
                                onChange={handleChangeDistanciaSpigot6}
                            />

                            <TextField
                                margin="dense"
                                id="distanciaSpigot7"
                                label="Distancia Spigot 7"
                                fullWidth
                                value={distanciaSpigot7}
                                type="number"
                                onChange={handleChangeDistanciaSpigot7}
                            />

                            <InputLabel id="select-datos-label">Archivo</InputLabel>
                            <div className="select-datos">
                                <input
                                    ref={hiddenFileInput}
                                    type='file'
                                    style={{ display: 'none' }}
                                    // accept={FILE_TYPES}
                                    onChange={cargarFichero}
                                />
                                <Button
                                    size="sm"
                                    className="rounded mr-2"
                                    color={"primary"}
                                    variant="contained"
                                    onClick={handleClickImportar}
                                    style={{ marginRight: '8px' }}
                                >
                                    Selecionar
                                </Button>
                                <span>{fichero ? fichero.name : FILE_TEXT}</span>
                            </div>
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        close();
                    }}>
                        Cancelar
                    </Button>
                    <Button disabled={!distanciaSpigot2  || !distanciaSpigot3 || !distanciaSpigot6 || !distanciaSpigot7}
                            onClick={editarMapaDistancia}>
                        Subir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default EditarMapaDistanciaDialog;