import React, { useReducer, createContext, useContext } from "react";

const initialState = {
    isOpenDrawerMenu: {
        LAYER_MENU: false,
        GROUP_MENU: false,
        SECTION_MENU: false,
        REFRERENCE_SYSTEM_MENU: false,
    }
};

const ACTIONS = {
    SET_OPEN_DRAWER_MENU: "SET_OPEN_DRAWER_MENU",
    CLOSE_ALL_DRAWER_MENU: "CLOSE_ALL_DRAWER_MENU",
};

const reducer = (state, data) => {
    switch (data.action) {
        case ACTIONS.SET_OPEN_DRAWER_MENU: {
            const { menu, value } = data.payload;
            return {
                ...state,
                isOpenDrawerMenu: {
                    [menu]: value
                }
            };
        };

        case ACTIONS.CLOSE_ALL_DRAWER_MENU:
            return {
                ...state,
                isOpenDrawerMenu: {
                    LAYER_MENU: false,
                    GROUP_MENU: false,
                    SECTION_MENU: false,
                    REFRERENCE_SYSTEM_MENU: false,
                }
            };

        default:
            throw new Error();
    }
};

const DrawerMenuContext = createContext(initialState);

const DrawerMenuProvider = ({ children }) => {
    const stateAndDispatch = useReducer(reducer, initialState);
    return (
        <DrawerMenuContext.Provider value={stateAndDispatch}>
            {children}
        </DrawerMenuContext.Provider>
    );
}

export const useDrawerMenu = () => useContext(DrawerMenuContext);

export { DrawerMenuContext, DrawerMenuProvider, ACTIONS };
