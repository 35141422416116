import React, { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { SketchPicker } from 'react-color'
import './PaletaColores.css'

const PaletaColores = ({ onChangeComplete, color }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '40px', justifyContent: 'center' }}>
                <Tooltip title="Seleccionar color">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{
                            border: open ? "1px solid #b2b2b2" : 0,
                            padding: '5px',
                            borderRadius: '4px',

                        }}
                    >
                        <Avatar variant="rounded" sx={{ width: '24px', height: '24px', backgroundColor: color }}>{" "}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        marginLeft: '-5px',
                        '& .MuiMenu-list': {
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    },
                }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <SketchPicker
                    color={color}
                    onChangeComplete={onChangeComplete}
                />
            </Menu>
        </Fragment>
    );
}
export default PaletaColores;