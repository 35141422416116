import { useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import { Route, Routes } from "react-router-dom";
import { Home } from './pages/Home';
import { loginRequest, b2cPolicies, protectedResources } from './authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';

import './index.css';

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  
  return (
    <Routes>
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  useEffect(() => {
    if (instance) {
      instance.initialize()
        .then(() => {
          instance.handleRedirectPromise()
            .then((res) => {
              if (!res && !instance.getActiveAccount()) {
                instance.loginRedirect(loginRequest);
              }
            })
            .catch((error) => console.log(error));
        })
    }
  }, [instance]);


  return (
    <MsalProvider instance={instance}>
      {
        <Pages />
      }
    </MsalProvider>
  );
};

export default App;