import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './SystemReferences.css';
import Information from '../../../assets/informationBlack.png';
import { useParams } from 'react-router-dom';
import { useSystemReferences, ACTIONS } from '../../../providers/SystemReferencesProvider';
import DeleteItemDialog from '../../Dialogs/DeleteItemDialog';
import AddSysRefDialog from '../../Dialogs/AddSysRefDialog';
import { useNavigate } from "react-router-dom";
import CoordinationBlackIcon from '../../../assets/coordinationBlack.png';


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const SystemReference = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sysRefContext, setSectionsContext] = useSystemReferences();

  const sysRef = sysRefContext.systemReferences.filter(sr => sr.id === id)[0];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // eliminar sys ref
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

  const handleCloseDleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleClickDeleteItem = () => {
    handleClose();
    setOpenDeleteItemDialog(true);
  };

  const handleDeleteItem = () => {
    handleCloseDleteItemDialog();
    setSectionsContext({
      action: ACTIONS.DELETE_SYS_REF,
      payload: { id }
    });
    navigate('/');
  };
  //edit sys ref
  const [openEditSysRefDialog, setOpenEditSysRefDialog] = useState(false);

  const handleCloseEditSysRefDialog = () => {
    setOpenEditSysRefDialog(false);
  };

  const handleClickEditSysRef = () => {
    handleClose();
    setOpenEditSysRefDialog(true);
  };

  const handleEditSysRef = (sysRefEdited) => {
    handleCloseEditSysRefDialog();
    setSectionsContext({
      action: ACTIONS.UPDATE_SYS_REF,
      payload: { sysRef: sysRefEdited }
    })
  };

  //tabla
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#343434",
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData( 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  ];
  //tabla

  return (
    <div className="area-container">
      <div className="head-area">
        <div className="head-name">
        <img src={CoordinationBlackIcon} alt="" width={18} height={18}/>
        <h2 className="name-area">{sysRef ? sysRef.name : 'Sistema de referencia inexistente'}</h2>
        </div>
        {sysRef ? <Button
          onClick={handleClick}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          <img src={Information} alt="Sistema Referencia" />
        </Button> : null}
      </div>
      {sysRef ?
        <>
          <div className="container-table">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center"colSpan={6}>Desde WGS84</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    {/* <StyledTableCell>Coef. NN</StyledTableCell> */}
                    <StyledTableCell align="center">Coef. NN</StyledTableCell>
                    <StyledTableCell align="center">Coef. NE</StyledTableCell>
                    <StyledTableCell align="center">TN</StyledTableCell>
                    <StyledTableCell align="center">Coef. EN</StyledTableCell>
                    <StyledTableCell align="center">Coef. EE</StyledTableCell>
                    <StyledTableCell align="center">TE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      {/* <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">{row.calories}</StyledTableCell>
                      <StyledTableCell align="center">{row.calories}</StyledTableCell>
                      <StyledTableCell align="center">{row.calories}</StyledTableCell>
                      <StyledTableCell align="center">{row.fat}</StyledTableCell>
                      <StyledTableCell align="center">{row.carbs}</StyledTableCell>
                      <StyledTableCell align="center">{row.protein}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="container-table">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={6}>Hacia WGS84</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="center" >Coef. NN</StyledTableCell>
                    <StyledTableCell align="center">Coef. NE</StyledTableCell>
                    <StyledTableCell align="center">TN</StyledTableCell>
                    <StyledTableCell align="center">Coef. EN</StyledTableCell>
                    <StyledTableCell align="center">Coef. EE</StyledTableCell>
                    <StyledTableCell align="center">TE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell align="center">{row.calories}</StyledTableCell>
                      <StyledTableCell align="center">{row.calories}</StyledTableCell>
                      <StyledTableCell align="center">{row.calories}</StyledTableCell>
                      <StyledTableCell align="center">{row.fat}</StyledTableCell>
                      <StyledTableCell align="center">{row.carbs}</StyledTableCell>
                      <StyledTableCell align="center">{row.protein}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Menu
            id="section-menu"
            // MenuListProps={{
            //   'aria-labelledby': 'long-button',
            // }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            keepMounted
          >

            <MenuItem onClick={handleClickEditSysRef}>Editar</MenuItem>
            <MenuItem onClick={handleClickDeleteItem}>Eliminar</MenuItem>
          </Menu>
        </> : null}

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      <AddSysRefDialog
        open={openEditSysRefDialog}
        handleClose={handleCloseEditSysRefDialog}
        handleAddSysRef={handleEditSysRef}
        sysReference={sysRef}
      />

    </div>
  );
}

export default SystemReference;