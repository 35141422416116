import React, { useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import './EjesMuro.css'

const FILE_TYPES = ".geojson, .geo.json";
const FILE_TEXT = "Seleccione un archivo (*.geojson)";

const AddDialog = ({ open, handleClose, handleSubmit, }) => {
    const [fichero, setFichero] = useState();
    const [nombreCapa, setNombreCapa] = useState();
    const [fecha, setFecha] = useState(new Date());

    const hiddenFileInput = useRef(null);

    const handleChange = (newFecha) => {
        setFecha(newFecha);
    };

    const close = () => {
        handleClose();
    }

    const handleClickImportar = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChangeNombreCapa = (event) => {
        setNombreCapa(event.target.value);
    };

    const cargarFichero = (event) => {
        setFichero(event.target.files[0]);
    }

    const uploadFiles = () => {
        handleSubmit({ fecha, ficheros:fichero, nombreCapa })
    }

    return (
        <div>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Subir eje de muro</DialogTitle>
                <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Fecha"
                                inputFormat="MM/dd/yyyy"
                                value={fecha}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <TextField
                                margin="dense"
                                id="name"
                                label="Nombre de la Capa"
                                fullWidth
                                value={nombreCapa}
                                onChange={handleChangeNombreCapa}
                            />

                            <div>
                                <InputLabel id="select-datos-label">Eje de muro</InputLabel>
                                <div className="select-datos">
                                    <input
                                        ref={hiddenFileInput}
                                        type='file'
                                        style={{ display: 'none' }}
                                        accept={FILE_TYPES}
                                        onChange={cargarFichero}
                                    />
                                    <Button
                                        size="sm"
                                        className="rounded mr-2"
                                        color={"primary"}
                                        variant="contained"
                                        onClick={handleClickImportar}
                                        style={{ marginRight: '8px' }}
                                    >
                                        Selecionar
                                    </Button>
                                    <span>{fichero ? fichero.name : FILE_TEXT}</span>
                                </div>
                            </div>
                        </Stack>
                    </LocalizationProvider>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        close();
                    }}>
                        Cancelar
                    </Button>
                    <Button disabled={!fichero}
                        onClick={uploadFiles}>
                        Subir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default AddDialog;