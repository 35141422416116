import React, { useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import '../AddElementoDialog.css'

const FILE_TYPES = ".tif";
const FILE_TEXT = "Seleccione un archivo (*.tif)";

const CrearMapaSuperficieRelaveDialog = ({ open, handleClose, handleSubmit, }) => {
    const [fichero, setFichero] = useState();
    const [superficieOcupacionRelave, setSuperficieOcupacionRelave] = useState()

    const hiddenFileInput = useRef(null);

    const handleSuperficieOcupacionRelave = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setSuperficieOcupacionRelave(newData);
    }

    const close = () => {
        handleClose();
    }

    const handleClickImportar = (event) => {
        hiddenFileInput.current.click();
    };

    const cargarFichero = (event) => {
        setFichero(event.target.files[0]);
    }

    const crearMapaDistancia = () => {
        handleSubmit({ superficieOcupacionRelave, fichero })
    }

    return (
        <div>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Crear Datos Mapa de Superficie Relave</DialogTitle>
                <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
                    <Stack spacing={3}>
                        <div>
                            <TextField
                                margin="dense"
                                id="superficieOcupacionRelave"
                                label="Superficie Ocupación de Relave"
                                fullWidth
                                value={superficieOcupacionRelave}
                                type="number"
                                onChange={handleSuperficieOcupacionRelave}
                            />

                            <InputLabel id="select-datos-label">Archivo</InputLabel>
                            <div className="select-datos">
                                <input
                                    ref={hiddenFileInput}
                                    type='file'
                                    style={{ display: 'none' }}
                                    // accept={FILE_TYPES}
                                    onChange={cargarFichero}
                                />
                                <Button
                                    size="sm"
                                    className="rounded mr-2"
                                    color={"primary"}
                                    variant="contained"
                                    onClick={handleClickImportar}
                                    style={{ marginRight: '8px' }}
                                >
                                    Selecionar
                                </Button>
                                <span>{fichero ? fichero.name : FILE_TEXT}</span>
                            </div>
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        close();
                    }}>
                        Cancelar
                    </Button>
                    <Button disabled={!fichero || !superficieOcupacionRelave}
                            onClick={crearMapaDistancia}>
                        Subir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default CrearMapaSuperficieRelaveDialog;