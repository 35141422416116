import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Sismos = ({ sismos }) => {
  return (
    <div>
      <div className="container-table">
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Identificador</StyledTableCell>
                <StyledTableCell align="center">Lugar</StyledTableCell>
                <StyledTableCell align="center">Fecha</StyledTableCell>
                <StyledTableCell align="center">Magnitud</StyledTableCell>
                <StyledTableCell align="center">Profundidad</StyledTableCell>
                <StyledTableCell align="center">Latitud</StyledTableCell>
                <StyledTableCell align="center">Longitud</StyledTableCell>
                {/* <StyledTableCell align="center" width="5%"></StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sismos?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    {row?.id ? row.id : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.lugar}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.fecha
                      ? moment(row.fecha).local().format("DD-MM-YYYY HH:mm:ss")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">{`${row.magnitud.value} ${row.magnitud.unit}`}</StyledTableCell>
                  <StyledTableCell align="center">{`${row.profundidad.value} ${row.profundidad.unit}`}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.ubicacion.latitude}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.ubicacion.longitude}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center" width="5%"></StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
export default Sismos;
