import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import { MenuItem } from '@mui/material';

const AuxiliarMenuBtn = ({ descargarAction, eliminarAction, publicarAction, dejarPublicarAction, disabled }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Tooltip title="Menu">
                <IconButton
                    disabled={disabled}
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                        bgcolor: open ? "rgba(0, 0, 0, 0.04)" : "transparent",
                    }}
                >
                    <MoreHorizIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        marginLeft: '-5px',
                        '& .MuiMenu-list': {
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    },
                }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {publicarAction && <MenuItem onClick={() => { publicarAction(); handleClose() }}>Publicar</MenuItem>}
                {dejarPublicarAction && <MenuItem onClick={() => { dejarPublicarAction(); handleClose() }}>Dejar de publicar</MenuItem>}
                <MenuItem onClick={() => { descargarAction(); handleClose() }}>Descargar</MenuItem>
                <MenuItem onClick={() => { eliminarAction(); handleClose() }}>Eliminar</MenuItem>
            </Menu>
        </>
    );
}
export default AuxiliarMenuBtn;