import axios from "../config/axiosInstance";
import {acelerometrosBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const acelerometrosService = {
  getGroups: async () => {
    return axios("/acelerometros/grupo", {
      baseURL: acelerometrosBaseUrl,
      method: "get"
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  createGroup: async (data) => {
    return axios("/acelerometros/grupo", {
      baseURL: acelerometrosBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGroup: async (id) => {
    return axios(`/acelerometros/grupo/${id}`, {
      baseURL: acelerometrosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGroup: async (data) => {
    try {
      const result = await axios(`/acelerometros/grupo/${data.id}`, {
        baseURL: acelerometrosBaseUrl,
        data,
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  deleteGroup: async (id) => {
    try {
      const result = await axios(`/acelerometros/grupo/${id}`, {
        baseURL: acelerometrosBaseUrl,
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  importarData: ({ id, ficheros }) => {
    return axios(`/acelerometros/${id}/upload`, {
      baseURL: acelerometrosBaseUrl,
      method: "post",
      data: ficheros,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    })
      .then((result) => {return result;})
      .catch((error) => errorHandle(error));
  },
  getAcelerometros: async () => {
    return axios("/acelerometros/acelerometros", {
      baseURL: acelerometrosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getAcelerometro: async (id) => {
    try {
      const result = await axios(`/acelerometros/acelerometros/${id}`, {
        baseURL: acelerometrosBaseUrl,
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  updateAcelerometro: (data) => {
    return axios(`/acelerometros/${data.id}`, {
      baseURL: acelerometrosBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  archivarAcelerometro: (data) => {
    return axios(`/acelerometros/${data.id}/archivar`, {
      baseURL: acelerometrosBaseUrl,
      data,
      method: "put",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deleteAcelerometro: async (data) => {
    try {
      const result = await axios(`/acelerometros/acelerometros/${data.id}`, {
        baseURL: acelerometrosBaseUrl,
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  createAcelerometro: (data) => {
    return axios(`/acelerometros`, {
      baseURL: acelerometrosBaseUrl,
      method: "post",
      data,
    })
      .then((result) => {return result;})
      .catch((error) => errorHandle(error));
  },
  publicarAcelerometro: async ({grupoId}) => {
    try {
      const result = await axios(`/acelerometos/grupos/${grupoId}/publicar`, {
        baseURL: acelerometrosBaseUrl,
        method: "post",
        data: {
          id: grupoId
        },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getCapas: async () => {
    return axios("/acelerometros/capas", {
      baseURL: acelerometrosBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    try {
      const result = await axios(`/acelerometros/capas/${id}`, {
        baseURL: acelerometrosBaseUrl,
        method: "get",
      });
      return result;
    } catch (error) {
      const err = errorHandle(error);
      return err;
    }
  },
  crearCapa: ({nombre, itemId, grupoId, creado }) => {
    return axios(`/acelerometros/capas`, {
      baseURL: acelerometrosBaseUrl,
      method: "post",
      data: {
        itemId,
        grupoAcelerometroId: grupoId,
        nombre,
        creado
      },
    })
        .then((result) => result)
        .catch((error) => {
          const err = errorHandle(error);
          return err;
        });
  },
  editarCapa: ({id, nombre, itemId }) => {
    return axios(`/acelerometros/capas/${id}`, {
      baseURL: acelerometrosBaseUrl,
      method: "put",
      data: {
        nombre,
        itemId
      },
    })
        .then((result) => result)
        .catch((error) => {
          const err = errorHandle(error);
          return err;
        });
  },
  eliminarCapa: ({ capaId }) => {
    return axios(`/acelerometros/capas/${capaId}`, {
      baseURL: acelerometrosBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => {
          const err = errorHandle(error);
          return err;
        });
  },
  eliminarCapaByGrupoId: (grupoId) => {
    return axios(`/acelerometros/capas/grupo/${grupoId}`, {
      baseURL: acelerometrosBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => {
          const err = errorHandle(error);
          return err;
        });
  },
  getReceptoresEmail: () => {
    return axios("/acelerometros/receptores-email", {
      baseURL: acelerometrosBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  getReceptoresEmailById: ({id}) => {
    return axios(`/acelerometros/receptores-email/${id}`, {
      baseURL: acelerometrosBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearReceptoresEmail: ({ grupoId, email }) => {
    return axios("/acelerometros/receptores-email", {
      baseURL: acelerometrosBaseUrl,
      method: "post",
      data: { grupoId, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateReceptoresEmail: ({ id, email }) => {
    return axios(`/acelerometros/receptores-email/${id}`, {
      baseURL: acelerometrosBaseUrl,
      method: "put",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  deleteReceptoresEmail: ({ id, email }) => {
    return axios(`/acelerometros/receptores-email/${id}`, {
      baseURL: acelerometrosBaseUrl,
      method: "delete",
      data: { id, email },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
