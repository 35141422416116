import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { LAYER_TYPES } from "../../common/constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { planesCargaService } from "../../services/PlanesCargaService";
import { ejesmuroService } from "../../services/EjesMuroService";

const AddLayerDialog = ({ open, handleClose, handleAddLayer }) => {
  const [name, setName] = useState("");
  const [layerType, setLayerType] = useState("");

  const [noCapa, setNoCapa] = useState("")
  const [ejeMuroId, setEjemuroId] = useState("")
  const [sector, setSector] = useState("")
  const [estadoPlanCargaId, setEstadoPlanCargaId] = useState("")
  const [estados, setEstados] = useState()
  const [ejesMuro, setEjesMuro] = useState()
  const [elevacionRequerida, setElevacionRequerida] = useState("")
  const [espesorObjetivo, setEspesorObjetivo] = useState("")
  const [toleranciaCumplimiento, setToleranciaCumplimiento] = useState("")
  const [fechaRecepcion, setFechaRecepcion] = useState("")
  const [fechaCreacion, setFechaCreacion] = useState("")
  const [puntoControlInicial, setPuntoControlInicial] = useState("")
  const [puntoControlFinal, setPuntoControlFinal] = useState("")

  useEffect(() => {
    planesCargaService.getEstadosPlandeCarga()
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setEstados(res.data.estados);
          } else {
            console.error(res);
          }
        })
    ejesmuroService.getCapas()
        .then(res => {
          if(res.status >= 200 && res.status < 300) {
            setEjesMuro(res.data.capasDto)
          } else {
            console.log(res)
          }
        })
  }, [LAYER_TYPES.PLANES_ESTERIL])

  const resetValues = () => {
    setName("");
    setLayerType("");
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeLayer = (event) => {
    setLayerType(event.target.value);
  };

  const handleChangeNoCapa = (event) => {
    setNoCapa(event.target.value);
  }

  const handleChangeEjeMuro = (event) => {
    setEjemuroId(event.target.value);
  }

  const handleChangeSector = (event) => {
    setSector(event.target.value);
  }

  const handleChangeEstadoId = (event) => {
    setEstadoPlanCargaId(event.target.value);
  }

  const handleChangeElevacionReq = (event) => {
    setElevacionRequerida(event.target.value);
  }

  const handleChangeEspesorObj = (event) => {
    setEspesorObjetivo(event.target.value);
  }

  const handleChangeToleranciaCump = (event) => {
    setToleranciaCumplimiento(event.target.value);
  }
  const handleChangeFechaRecepcion = (newFecha) => {
    setFechaRecepcion(newFecha);
  }
  const handleChangeFechaCreacion = (newFecha) => {
    setFechaCreacion(newFecha);
  }

  const handleChangePuntoInicial = (event) => {
    setPuntoControlInicial(event.target.value);
  }

  const handleChangePuntoFinal = (event) => {
    setPuntoControlFinal(event.target.value);
  }

  const onDataSubmit = () => {
    if (layerType !== LAYER_TYPES.PLANES_ESTERIL) {
      handleAddLayer({ name, layerType })
    } else {
      handleAddLayer({
        noCapa, ejeMuroId, sector, estadoPlanCargaId, elevacionRequerida, espesorObjetivo,
        toleranciaCumplimiento, fechaRecepcion, fechaCreacion, puntoControlInicial, puntoControlFinal, layerType
      })
    }
    close();
  }

  const close = () => {
    resetValues();
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        {(layerType !== LAYER_TYPES.PLANES_ESTERIL)
          ?
          <DialogTitle>Adicionar Grupo</DialogTitle>
          :
          <DialogTitle>Adicionar Plan de Carga</DialogTitle>
        }

        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <FormControl sx={{ marginTop: 1, minWidth: 120, width: "100%" }}>
            <InputLabel id="item-type-label">Tipo de Capa</InputLabel>
            <Select
              labelId="item-type-select-label"
              id="item-type-select"
              value={layerType}
              label="Tipo de Capa"
              onChange={handleChangeLayer}
            >
              <MenuItem value={LAYER_TYPES.ORTOFOTOS}>Ortofotos</MenuItem>
              <MenuItem value={LAYER_TYPES.DTM}> Modelos Digitales del Terreno (DTM) </MenuItem>
              <MenuItem value={LAYER_TYPES.ELEVACIONES}> Mapa de Elevaciones </MenuItem>
              <MenuItem value={LAYER_TYPES.CURVAS_NIVEL}> Curvas de Nivel </MenuItem>
              <MenuItem value={LAYER_TYPES.PIEZOMETROS}>Piezómetros</MenuItem>
              <MenuItem value={LAYER_TYPES.ACELEROMETROS}> Acelerómetros </MenuItem>
              <MenuItem value={LAYER_TYPES.INSAR}>INSAR</MenuItem>
              <MenuItem value={LAYER_TYPES.REVANCHA}>Revanchas</MenuItem>
              <MenuItem value={LAYER_TYPES.AUXILIARES}>Auxiliares</MenuItem>
              <MenuItem value={LAYER_TYPES.EJES_MURO}>Ejes de Muro</MenuItem>
              <MenuItem value={LAYER_TYPES.ESTACIONES_METEOROLOGICAS}>Estaciones Meteorológicas</MenuItem>
              <MenuItem value={LAYER_TYPES.GRIETAS}>Grietas</MenuItem>
              <MenuItem value={LAYER_TYPES.PUNTOS_DESCARGA}> Puntos de Descarga </MenuItem>
              <MenuItem value={LAYER_TYPES.PLANES_ESTERIL}> Plan de Carga Esteril </MenuItem>
              <MenuItem value={LAYER_TYPES.BATIMETRIAS}> Batimetria </MenuItem>
            </Select>
          </FormControl>

          {(layerType !== LAYER_TYPES.PLANES_ESTERIL) ?
            <TextField
              margin="dense"
              id="name"
              label="Nombre"
              fullWidth
              value={name}
              onChange={handleChangeName}
            /> :

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                margin="dense"
                id="noCapa"
                label="No. Capa"
                fullWidth
                value={noCapa}
                onChange={handleChangeNoCapa}
              />

              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={5}>
                  <TextField
                    margin="dense"
                    id="sector"
                    label="Sector"
                    fullWidth
                    value={sector}
                    onChange={handleChangeSector}
                  />
                </Grid>

                  <Grid xs={5}>
                    <InputLabel id="item-type-label">Eje de Muro</InputLabel>
                    <Select
                        labelId="item-type-select-label"
                        id="item-type-select"
                        fullWidth
                        value={ejeMuroId}
                        label="Eje de Muro"
                        onChange={handleChangeEjeMuro}
                    >
                      {ejesMuro?.map((eje, index) => (
                          <MenuItem key={index} value={eje?.ejeMuroId ? eje?.ejeMuroId : 0}>
                            {eje?.nombre ? eje.nombre : "-"}
                          </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={5}>
                  <InputLabel id="item-type-label">Estado Plan Carga</InputLabel>
                  <Select
                    labelId="item-type-select-label"
                    id="item-type-select"
                    fullWidth
                    value={estadoPlanCargaId}
                    label="Estado Plan de Carga"
                    onChange={handleChangeEstadoId}
                  >
                    {estados?.map((estado, index) => (
                      <MenuItem key={index} value={estado?.id ? estado?.id : 0}>
                        {estado?.name ? estado.name : "-"}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid xs={5}>
                  <TextField
                    margin="dense"
                    id="elevacionReq"
                    label="Elevación requerida"
                    fullWidth
                    value={elevacionRequerida}
                    onChange={handleChangeElevacionReq}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={5}>
                  <TextField
                    margin="dense"
                    id="espesorObj"
                    label="Espesor Objetivo"
                    fullWidth
                    value={espesorObjetivo}
                    onChange={handleChangeEspesorObj}
                  />
                </Grid>

                <Grid xs={5}>
                  <TextField
                    margin="dense"
                    id="toleranciaCump"
                    label="Tolerancia de Cumplimiento"
                    fullWidth
                    value={toleranciaCumplimiento}
                    onChange={handleChangeToleranciaCump}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={5}>
                  <TextField
                    margin="dense"
                    id="puntoControlInicial"
                    label="Punto de Control Inicial"
                    fullWidth
                    value={puntoControlInicial}
                    onChange={handleChangePuntoInicial}
                  />
                </Grid>

                <Grid xs={5}>
                  <TextField
                    margin="dense"
                    id="puntoControlFinal"
                    label="Punto de Control Final"
                    fullWidth
                    value={puntoControlFinal}
                    onChange={handleChangePuntoFinal}
                  />
                </Grid>
              </Grid>

              <div>&nbsp;</div>

              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid xs={5}>
                  <DesktopDatePicker
                    label="Fecha Recepción"
                    inputFormat="MM/dd/yyyy"
                    value={fechaRecepcion}
                    onChange={handleChangeFechaRecepcion}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>

                <Grid xs={5} >
                  <DesktopDatePicker
                    label="Fecha Creación"
                    inputFormat="MM/dd/yyyy"
                    value={fechaCreacion}
                    onChange={handleChangeFechaCreacion}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          }

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button onClick={onDataSubmit}>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddLayerDialog;
