import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import moment from 'moment';
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import AuxiliaresBlackIcon from '../../../../assets/slopeBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddAuxiliarDialog from '../../../Dialogs/Auxiliares/AddAuxiliarDialog'
import EditGrupoAuxiliarDialog from '../../../Dialogs/Auxiliares/EditAuxiliarDialog';
import { auxiliaresService } from '../../../../services/AuxiliaresService';
import AuxiliarMenuBtn from '../../../Dialogs/Auxiliares/AuxiliarMenuBtn';
import GrupoAuxiliarMenuBtn from '../../../Dialogs/Auxiliares/GrupoAuxiliarMenuBtn';
import './Auxiliares.css'
import NotificacionMensaje from '../../../NotificacionMensaje';
import PublicarAuxiliarDialog from '../../../Dialogs/Auxiliares/PublicarAuxiliarDialog';
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Auxiliares = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grupoAuxiliares, setGrupoAuxiliares] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const [openAddAuxiliarDialog, setOpenAddAuxiliarDialog] = useState(false);
  const [openEditAuxiliarDialog, setOpenEditAuxiliarDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

  const [currentAuxiliar, setCurrentAuxiliar] = useState(null);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando', mensaje: "La operación fue realizada con éxito" });
  const [openPublicar, setOpenPublicar] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    cargarGrupoAuxiliares();
  }, [id]);

  const cargarGrupoAuxiliares = () => {
    setLoadingGroup(true);
    auxiliaresService.getGrupo(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupoAuxiliares(res.data.grupo);
        } else {
          setImportandoDatos({ ...importandoDatos, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleClickEditGAuxiliar = () => {
    setOpenEditAuxiliarDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseEditAuxiliarDialog = () => {
    setOpenEditAuxiliarDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditGrupoAuxiliares = (nombre) => {
    handleCloseEditAuxiliarDialog(false)
    auxiliaresService.actualizarGrupo({ id, nombre })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargarGrupoAuxiliares();
        } else {
          setImportandoDatos({ ...importandoDatos, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
          console.log(res)
        }
      })
  }

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentAuxiliar) {
      auxiliaresService.eliminarAuxiliar({
        auxiliarId: currentAuxiliar.id,
        grupoId: grupoAuxiliares.id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setCurrentAuxiliar(null)
            cargarGrupoAuxiliares();
          } else {
            setImportandoDatos({ ...importandoDatos, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
            console.log(res)
          }
        })
    } else {
      auxiliaresService.deleteGroup(grupoAuxiliares.id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU})
            navigate('/');
          } else {
            setImportandoDatos({ ...importandoDatos, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
            console.log(res)
          }
        })
    }
  };

  //menu-option-row
  const handleClickAddAuxiliar = () => {
    setOpenAddAuxiliarDialog(true);
  }

  const handleCloseAddAuxiliarDialog = () => {
    setOpenAddAuxiliarDialog(false);
    setCurrentAuxiliar(null);
  };

  const handleAddAuxiliar = ({ fecha, ficheros, nombreCapa }) => {
    handleCloseAddAuxiliarDialog(false)
    setImportandoDatos({ importando: true, status: "Importando" })
    const formData = new FormData()
    formData.append("UploadFile", ficheros[0])
    formData.append("NombreCapa", nombreCapa)
    formData.append("FechaCreado", new Date(fecha).toISOString())
    auxiliaresService.uploadAuxiliares({
      idGrupo: grupoAuxiliares.id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        setCurrentAuxiliar(null);
        cargarGrupoAuxiliares();
        setImportandoDatos({ importando: false, status: "Hecho" })
      } else {
        console.log(res)
        setImportandoDatos({ importando: false, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
      }
    });
  }

  const handleDescargarAuxiliar = (ort) => {
    auxiliaresService.descargarAuxiliar({
      grupoId: grupoAuxiliares.id,
      auxiliarId: ort.id
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          fileDownload(res.data, `(${moment(ort.creado).format("DD-MM-YYYY")})auxiliar.dxf`)
        } else {
          setImportandoDatos({ ...importandoDatos, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
          console.log(res)
        }
      })
  }

  const handleDejarPublicar = () => {
    alert("Esta acción no esta disponible")
  }

  const handleClosePublicar = () => {
    setOpenPublicar(false)
  }

  const handleClickPublicar = (ort) => {
    setCurrentAuxiliar(ort)
    setOpenPublicar(true)
  }

  const handlePublicar = ({ url, nombre, fecha }) => {
    handleClosePublicar()
    auxiliaresService.publicarCapaAuxiliar({ auxiliarId: currentAuxiliar.id, nombre, url, creado: fecha })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          let auxiliares = grupoAuxiliares?.auxiliares.map((ort) => {
            if (ort.id === currentAuxiliar.id) {
              return {
                ...ort,
                publicado: true
              }
            } else {
              return ort;
            }
          })
          setGrupoAuxiliares({ ...grupoAuxiliares, auxiliares })
          setCurrentAuxiliar()
        } else {
          setImportandoDatos({ ...importandoDatos, status: "Fallido", mensaje: `Algo a salido mal: ${res.message}` })
          console.log(res)
        }
      })
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setImportandoDatos({ ...importandoDatos, status: 'Importando' })
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupoAuxiliares.auxiliares.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Publicado</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupoAuxiliares.auxiliares.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupoAuxiliares?.auxiliares
            ).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell align="center">{row.fechaCreado ? moment(row.fechaCreado).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.publicado ?
                    <PublicadoIcon fontSize="small" />
                    :
                    <PublicadoIcon fontSize="small" color="disabled" />
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  <AuxiliarMenuBtn
                    disabled={importandoDatos.importando}
                    dejarPublicarAction={row.publicado && (() => handleDejarPublicar(row))}
                    publicarAction={!row.publicado && (() => handleClickPublicar(row))}
                    descargarAction={() => handleDescargarAuxiliar(row)}
                    eliminarAction={() => {
                      setCurrentAuxiliar(row);
                      handleClickDeleteItem();
                    }} />
                </StyledTableCell>
              </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: grupoAuxiliares.auxiliares.length }]}
                  colSpan={12}
                  count={grupoAuxiliares.auxiliares.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen auxiliares</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {loadingGroup ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={AuxiliaresBlackIcon} alt="" width={18} height={18} />
              <h2 className="name-area">{grupoAuxiliares ? grupoAuxiliares.nombre : 'Grupo de Auxiliares inexistente'}</h2>
            </div>
            {grupoAuxiliares
              ?
                <div className="actionsButtons">
                  <Button
                      onClick={cargarGrupoAuxiliares}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoAuxiliarMenuBtn
                    editarAction={handleClickEditGAuxiliar}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
              : null}
          </div>
          {grupoAuxiliares ?
            <>
              <Button
                disabled={importandoDatos.importando}
                variant="contained"
                className="btn-add"
                onClick={handleClickAddAuxiliar}
              >{importandoDatos.importando && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}Adicionar
              </Button>
              <div className="container-table">
                {renderTabla()}
              </div>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditAuxiliarDialog
        ? <EditGrupoAuxiliarDialog
          open={openEditAuxiliarDialog}
          handleClose={handleCloseEditAuxiliarDialog}
          handleEditarGrupo={handleEditGrupoAuxiliares}
          grupoAuxiliares={grupoAuxiliares}
        /> : null}
      {openAddAuxiliarDialog ?
        <AddAuxiliarDialog
          open={openAddAuxiliarDialog}
          handleClose={handleCloseAddAuxiliarDialog}
          handleSubmit={handleAddAuxiliar}
        /> : null}
      {openPublicar && <PublicarAuxiliarDialog
        open={openPublicar}
        handleClose={handleClosePublicar}
        handleSubmit={handlePublicar}
      />}
      <NotificacionMensaje
        open={importandoDatos.status === 'Fallido'}
        onClose={handleCloseAlert}
        message={importandoDatos.mensaje || "Ha fallado la subida de los archivos"}
      />
    </div>
  );
}
export default Auxiliares;


