import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import './AddSysRefDialog.css';


const AddSysRefDialog = ({ open, handleClose, handleAddSysRef, sysReference = null }) => {

  const [sysRef, setSysRef] = useState(sysReference ? sysReference : {
    name: '',
    coefNN_FROM: '',
    coefNE_FROM: '',
    coefTN_FROM: '',
    coefEN_FROM: '',
    coefEE_FROM: '',
    coefTE_FROM: '',
    coefNN_TO: '',
    coefNE_TO: '',
    coefTN_TO: '',
    coefEN_TO: '',
    coefEE_TO: '',
    coefTE_TO: '',
  });

  const resetValues = () => {
    setSysRef({
      name: '',
      coefNN_FROM: '',
      coefNE_FROM: '',
      coefTN_FROM: '',
      coefEN_FROM: '',
      coefEE_FROM: '',
      coefTE_FROM: '',
      coefNN_TO: '',
      coefNE_TO: '',
      coefTN_TO: '',
      coefEN_TO: '',
      coefEE_TO: '',
      coefTE_TO: '',
    })
  }

  const handleChangeSysRef = (event) => {
    const { id, value } = event.target;
    setSysRef({
      ...sysRef,
      [id]: value
    })
  }

  const close = () => {
    resetValues();
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>{`${sysReference ? 'Editar' : 'Adicionar'} Sistema de Referencia`}</DialogTitle>
        <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>


          <div className="coeficient-container">
            <TextField
              style={{
                marginLeft: "20%",
                marginRight: "20%",
                width: "60%",
              }}
              margin="dense"
              id="name"
              label="Nombre"
              fullWidth
              value={sysRef.name}
              onChange={handleChangeSysRef}
            />
            {/* <FormControl component="fieldset" className="coeficient-form"> */}
            <InputLabel id="coeficient-group-label">Desde WGS84</InputLabel>
            <div className="coeficient-group">
              <TextField
                margin="dense"
                id="coefNN_FROM"
                label="Coeficiente NN"
                fullWidth
                value={sysRef.coefNN_FROM}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefNE_FROM"
                label="Coeficiente NE"
                fullWidth
                value={sysRef.coefNE_FROM}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefTN_FROM"
                label="Coeficiente TN"
                fullWidth
                value={sysRef.coefTN_FROM}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefEN_FROM"
                label="Coeficiente EN"
                fullWidth
                value={sysRef.coefEN_FROM}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefEE_FROM"
                label="Coeficiente EE"
                fullWidth
                value={sysRef.coefEE_FROM}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefTE_FROM"
                label="Coeficiente TE"
                fullWidth
                value={sysRef.coefTE_FROM}
                onChange={handleChangeSysRef}
              />
              {/* </FormControl> */}
            </div>

            <InputLabel id="coeficient-group-label">Hacia WGS84</InputLabel>
            <div className="coeficient-group">
              <TextField
                margin="dense"
                id="coefNN_TO"
                label="Coeficiente NN"
                fullWidth
                value={sysRef.coefNN_TO}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefNE_TO"
                label="Coeficiente NE"
                fullWidth
                value={sysRef.coefNE_TO}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefTN_TO"
                label="Coeficiente TN"
                fullWidth
                value={sysRef.coefTN_TO}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefEN_TO"
                label="Coeficiente EN"
                fullWidth
                value={sysRef.coefEN_TO}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefEE_TO"
                label="Coeficiente EE"
                fullWidth
                value={sysRef.coefEE_TO}
                onChange={handleChangeSysRef}
              />
              <TextField
                margin="dense"
                id="coefTE_TO"
                label="Coeficiente TE"
                fullWidth
                value={sysRef.coefTE_TO}
                onChange={handleChangeSysRef}
              />
              {/* </FormControl> */}
            </div>


          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            close();
          }}>
            Cancelar
          </Button>
          <Button onClick={() => {
            handleAddSysRef(sysRef);
            close();
          }}>
            {`${sysReference ? 'Aceptar' : 'Adicionar'}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AddSysRefDialog;