import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import "./AddPiezometroDialog.css";
import SeleccionarCoordenadas from "../SeleccionarCoordenadas";
import { getCoordenadas } from "../../../utils/ll2utm";
import {MenuItem, Select} from "@mui/material";

const AddPiezometroDialog = ({
  open,
  handleClose,
  handleSubmit,
  currentPiezometro = null,
  tiposPiezometros,
  updatingThresholds,
}) => {
  const [piezometro, setPiezometro] = useState({
    identifier: currentPiezometro?.etiqueta || "",
    location_East: currentPiezometro?.ubicacion?.x || "",
    location_North: currentPiezometro?.ubicacion?.y || "",
    cota: currentPiezometro?.cota || "",
    warning: currentPiezometro?.umbralPiezometro?.advertencia || "",
    alert: currentPiezometro?.umbralPiezometro?.alerta || "",
    alarm: currentPiezometro?.umbralPiezometro?.alarma || "",
    ascent: currentPiezometro?.umbralPiezometro?.velocidadAscenso || "",
    ascenHisto: currentPiezometro?.umbralPiezometro.velocidadAscensoHistorico || "",
    tipo: currentPiezometro?.tipo
  });
  const [tipoCoordenada, setTipoCoordenada] = useState("geograficas");
  const [tiposPiezometro, setTiposPiezometro] = useState(tiposPiezometros)

  const resetValues = () => {
    setPiezometro({
      identifier: "",
      location_East: "",
      location_North: "",
      cota: "",
      warning: "",
      alert: "",
      alarm: "",
      ascent: "",
      ascenHisto: "",
      tipo: ""
    });
  };

  const handleChangePiezometro = (event) => {
    const { id, value } = event.target;
    setPiezometro({
      ...piezometro,
      [id]: value,
    });
  };

  const handleChangeLocation_East = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      location_East: newData
    });
  }

  const handleChangeLocation_North = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      location_North: newData
    });
  }

  const handleChangeCota = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      cota: newData
    });
  }

  const handleChangeWarning = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      warning: newData
    });
  }

  const handleChangeAlert = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      alert: newData
    });
  }

  const handleChangeAlarm = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      alarm: newData
    });
  }

  const handleChangeAscent = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      ascent: newData
    });
  }

  const handleChangeAscentHist = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setPiezometro({
      ...piezometro,
      ascenHisto: newData
    });
  }

  const close = () => {
    resetValues();
    handleClose();
  };

  const showBasicData = () => {
    return currentPiezometro == null || !updatingThresholds;
  };

  const showTresholdsData = () => {
    return currentPiezometro == null || updatingThresholds;
  };

  const handleChangeCoordenadas = (value) => {
    setTipoCoordenada(value);
  };

  const handleChangeTipo = (event) => {
    setPiezometro({
      ...piezometro,
      tipo: event.target.value
    })
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>
          {`${currentPiezometro ? "Editar" : "Adicionar"} Piezómetro`}
        </DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <div className="piezometro-container">
            {showBasicData() ? (
              <>
                <TextField
                  margin="dense"
                  id="identifier"
                  label="Identificación de piezómetro"
                  fullWidth
                  value={piezometro.identifier}
                  onChange={handleChangePiezometro}
                />

                <InputLabel id="tipoLabel">Tipo de Piezometro</InputLabel>
                <Select
                    labelId="tipoLabel"
                    id="tipo"
                    fullWidth
                    value={piezometro.tipo}
                    label="Tipo de Piezometro"
                    onChange={handleChangeTipo}
                >
                  {tiposPiezometro?.map((tipo, index) => (
                      <MenuItem key={index} value={tipo?.name ? tipo?.name : 0}>
                        {tipo?.name ? tipo.name : "-"}
                      </MenuItem>
                  ))}
                </Select>

                <InputLabel
                  id="geo-ubication-label"
                  style={{ marginTop: "0.8rem" }}
                >
                  Ubicación geográfica
                </InputLabel>
                <div className="geo-ubication-group">
                  <SeleccionarCoordenadas
                    value={tipoCoordenada}
                    onChange={handleChangeCoordenadas}
                  />
                  <div className="coordinates">
                    <TextField
                      margin="dense"
                      id="location_East"
                      type="number"
                      label={
                        tipoCoordenada === "geograficas"
                          ? "Longitud"
                          : "Este [m]"
                      }
                      fullWidth
                      value={piezometro.location_East}
                      onChange={handleChangeLocation_East}
                    />
                    <TextField
                      margin="dense"
                      id="location_North"
                      type="number"
                      label={
                        tipoCoordenada === "geograficas"
                          ? "Latitud"
                          : "Norte [m]"
                      }
                      fullWidth
                      value={piezometro.location_North}
                      onChange={handleChangeLocation_North}
                    />
                  </div>
                </div>
                <TextField
                  margin="dense"
                  id="cota"
                  label="Cota de instalación"
                  type="number"
                  fullWidth
                  value={piezometro.cota}
                  onChange={handleChangeCota}
                />
              </>
            ) : null}
            {showTresholdsData() ? (
              <>
                <InputLabel
                  id="umbral-configuration-label"
                  style={{ marginTop: "0.8rem" }}
                >
                  Configurar umbrales
                </InputLabel>
                <div className="umbral-configuration-group">
                  <TextField
                    margin="dense"
                    id="warning"
                    type="number"
                    label="Advertencia [m]"
                    fullWidth
                    value={piezometro.warning}
                    onChange={handleChangeWarning}
                  />
                  <TextField
                    margin="dense"
                    id="alert"
                    label="Alerta [m]"
                    type="number"
                    fullWidth
                    value={piezometro.alert}
                    onChange={handleChangeAlert}
                  />
                  <TextField
                    margin="dense"
                    id="alarm"
                    label="Alarma [m]"
                    type="number"
                    fullWidth
                    value={piezometro.alarm}
                    onChange={handleChangeAlarm}
                  />
                  <TextField
                    margin="dense"
                    id="ascent"
                    label="Ascenso [cm/semana]"
                    type="number"
                    fullWidth
                    value={piezometro.ascent}
                    onChange={handleChangeAscent}
                  />
                  <TextField
                      margin="dense"
                      id="ascenHisto"
                      label="Ascenso Histórico"
                      type="number"
                      fullWidth
                      value={piezometro.ascenHisto}
                      onChange={handleChangeAscentHist}
                  />
                </div>
              </>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const { location_East, location_North } = getCoordenadas(
                tipoCoordenada,
                piezometro.location_East,
                piezometro.location_North
              );
              handleSubmit({
                identifier: piezometro.identifier,
                location_East: location_East,
                location_North: location_North,
                cota: piezometro.cota,
                warning: piezometro.warning,
                alert: piezometro.alert,
                alarm: piezometro.alarm,
                ascent: piezometro.ascent,
                ascenHisto: piezometro.ascenHisto,
                tipo: piezometro.tipo
              });
              close();
            }}
          >
            {`${currentPiezometro ? "Aceptar" : "Adicionar"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddPiezometroDialog;
