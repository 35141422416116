import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const EditInsarDialog = ({ open, handleClose, handleEditSerieInsar, serieInsar }) => {

  const [etiqueta, setEtiqueta] = useState(serieInsar?.etiqueta);

  const resetValues = () => {
    setEtiqueta("");
  }

  const handleChangeName = (event) => {
    setEtiqueta(event.target.value);
  }

  const close = () => {
    resetValues();
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Editar Insar</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>

          <TextField
            margin="dense"
            id="etiqueta"
            label="Nombre"
            fullWidth
            value={etiqueta}
            onChange={handleChangeName}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            close();
          }}>
            Cancelar
          </Button>
          <Button onClick={() => handleEditSerieInsar({
            ...serieInsar,
            etiqueta: etiqueta
          })}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditInsarDialog;