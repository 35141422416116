import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const PublicarCapaDialog = ({ open, handleClose, handleSubmit }) => {

  const [name, setName] = useState('');
  const [urlFile, setUrlFile] = useState('');
  const [fecha, setFecha] = React.useState(new Date());

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  const handleChangeUrlfile = (event) => {
    setUrlFile(event.target.value);
  }

  const handleChangeFecha = (newFecha) => {
    setFecha(newFecha);
  };

  const close = () => {
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Publicar capa</DialogTitle>
        <DialogContent style={{ paddingTop: '1rem' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Fecha"
                inputFormat="MM/dd/yyyy"
                value={fecha}
                onChange={handleChangeFecha}
                renderInput={(params) => <TextField {...params} />}
              />
              <TextField
                margin="dense"
                id="name"
                label="Nombre"
                fullWidth
                value={name}
                onChange={handleChangeName}
              />
              <TextField
                margin="dense"
                id="url"
                label="Dirección del archivo"
                fullWidth
                value={urlFile}
                onChange={handleChangeUrlfile}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            Cancelar
          </Button>
          <Button disabled={!name || !urlFile}
            onClick={() => {
              handleSubmit({ url: urlFile, nombre: name, fecha: fecha.toISOString() })
            }}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default PublicarCapaDialog;