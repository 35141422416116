import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import datos from "../../../Pages/Layers/Revancha/Datos";
import {Input} from "@mui/material";

export const MostrarDatosDuperficieDialog = ({datosSuperficieActual}) => {
    const [datosSuperficie, setDatosSuperficie] = useState((datosSuperficieActual) ? datosSuperficieActual : null);
    //console.log("SUERFICIE", datosSuperficie);
    return (
        <Stack spacing={3}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5} direction="row">
                        <b>Laguna [m²]:&nbsp;&nbsp;</b>
                        <u>{(datosSuperficie.superficieEspejoAgua) ? datosSuperficie.superficieEspejoAgua : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Pelo de Agua [m]: </b>
                        <u>{(datosSuperficie.superficiePeloAgua) ? datosSuperficie.superficiePeloAgua : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Cuerpo de Agua [m²]: </b>
                        <u>{(datosSuperficie.superficieCuerpoAgua) ? datosSuperficie.superficieCuerpoAgua : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Perimetro [m]: </b>
                        <u>{(datosSuperficie.perimetro) ? datosSuperficie.perimetro : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Zona Baja Prof (0m-0.8m) [m²]: </b>
                        <u>{(datosSuperficie.zonaBajaProfundidad08) ? datosSuperficie.zonaBajaProfundidad08 : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Zona Baja Prof (0.8m-2.1m) [m²]: </b>
                        <u>{(datosSuperficie.zonaBajaProfundidad291) ? datosSuperficie.zonaBajaProfundidad291 : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Pelo de Agua [m]: </b>
                        <u>{(datosSuperficie.cotaPeloAgua) ? datosSuperficie.cotaPeloAgua : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Altura Max. Profundidad Agua [m]: </b>
                        <u>{(datosSuperficie.cotaProfundidadMaxima) ? datosSuperficie.cotaProfundidadMaxima : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Max. Profundidad Superficie [m]: </b>
                        <u>{(datosSuperficie.profundidadMaxima) ? datosSuperficie.profundidadMaxima : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Volumen Agua [m³]: </b>
                        <u>{(datosSuperficie.volumenPeloAgua) ? datosSuperficie.volumenPeloAgua : "-" }</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Volumen Agua Menos 1m [m³]: </b>
                        <u>{(datosSuperficie.volumenPeloAgua1Metro) ? datosSuperficie.volumenPeloAgua1Metro : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Volumen Agua Menos 2m [m³]: </b>
                        <u>{(datosSuperficie.volumenPeloAgua2Metro) ? datosSuperficie.volumenPeloAgua2Metro : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>ArcGIS ItemId Superficie Agua: </b>
                        <u>{(datosSuperficie.itemIdAgua) ? datosSuperficie.itemIdAgua : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>ArcGIS ItemId Superficie Cuerpo Agua: </b>
                        <u>{(datosSuperficie.itemIdCuerposAgua) ? datosSuperficie.itemIdCuerposAgua : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Url Blob Storage Superficie Agua: </b>
                        <u>{(datosSuperficie.urlStorageAgua) ? datosSuperficie.urlStorageAgua : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Url Blob Storage Superficie Cuerpo Agua: </b>
                        <u>{(datosSuperficie.urlStorageCuerposAgua) ? datosSuperficie.urlStorageCuerposAgua : "-"}</u>
                    </Grid>
                </Grid>
            </div>
        </Stack>
    )
}
