import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import HydrogeologyBlack from "../../../../assets/hydrogeologyBlack.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";
import ArchiveIcon from '@mui/icons-material/Archive';

import AddPiezometroDialog from "../../../Dialogs/Piezometros/AddPiezometroDialog";
import EditPiezomDialog from "../../../Dialogs/Piezometros/EditPiezomDialog";
import ImportDataPiezometerDialog from "../../../Dialogs/Piezometros/ImportDataPiezometerDialog";
import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import "./Piezometros.css";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { piezometersService } from "../../../../services/PiezometersService";
import moment from "moment";
import GrupoMenuBtn from "../../../Dialogs/Piezometros/GrupoMenuBtn";
import PublicarDialog from "../../../Dialogs/Piezometros/PublicarDialog";
import { toast } from "react-toastify";
import AddTipoPiezometroDialog from "../../../Dialogs/Piezometros/AddTipoPiezometroDialog";
import ImportExcelPiezometroDialog from "../../../Dialogs/Piezometros/ImportExcelPiezometroDialog";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReceptorEmailForm from "../../../Dialogs/ReceptorEmailForm";
import PublicadoIcon from "@mui/icons-material/FlagOutlined";
import {ACTIONS, useDrawerMenu} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";
import DeleteCapaDialog from "../../../Dialogs/DeleteCapaDialog";

const style = {
  paddingTop: -2
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Piezometros = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [piezometerGroup, setPiezometerGroup] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [drawerMenuContex, setDrawerMenuContext] = useDrawerMenu();

  const [rowEl, setRowEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRowEl, setOpenRowEl] = useState(null);

  const [openAddPiezomDialog, setOpenAddPiezomDialog] = useState(false);
  const [openEditPiezomDialog, setOpenEditPiezomDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openImportarDatosDialog, setOpenImportarDatosDialog] = useState(false);
  const [openImportarDatosExcelDialog, setOpenImportarDatosExcelDialog] = useState(false);

  const [currentPiezometer, setCurrentPiezometer] = useState(null);
  const [updatingThresholds, setUpdatingThresholds] = useState(false);
  const [openPublicar, setOpenPublicar] = useState(false);
  const [openDejarPublicar, setOpenDejarPublicar] = useState(false);

  const [openCrearTipoPiezometro, setOpenCrearTipoPiezometro] = useState(false);
  const [tiposPiezometros, setTiposPiezometros] = useState([]);
  const [publicado, setPublicado] = useState(false);

  const [receptoresEmail, setReceptoresEmail] = useState([]);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [openEditEmailDialog, setOpenEditEmailDialog] = useState(false);
  const [openDeleteItemEmailDialog, setOpenDeleteItemEmailDialog] = useState(false);
  const [openDeleteTipoDialog, setOpenDeleteTipoDialog] = useState(false);
  const [currentReceptorEmail, setCurrentReceptorEmail] = useState(null);
  const [currentTipoPiezometro, setCurrentTipoPiezometro] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const toastId = React.useRef(null);
  const notify = (mensaje) =>
      (toastId.current = toast(`${mensaje}`, { autoClose: false }));
  const update = (mensaje, tipo) => {
    toast.update(toastId.current, {
      render: `${mensaje}`,
      type: tipo ? tipo : toast.TYPE.INFO,
      autoClose: 5000,
    });
  };

  useEffect(() => {
    let mounted = true;
    mounted && loadPiezometerGroup();
    loadReceptoresEmail();
    return () => (mounted = false);
  }, [id]);

  const loadPiezometerGroup = () => {
    setLoadingGroup(true);
    piezometersService.getGroup(id).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        res.data.grupoPiezometro.piezometros.sort((a, b) => a.id - b.id);
        setPiezometerGroup(res.data.grupoPiezometro);
        setPublicado(res.data.grupoPiezometro.published);
        setLoadingGroup(false);
      } else {
        console.error(res);
      }
    });
    piezometersService.getTiposPiezometro().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setTiposPiezometros(res.data);
      } else {
        console.error(res);
      }
    });
  };

  const loadReceptoresEmail = () => {
    piezometersService.getReceptoresEmail().then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setReceptoresEmail(res.data.receptores);
      } else {
        console.error(res);
      }
    });
  };

  const handleClickEditPiezometro = () => {
    setOpenEditPiezomDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleClickDeleteEmailItem = () => {
    handleClose();
    handleCloseRowOption();
    setOpenDeleteItemEmailDialog(true);
  };

  const handleClickDeleteTipo = () => {
    handleClose();
    handleCloseRowOption();
    setOpenDeleteTipoDialog(true);
  };

  const handleCloseEditPiezomDialog = () => {
    setOpenEditPiezomDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleCloseDeleteEmailItemDialog = () => {
    setOpenDeleteItemEmailDialog(false);
  };

  const handleCloseDeleteTipoDialog = () => {
    setOpenDeleteTipoDialog(false);
  };

  const handleCloseImportarDatosDialog = () => {
    setOpenImportarDatosDialog(false);
  };

  const handleCloseImportarDatosExcelDialog = () => {
    setOpenImportarDatosExcelDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickImportarDatos = () => {
    setOpenImportarDatosDialog(true);
  };

  const handleClickImportarDatosExcel = () => {
    setOpenImportarDatosExcelDialog(true);
  };

  const handleImportarDatos = async (ficheros) => {
    setOpenImportarDatosDialog(false);
    const bodyFormData = new FormData();
    bodyFormData.append("file", new Blob(ficheros));
    // bodyFormData.append('data', Object.values(ficheros));

    notify("Importando Datos de Piezometros");
    piezometersService.importarData({
      id: piezometerGroup.id,
      formData: bodyFormData,
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        update("Los datos se importaron corretamente 👌", toast.TYPE.SUCCESS);
        loadPiezometerGroup();
      } else {
        console.log(res)
        update(`Ocurrio un error al importar los datos 🤯 ${res.message}`, toast.TYPE.ERROR );
        loadPiezometerGroup();
      }
    });
  };

  const handleImportarDatosExcel = async ({endColumns, ficheros}) => {
    setOpenImportarDatosExcelDialog(false);
    const formData = new FormData();
    formData.append("UploadFile", ficheros)
    formData.append("EndColumns", endColumns)
    notify("Importando Datos...")

    piezometersService.importarDataExcel({
      grupoId: piezometerGroup.id,
      formData: formData,
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        update("Los datos se importaron corretamente 👌", toast.TYPE.SUCCESS);
        loadPiezometerGroup();
      } else {
        console.log(res)
        update(`Ocurrio un error al importar los datos 🤯 ${res.message}`, toast.TYPE.ERROR );
        loadPiezometerGroup();
      }
    });
  };

  const handleEditPiezomGroup = (piezometerGroupEdited) => {
    handleCloseEditPiezomDialog();
    setLoadingGroup(true);
    notify("Editando datos del Grupo...")
    piezometersService
      .updateGroup(piezometerGroupEdited)
      .then((res) => {
        loadPiezometerGroup();
        update(
            "Se actulalizaron los datos correctamente 👌",
            toast.TYPE.SUCCESS
        );
      })
      .catch((err) => {
        console.error(err);
        update(
            "Ocurrio un error al actualizar los datos. 🤯 " + err.message,
            toast.TYPE.ERROR
        );
      });
  };

  const handleClickArchivarItem = (row) => {
    setCurrentPiezometer(row);
    handleCloseRowOption();
    notify("Modificando estado del Piezómetro...")
    piezometersService
        .archivarPiezometro({
          piezometroId: row.id,
          archivar: !row.archivado
        })
        .then((res) => {
          loadPiezometerGroup();
          update(
              "El estado del Piezómetro se actualizó correctamente 👌",
              toast.TYPE.SUCCESS
          );
        })
        .catch((err) => {
          console.error(err);
          update(
              "Ocurrio un error al actualizar el estado del Piezómetro. 🤯 " + err.message,
              toast.TYPE.ERROR
          );
        });
  };

  const handleClickAddEmail = () => {
    handleCloseRowOption();
    setOpenAddEmailDialog(true);
  };
  const handleCloseAddEmailDialog = () => {
    setOpenAddEmailDialog(false);
    setCurrentReceptorEmail(null);
  };

  const handleAddReceptorEmail = ({email}) => {
    handleCloseAddEmailDialog();
    setLoadingGroup(true);
    piezometersService
        .crearReceptoresEmail({
          grupoId: parseInt(piezometerGroup.id),
          email
        })
        .then((res) => {
          update(
              "Se añadío el Email correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadReceptoresEmail();
          setLoadingGroup(false);
        })
        .catch((err) => {
          update(
              "Ocurrio un error al crear el receptor email. 🤯 ${res.message}",
              toast.TYPE.ERROR
          );
          console.error(err);
        });
  };

  const handleClickEditEmail = () => {
    handleCloseRowOption();
    setOpenEditEmailDialog(true);
  };
  const handleCloseEditEmailDialog = () => {
    setOpenEditEmailDialog(false);
    setCurrentReceptorEmail(null);
  };

  const handleEditReceptorEmail = ({id, email}) => {
    handleCloseEditEmailDialog();
    setLoadingGroup(true);
    piezometersService
        .updateReceptoresEmail({id, email})
        .then((res) => {
          update(
              "Se actualizó el Email correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadReceptoresEmail();
          setLoadingGroup(false);
        })
        .catch((err) => {
          update(
              "Ocurrio un error al editar el receptor email. 🤯 ${res.message}",
              toast.TYPE.ERROR
          );
          console.error(err);
        });
  };

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentPiezometer) {
      piezometersService
        .deletePiezometer({
          piezometroId: currentPiezometer.id,
          grupoPiezometroId: piezometerGroup.id,
        })
        .then((res) => {
          loadPiezometerGroup();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      piezometersService
        .deleteGroup(id)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
      setDrawerMenuContext({ action: ACTIONS.CLOSE_ALL_DRAWER_MENU });
      navigate("/");
    }
  };

  const handleDeleteEmailItem = () => {
    handleCloseDeleteEmailItemDialog();
    if (currentReceptorEmail) {
      piezometersService
          .deleteReceptoresEmail({
            id: currentReceptorEmail.id,
          })
          .then((res) => {
            loadReceptoresEmail();
          })
          .catch((err) => {
            console.error(err);
          });
    }
  };

  const handleDeleteTipo = () => {
    handleCloseDeleteTipoDialog();
    if (currentTipoPiezometro) {
      piezometersService
          .deleteTipoPiezometro({
            id: currentTipoPiezometro.id,
          })
          .then((res) => {
            loadPiezometerGroup();
          })
          .catch((err) => {
            console.error(err);
          });
    }
  };

  //menu-option-row
  const handleClickRowOption = (event) => {
    setRowEl(event.currentTarget);
    setOpenRowEl(event.currentTarget.getAttribute("aria-controls"));
  };
  const handleCloseRowOption = () => {
    setRowEl(null);
    setOpenRowEl(null);
  };
  const handleClickAddPiezom = () => {
    handleCloseRowOption();
    setOpenAddPiezomDialog(true);
  };
  const handleCloseAddPiezomDialog = () => {
    setOpenAddPiezomDialog(false);
    setCurrentPiezometer(null);
  };

  const handleAddPiezom = (piezometer) => {
    handleCloseAddPiezomDialog();
    const {
      identifier,
      location_East,
      location_North,
      cota,
      warning,
      alert,
      alarm,
      ascent,
      ascenHisto,
      tipo,
    } = piezometer;

    var tipoReal = 0;

    tiposPiezometros.forEach((tipoP) => {
      if(tipoP.name === tipo)
        tipoReal = tipoP.id;
    })
    notify("Añadiendo un nuevo Piezómetro...")
    var data = {
      grupoId: parseInt(id),
      etiqueta: identifier,
      cota: cota,
      ubicacion: {
        x: location_East,
        y: location_North
      },
      umbralPiezometro: {
        alerta: alert,
        advertencia: warning,
        alarma:alarm,
        velocidadAscenso: ascent,
        velocidadAscensoHistorico: ascenHisto
      },
      tipoPiezometro: tipoReal
    }

    piezometersService
      .crearPiezometro(data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadPiezometerGroup();
          update(
              "Se añadío el Piezómetro correctamente 👌",
              toast.TYPE.SUCCESS
          );
        } else {
          console.error(res);
          update(
              "Error al añadir el nuevo Piezómetro 🤯 " + res.message,
              toast.TYPE.ERROR
          );
        }
      });
  };

  const handleEditPiezom = (piezometerEdited) => {
    handleCloseAddPiezomDialog();
    const {
      identifier,
      location_East,
      location_North,
      cota,
      warning,
      alert,
      alarm,
      ascent,
      ascenHisto,
      tipo
    } = piezometerEdited;

    var tipoReal = 0;

    tiposPiezometros.forEach((tipoP) => {
      if(tipoP.name === tipo)
          tipoReal = tipoP.id;
    })
    notify("Editando datos de Piezómetro...")
    piezometersService
      .updatePiezometer({
        grupoPiezometroId: parseInt(piezometerGroup.id),
        id:parseInt(currentPiezometer.id),
        etiqueta: identifier,
        cota: cota,
        ubicacion: {
          x: location_East,
          y: location_North,
        },
        umbralPiezometro: {
          alerta: alert,
          advertencia: warning,
          alarma: alarm,
          velocidadAscenso: ascent,
          velocidadAscensoHistorico: ascenHisto,
        },
        tipoPiezometro: tipoReal,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          update(
              "Los datos del piezómetro fueron actualizados correctamente 👌",
              toast.TYPE.SUCCESS
          );
          loadPiezometerGroup();
        } else {
          console.error(res);
          update(
              "Ocurrio un error al editar el piezómetro. 🤯 " + res.message,
              toast.TYPE.ERROR
          );
          loadPiezometerGroup();
        }
      });
    setCurrentPiezometer(null);
    setUpdatingThresholds(false);
  };

  const getResultadoRow = (numero) => {
    const resultado = numero.toFixed(2);
    if (resultado?.includes("99999")) return "-";
    if (resultado?.includes("NaN")) return "NaN";
    return resultado;
  };

  const handleClickPublicarCapa = () => {
    setOpenPublicar(true);
  };

  const handlePublicar = ({ nombre, fecha }) => {
    handleClosePublicar();
    notify("Publicando nueva Capa de Piezómetro...")
    piezometersService
        .publicarCapa({
          grupoId: parseInt(id),
          nombre,
          itemId: "",
          creado: fecha,
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            loadPiezometerGroup()
            update(
                "La capa fue publicada correctamente 👌",
                toast.TYPE.SUCCESS
            );
          } else {
            update(
                "Ocurrio un error al publicar la capa. 🤯 ${res.message}",
                toast.TYPE.ERROR
            );
            console.log(res);
          }
        });
    loadPiezometerGroup();
  };

  const handleClosePublicar = () => {
    setOpenPublicar(false);
  };

  const handleClickDejarPublicarCapa = () => {
    setOpenDejarPublicar(true);
  };

  const handleCloseDejarPublicar = () => {
    setOpenDejarPublicar(false);
  };

  const handleDejarPublicarCapa = () => {
    handleCloseDejarPublicar();
    piezometersService.eliminarCapaByGrupoId(parseInt(id)).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        loadPiezometerGroup();
      } else {
        toast.error("Fallo al eliminar el Grupo de Piezómetros" + res.message, {
          autoClose: 6000,
        });
      }
    });
  };

  const handleOpenCrearTipoPiezometro = () => {
    setOpenCrearTipoPiezometro(true);
  };

  const handleCloseCrearTipoPiezometro = () => {
    setOpenCrearTipoPiezometro(false);
  };

  const handleCrearTipoPiezometro = ({ nombre }) => {
    handleCloseCrearTipoPiezometro();
    var id = tiposPiezometros[tiposPiezometros.length-1].id + 1
    notify("Publicando nueva Capa de Piezómetro...")
    piezometersService
        .createTipoPiezometro({ id, nombre})
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            update(
                "El nuevo Tipo de Piezometro se creo correctamente 👌",
                toast.TYPE.SUCCESS
            );
            loadPiezometerGroup();
          } else {
            update(
                "Ha ocurrido un error al crear el nuevo tipo de piezometro. 🤯 ${res.message}",
                toast.TYPE.ERROR
            );
            console.log(res);
          }
        });
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - piezometerGroup.piezometros.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <div className="area-container">
      {loadingGroup ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={HydrogeologyBlack} alt="" width={18} height={18} />
              <h2 className="name-area">
                {piezometerGroup
                  ? piezometerGroup.nombre
                  : "Grupo de Piezómetros inexistente"}
              </h2>
            </div>
            {piezometerGroup && (
                <div className="actionsButtons">
                  <Button
                      onClick={loadPiezometerGroup}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoMenuBtn
                    editarAction={handleClickEditPiezometro}
                    eliminarAction={handleClickDeleteItem}
                    importarAction={handleClickImportarDatos}
                    // importarExcelAction={handleClickImportarDatosExcel}
                    dejarPublicarAction={publicado && (() => handleClickDejarPublicarCapa())}
                    publicarAction={!publicado && (() => handleClickPublicarCapa())}
                  />
                </div>
            )}
          </div>
          <div>
            <span>{publicado ?
                <><PublicadoIcon /> <span>Publicado</span></>
                :
                <><PublicadoIcon color="disabled" /> <span>No Publicado</span></>
            }</span>
          </div>
          {piezometerGroup ? (
            <>
              <div className="container-table">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab label="Piezómetros" {...a11yProps(0)} />
                    <Tab label="Emails Notificaciones" {...a11yProps(1)} />
                    <Tab label="Tipos" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="table-captura">
                  <Button
                      variant="contained"
                      className="btn-add-piezometro"
                      onClick={handleClickAddPiezom}
                  >
                    Adicionar Piezometro
                  </Button>
                  &nbsp;
                  {(rowsPerPage > 0) ? (
                      <>
                        <TableContainer component={Paper}>
                          <Table
                              sx={{ minWidth: 700 }}
                              size="small"
                              aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                    align="center"
                                    colSpan={1}
                                ></StyledTableCell>
                                <StyledTableCell align="center" colSpan={5}>
                                  Última Medición
                                </StyledTableCell>
                                <StyledTableCell align="center" colSpan={4}>
                                  Umbrales
                                </StyledTableCell>
                                <StyledTableCell align="center" colSpan={1}>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    colSpan={2}
                                ></StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell align="center">
                                  Identificador
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Lectura
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Cota Inst. [m]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Nivel Piez [m]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Offset [m]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Presión [bar]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Advertencia [m]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Alerta [m]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Alarma [m]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Ascenso [cm/semana]
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Tipo
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Archivado
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(rowsPerPage > 0
                                  ? piezometerGroup.piezometros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  : piezometerGroup.piezometros
                              ).map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="center">
                                      {row.etiqueta || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.ultimaData?.fecha
                                          ? moment(row.ultimaData.fecha).format(
                                              "DD-MM-YYYY HH:mm:ss"
                                          )
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.cota ? getResultadoRow(row.cota) : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.ultimaData?.nivel
                                          ? getResultadoRow(row.ultimaData.nivel)
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.ultimaData?.nivel && row.cota
                                          ? getResultadoRow(row.ultimaData.nivel - row.cota)
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.ultimaData?.presion
                                          ? getResultadoRow(row.ultimaData.presion)
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.umbralPiezometro?.advertencia
                                          ? getResultadoRow(
                                              row.umbralPiezometro?.advertencia
                                          )
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.umbralPiezometro?.alerta
                                          ? getResultadoRow(row.umbralPiezometro?.alerta)
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.umbralPiezometro?.alarma
                                          ? getResultadoRow(row.umbralPiezometro?.alarma)
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.umbralPiezometro?.velocidadAscenso
                                          ? getResultadoRow(
                                              row.umbralPiezometro?.velocidadAscenso
                                          )
                                          : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.tipo || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.archivado ? <ArchiveIcon/> : <ArchiveIcon color="disabled"/>}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-controls={row.id}
                                          aria-haspopup="true"
                                          onClick={handleClickRowOption}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <Menu
                                          id="long-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "long-button",
                                          }}
                                          anchorEl={rowEl}
                                          open={openRowEl === row.id.toString()}
                                          onClose={handleCloseRowOption}
                                          keepMounted
                                      >
                                        {/*<MenuItem onClick={handleCloseRowOption}>*/}
                                        {/*  Descargar datos de medición*/}
                                        {/*</MenuItem>*/}
                                        <MenuItem
                                            onClick={() => {
                                              setUpdatingThresholds(true);
                                              setCurrentPiezometer(row);
                                              handleClickAddPiezom();
                                            }}
                                        >
                                          Actualizar umbrales
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                              setUpdatingThresholds(false);
                                              setCurrentPiezometer(row);
                                              handleClickAddPiezom();
                                            }}
                                        >
                                          Editar
                                        </MenuItem>
                                        <MenuItem onClick={() => handleClickArchivarItem(row)}>
                                          {row.archivado ? "Activar" : "Archivar" }
                                        </MenuItem>
                                      </Menu>
                                    </StyledTableCell>
                                  </StyledTableRow>
                              ))}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: piezometerGroup.piezometros.length }]}
                                    colSpan={12}
                                    count={piezometerGroup.piezometros.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                      inputProps: {
                                        'aria-label': 'rows per page',
                                      },
                                      native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </>
                  ) : (
                      <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 700 }}
                            size="small"
                            aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                No existen piezometros
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1} className="table-captura">
                  <Button
                      variant="contained"
                      className="btn-add-acelerometro"
                      onClick={handleClickAddEmail}
                  >
                    Adicionar Email
                  </Button>
                  &nbsp;
                  {receptoresEmail.length ? (
                      <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 700 }}
                            size="small"
                            aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Identificador
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Correo Electrónico
                              </StyledTableCell>
                              <StyledTableCell align="right"></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {receptoresEmail.map((row) => {
                              return (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                      {row.id || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.email || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      <IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-controls={row.id}
                                          aria-haspopup="true"
                                          onClick={handleClickRowOption}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <Menu
                                          id="long-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "long-button",
                                          }}
                                          anchorEl={rowEl}
                                          open={openRowEl === row.id.toString()}
                                          onClose={handleCloseRowOption}
                                          keepMounted
                                      >
                                        <MenuItem
                                            onClick={() => {
                                              setCurrentReceptorEmail(row);
                                              handleClickEditEmail();
                                            }}
                                        >
                                          Editar
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                              setCurrentReceptorEmail(row);
                                              handleClickDeleteEmailItem();
                                            }}
                                        >
                                          Eliminar
                                        </MenuItem>
                                      </Menu>
                                    </StyledTableCell>
                                  </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  ) : (
                      <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 700 }}
                            size="small"
                            aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                No existen receptores de email definidos.
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2} className="table-captura">
                  <Button
                      variant="contained"
                      className="btn-add-acelerometro"
                      onClick={handleOpenCrearTipoPiezometro}
                  >
                    Adicionar Tipo
                  </Button>
                  &nbsp;
                  {tiposPiezometros.length ? (
                      <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 700 }}
                            size="small"
                            aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Identificador
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Nombre
                              </StyledTableCell>
                              <StyledTableCell align="right"></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tiposPiezometros.map((row) => {
                              return (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                      {row.id || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {row.name || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      <IconButton
                                          aria-label="more"
                                          id="long-button"
                                          aria-controls={row.id}
                                          aria-haspopup="true"
                                          onClick={handleClickRowOption}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <Menu
                                          id="long-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "long-button",
                                          }}
                                          anchorEl={rowEl}
                                          open={openRowEl === row.id.toString()}
                                          onClose={handleCloseRowOption}
                                          keepMounted
                                      >
                                        <MenuItem
                                            onClick={() => {
                                              setCurrentTipoPiezometro(row);
                                              handleClickDeleteTipo();
                                            }}
                                        >
                                          Eliminar
                                        </MenuItem>
                                      </Menu>
                                    </StyledTableCell>
                                  </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  ) : (
                      <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 700 }}
                            size="small"
                            aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                No existen tipos de piezómetros definidos.
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                  )}
                </TabPanel>
              </div>
            </>
          ) : null}
        </>
      )}

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem}
      />
      {openDeleteItemEmailDialog ? (
          <DeleteItemDialog
              open={openDeleteItemEmailDialog}
              handleClose={handleCloseDeleteEmailItemDialog}
              handleDeleteItem={handleDeleteEmailItem}
          />
      ) : null}
      {openDejarPublicar ?
          <DeleteCapaDialog
              open={openDejarPublicar}
              handleClose={handleCloseDejarPublicar}
              handleDeleteItem={handleDejarPublicarCapa}/> : null
      }
      {openDeleteTipoDialog ? (
          <DeleteItemDialog
              open={openDeleteTipoDialog}
              handleClose={handleCloseDeleteTipoDialog}
              handleDeleteItem={handleDeleteTipo}
          />
      ) : null}
      {openEditPiezomDialog ? (
        <EditPiezomDialog
          open={openEditPiezomDialog}
          handleClose={handleCloseEditPiezomDialog}
          handleEditPiezom={handleEditPiezomGroup}
          piezometerGroup={piezometerGroup}
        />
      ) : null}
      {openAddPiezomDialog ? (
        <AddPiezometroDialog
          open={openAddPiezomDialog}
          handleClose={handleCloseAddPiezomDialog}
          handleSubmit={currentPiezometer ? handleEditPiezom : handleAddPiezom}
          currentPiezometro={currentPiezometer}
          updatingThresholds={updatingThresholds}
          tiposPiezometros={tiposPiezometros}
        />
      ) : null}
      {openAddEmailDialog ? (
          <ReceptorEmailForm
              open={openAddEmailDialog}
              handleClose={handleCloseAddEmailDialog}
              handleReceptorEmail={handleAddReceptorEmail}
              edit={false}
              usuario={currentReceptorEmail}
          />
      ) : null}
      {openEditEmailDialog ? (
          <ReceptorEmailForm
              open={openEditEmailDialog}
              handleClose={handleCloseEditEmailDialog}
              handleReceptorEmail={handleEditReceptorEmail}
              edit={true}
              usuario={currentReceptorEmail}
          />
      ) : null}
      {openImportarDatosDialog ? (
        <ImportDataPiezometerDialog
          open={openImportarDatosDialog}
          handleClose={handleCloseImportarDatosDialog}
          handleSubmit={handleImportarDatos}
        />
      ) : null}
      {openImportarDatosExcelDialog ? (
          <ImportExcelPiezometroDialog
              open={openImportarDatosExcelDialog}
              handleClose={handleCloseImportarDatosExcelDialog}
              handleSubmit={handleImportarDatosExcel}
          />
      ) : null}
      {openPublicar && (
        <PublicarDialog
          open={openPublicar}
          handleClose={handleClosePublicar}
          handleSubmit={handlePublicar}
        />
      )}
      {openCrearTipoPiezometro && (
          <AddTipoPiezometroDialog
              open={openCrearTipoPiezometro}
              handleClose={handleCloseCrearTipoPiezometro}
              handleSubmit={handleCrearTipoPiezometro}
          />
      )}
    </div>
  );
};
export default Piezometros;
