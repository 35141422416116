import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import './AddPeriodoInsarDialog.css'
import RangoColor from '../../RangoColor';

const defaultColor = [{
  color: {
    r: 30,
    g: 144,
    b: 255,
    a: 1
  },
  rango: {
    minimo: 1,
    maximo: 1
  },
  valido: false
}];

const EditarSerieTiempoDialog = ({ open, handleClose, handleSubmit, serieActual }) => {
  const [colores, setColores] = useState((serieActual?.escala?.length > 0 && serieActual?.escala) || defaultColor)
  const [fecha, setFecha] = useState(serieActual?.fechaCreado || new Date());

  const close = () => {
    handleClose();
  }

  const handleChangeColor = useCallback((id, rangocolor) => {
    setColores(c => {
      const min = parseFloat(rangocolor.rango.minimo)
      const max = parseFloat(rangocolor.rango.maximo)
      let {valido} = rangocolor
      if (valido) {
        valido = c.findIndex((color, i) => {
          if (i === id || color.valido === false) {
            return false
          }
          let minimo = parseFloat(color.rango.minimo)
          let maximo = parseFloat(color.rango.maximo)
          if ((minimo === min) || (maximo === max)) {
            return true
          }
          if ((minimo > min && minimo < max) || (maximo > min && maximo < max)) {
            return true
          }
          if ((minimo < min && maximo > min) || (minimo < max && maximo > max)) {
            return true
          }
          return false;
        }) === -1
      }
      return c.map((item, index) => {
        return index !== id ? item : { ...rangocolor, valido }
      })
    })
  }, [])

  const handleEliminarColor = useCallback((id) => {
    setColores(c => c.filter((item, index) => index !== id))
  }, []);

  const handleAdicionarColor = () => {
    setColores([
      ...colores,
      ...defaultColor
    ])
  };

  const handleChangeFecha = (newFecha) => {
    setFecha(newFecha);
  };

  const handleOnSubmit = () => {
    let escala = colores.map((c) => ({ color: c.color, rango: c.rango }))
    handleSubmit({ fecha, escala });
  }

  const renderRangosColor = () => {
    return colores?.map((color, index) => {
      return (
        <RangoColor
          key={index}
          id={index}
          rangocolor={color}
          handleChange={handleChangeColor}
          eliminarAccion={colores?.length > 1 && handleEliminarColor}
        />
      )
    })
  }

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Editar Serie de Tiempo</DialogTitle>
        <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Fecha"
                inputFormat="MM/dd/yyyy"
                value={fecha}
                onChange={handleChangeFecha}
                renderInput={(params) => <TextField {...params} />}
              />
              <div>
                <InputLabel id="escala-group-label">Escala</InputLabel>
                <div className='escala-group'>
                  {
                    renderRangosColor()
                  }
                  <AddCircleOutlineIcon sx={{ color: "#0e6dca;", marginLeft: '0.5rem' }}
                    onClick={handleAdicionarColor}
                  />
                </div>
              </div>
            </Stack>
          </LocalizationProvider>

        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            Cancelar
          </Button>
          <Button
            disabled={colores?.findIndex((color) => color.valido === false) !== -1}
            onClick={handleOnSubmit}>Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
export default EditarSerieTiempoDialog