import axios from "../config/axiosInstance";
import { areasBaseUrl, sectoresBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const sectionService = {
  getAllAreas: async () => {
    return axios("/areas/areas", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },

  createArea: async (data) => {
    try {
      const result = await axios("/areas/areas", {
        method: "post",
        data,
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getArea: async (id) => {
    try {
      const result = await axios(`/areas/areas/${id}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  updateArea: async (data) => {
    try {
      const result = await axios(`/areas/areas/${data.id}`, {
        data,
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  deleteArea: async (id) => {
    try {
      const result = await axios(`/areas/areas/${id}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getAllSectores: async () => {
    return axios("/sectores/api/sectores/sectores", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getSector: async (id) => {
    try {
      const result = await axios(`/sectores/api/sectores/sector/${id}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  updateSector: async (data) => {
    try {
      const result = await axios(`/sectores/api/sectores/sector/${data.id}`, {
        data,
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  deleteSector: async (id) => {
    try {
      const result = await axios(`/sectores/api/sectores/sector/${id}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  createSector: async (data) => {
    try {
      const result = await axios("/sectores/api/sectores/sector", {
        method: "post",
        data,
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
};
