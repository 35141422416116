import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Sismo.css";
import moment from "moment";
import { sismosService } from "../../../services/SismosService";
import MenuBtn from "../../Dialogs/Sismos/MenuBtn";
import { Button, CircularProgress } from "@mui/material";
import AddDialog from "../../Dialogs/Sismos/AddDistanciaDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Distancia = ({ umbralDistancia, loadGrupoSismos }) => {
  const [editando, setEditando] = useState(false);
  const [importandoDatos, setImportandoDatos] = useState({
    importando: false,
    status: "Importando",
  });
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleClickAdd = () => {
    setOpenAddDialog(true);
  };
  const handleClickEditar = () => {
    setEditando(true);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    editando && setEditando(false);
    openAddDialog && setOpenAddDialog(false);
  };

  const handleAdd = ({ distancia, fecha, origenX, origenY }) => {
    handleCloseAddDialog();
    setImportandoDatos({ importando: true, status: "Creando Umbral de Distancia" });
    let dist = parseFloat(distancia);
    let orgx = parseFloat(origenX);
    let orgy = parseFloat(origenY);
    sismosService
      .crearUmbralDistancia({
        distancia: dist,
        origen: { x: orgx, y: orgy },
        creado: new Date(fecha).toISOString(),
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadGrupoSismos();
          setImportandoDatos({ importando: false, status: "Hecho" });
        } else {
          console.log(res);
          setImportandoDatos({ importando: false, status: "Fallido" });
        }
      });
  };
  const handleEditar = ({ distancia, origenX, origenY }) => {
    console.log("DIST", distancia);
    handleCloseAddDialog();
    setImportandoDatos({ importando: true, status: "Editando Umbral de Distancia" });
    let dist = parseFloat(distancia);
    let orgx = parseFloat(origenX);
    let orgy = parseFloat(origenY);
    sismosService
      .editarUmbralDistancia({
        id: umbralDistancia.id,
        distancia: dist,
        origen: { x: orgx, y: orgy },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          loadGrupoSismos();
          setImportandoDatos({ importando: false, status: "Hecho" });
        } else {
          console.log(res);
          setImportandoDatos({ importando: false, status: "Fallido" });
        }
      });
  };

  return (
    <div>
      <Button
        disabled={importandoDatos.importando}
        variant="contained"
        className="btn-add"
        onClick={handleClickAdd}
      >
        {importandoDatos.importando && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        Adicionar
      </Button>
      <div className="container-table">
        {(umbralDistancia) ?
            <TableContainer component={Paper}>
              <Table
                  sx={{ minWidth: 700 }}
                  size="small"
                  aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Identificador</StyledTableCell>
                    <StyledTableCell align="center">
                      Fecha de creado
                    </StyledTableCell>
                    <StyledTableCell align="center">Distancia</StyledTableCell>
                    <StyledTableCell align="center">Origen X</StyledTableCell>
                    <StyledTableCell align="center">Origen Y</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {umbralDistancia?.id ? umbralDistancia.id : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {umbralDistancia?.creado
                          ? moment(umbralDistancia.creado).local().format("DD-MM-YYYY HH:mm:ss")
                          : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {umbralDistancia?.distancia ? umbralDistancia.distancia : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {umbralDistancia?.origen?.x ? umbralDistancia.origen.x : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {umbralDistancia?.origen?.y ? umbralDistancia.origen.y : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {umbralDistancia?.id && <MenuBtn editarAction={handleClickEditar} />}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
        :
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No existen Umbral de Distancia definido</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        }
      </div>
      {openAddDialog ? (
        <AddDialog
          open={openAddDialog}
          handleClose={handleCloseAddDialog}
          handleSubmit={editando ? handleEditar : handleAdd}
          distancia={editando && umbralDistancia}
        />
      ) : null}
    </div>
  );
};
export default Distancia;
