import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import moment from 'moment';
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import OrtoFotoBlackIcon from '../../../../assets/ortofotoBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddOrtofotoDialog from '../../../Dialogs/Ortofotos/AddOrtofotoDialog'
import EditGrupoOrtofotoDialog from '../../../Dialogs/Ortofotos/EditOrtofotoDialog';
import { ortofotosService } from '../../../../services/OrtofotosService';
import GrupoOrtofotoMenuBtn from '../../../Dialogs/Ortofotos/GrupoOrtofotoMenuBtn';
import './Ortofotos.css'
import NotificacionMensaje from '../../../NotificacionMensaje';
import CategoriasMenuBtn from '../../../Dialogs/CategoriasMenuBtn';
import PublicarCapaDialog from '../../../Dialogs/PublicarCapaTopografiaDialog';
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Ortofotos = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grupoOrtofotos, setGrupoOrtofotos] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [drawerMenuCOntext, setDrawerMenuContext] = useDrawerMenu();

  const [openAddOrtofotoDialog, setOpenAddOrtofotoDialog] = useState(false);
  const [openEditOrtofotoDialog, setOpenEditOrtofotoDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

  const [currentOrtofoto, setCurrentOrtofoto] = useState(null);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
  const [openPublicarOrt, setOpenPublicarOrt] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    let mounted = true;
    mounted && cargarGrupoOrtofotos();
    return () => mounted = false;
  }, [id]);

  const cargarGrupoOrtofotos = () => {
    setLoadingGroup(true);
    ortofotosService.getGrupo(parseInt(id))
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupoOrtofotos(res.data.grupoOrtofoto);
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
      .catch(err => {
        console.error(err);
        setLoadingGroup(false);
      })
  };

  const handleClickEditGOrtofoto = () => {
    setOpenEditOrtofotoDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseEditOrtofotoDialog = () => {
    setOpenEditOrtofotoDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditGrupoOrtofotos = (nombre) => {
    handleCloseEditOrtofotoDialog(false)
    ortofotosService.updateGroup({ id, nombre })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargarGrupoOrtofotos();
        } else {
          console.log(res)
        }
      })
  }

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentOrtofoto) {
      ortofotosService.eliminarOrtofoto({
        ortofotoId: currentOrtofoto.id,
        grupoOrtofotosId: grupoOrtofotos.id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setCurrentOrtofoto(null)
            cargarGrupoOrtofotos();
          } else {
            console.log(res)
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      ortofotosService.deleteGroup(grupoOrtofotos.id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
            navigate('/');
          } else { console.log(res) }
        })
        .catch(err => {
          console.error(err)
        })
    }
  };

  //menu-option-row
  const handleClickAddOrtofoto = () => {
    setOpenAddOrtofotoDialog(true);
  }

  const handleCloseAddOrtofotoDialog = () => {
    setOpenAddOrtofotoDialog(false);
    setCurrentOrtofoto(null);
  };

  const handleAddOrtofoto = ({ fecha, ficheros, workSpace, nombreCapa }) => {
    handleCloseAddOrtofotoDialog(false)
    setImportandoDatos({ importando: true, status: "Importando" })
    const formData = new FormData()
    formData.append("UploadFile", ficheros)
    formData.append("FechaCreado", new Date(fecha).toISOString())
    formData.append("GeoServerWorkSpace", workSpace)
    formData.append("NombreCapa", nombreCapa)
    ortofotosService.uploadOrtofoto({
      idGrupo: grupoOrtofotos.id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        setCurrentOrtofoto(null);
        cargarGrupoOrtofotos();
        setImportandoDatos({ importando: false, status: "Hecho" })
      } else {
        console.log(res)
        setImportandoDatos({ importando: false, status: "Fallido" })
      }
    });
  }

  const handleDescargarOrtofoto = (ort) => {
    ortofotosService.descargarOrtofoto({
      grupoOrtofotosId: grupoOrtofotos.id,
      ortofotoId: ort.id
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          fileDownload(res.data, `(${moment(ort.creado).format("DD-MM-YYYY")})ortofoto.tif`)
        } else { console.log(res) }
      })
  }

  const handleDejarPublicar = () => {
    alert("Esta acción no esta disponible")
  }

  const handleClosePublicarOrt = () => {
    setOpenPublicarOrt(false)
  }

  const handleClickPublicarOrt = (ort) => {
    setCurrentOrtofoto(ort)
    setOpenPublicarOrt(true)
  }

  const handlePublicarOrt = ({ url, nombre, fecha }) => {
    handleClosePublicarOrt()
    ortofotosService.publicarCapa({ ortofotoId: currentOrtofoto.id, nombre, url, creado: fecha })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          let ortofotos = grupoOrtofotos?.ortofotos.map((ort) => {
            if (ort.id === currentOrtofoto.id) {
              return {
                ...ort,
                publicado: true
              }
            } else {
              return ort;
            }
          })
          setGrupoOrtofotos({ ...grupoOrtofotos, ortofotos })
          setCurrentOrtofoto()
        } else {
          console.log(res)
        }
      })
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setImportandoDatos({ ...importandoDatos, status: 'Importando' })
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupoOrtofotos.ortofotos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Publicado</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupoOrtofotos.ortofotos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupoOrtofotos.ortofotos
            ).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell align="center">{row.creado ? moment(row.creado).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.publicado ?
                    <PublicadoIcon fontSize="small" />
                    :
                    <PublicadoIcon fontSize="small" color="disabled" />
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  <CategoriasMenuBtn
                    disabled={importandoDatos.importando}
                    dejarPublicarAction={row.publicado && (() => handleDejarPublicar(row))}
                    publicarAction={!row.publicado && (() => handleClickPublicarOrt(row))}
                    descargarAction={() => handleDescargarOrtofoto(row)}
                    eliminarAction={() => {
                      setCurrentOrtofoto(row);
                      handleClickDeleteItem();
                    }} />
                </StyledTableCell>
              </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: grupoOrtofotos.ortofotos.length }]}
                  colSpan={12}
                  count={grupoOrtofotos.ortofotos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen ortofotos</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {loadingGroup ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={OrtoFotoBlackIcon} alt="" width={18} height={18} />
              <h2 className="name-area">{grupoOrtofotos ? grupoOrtofotos.nombre : 'Grupo de Ortofotos inexistente'}</h2>
            </div>
            {grupoOrtofotos
              ?
                <div className="actionsButtons">
                  <Button
                      onClick={cargarGrupoOrtofotos}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoOrtofotoMenuBtn
                    editarAction={handleClickEditGOrtofoto}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
              : null}
          </div>
          {grupoOrtofotos ?
            <>
              <Button
                disabled={importandoDatos.importando}
                variant="contained"
                className="btn-add-ortofoto"
                onClick={handleClickAddOrtofoto}
              >{importandoDatos.importando && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}Adicionar
              </Button>
              <div className="container-table">
                {renderTabla()}
              </div>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditOrtofotoDialog
        ? <EditGrupoOrtofotoDialog
          open={openEditOrtofotoDialog}
          handleClose={handleCloseEditOrtofotoDialog}
          handleEditarGrupo={handleEditGrupoOrtofotos}
          grupoOrtofotos={grupoOrtofotos}
        /> : null}
      {openAddOrtofotoDialog ?
        <AddOrtofotoDialog
          open={openAddOrtofotoDialog}
          handleClose={handleCloseAddOrtofotoDialog}
          handleSubmit={handleAddOrtofoto}
        /> : null}
      {openPublicarOrt && <PublicarCapaDialog
        open={openPublicarOrt}
        handleClose={handleClosePublicarOrt}
        handleSubmit={handlePublicarOrt}
      />}
      <NotificacionMensaje
        open={importandoDatos.status === 'Fallido'}
        onClose={handleCloseAlert}
        message="Ha fallado la subida de los archivos"
      />
    </div>
  );
}
export default Ortofotos;


