import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PuntoMenuBtn from "../../../Dialogs/Revancha/PuntoMenuBtn";
import "./Revancha.css";
import moment from "moment";
import EditarPuntoDialog from "../../../Dialogs/Revancha/EditarPuntoDialog";
import { revanchaService } from "../../../../services/RevanchaService";
import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import {Button, TableFooter, TablePagination} from "@mui/material";
import { toast } from "react-toastify";
import {fromLatLon, utm2slc} from "../../../../utils/ll2utm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Datos = ({ revancha, loadPuntos }) => {
  // console.log("DATOS REV",revancha);
  const [currentPunto, setCurrentPunto] = useState();
  const [openEditarPuntoDialog, setOpenEditarPuntoDialog] = useState(false);
  const [openEliminarPuntoDialog, setOpenEliminarPuntoDialog] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleClickAddPunto = () => {
    setOpenEditarPuntoDialog(true);
  };

  const handleAddPunto = ({ etiqueta }) => {
    handleCloseEditarPuntoDialog();
    revanchaService
      .crearPunto({
        revanchaId: revancha.id,
        etiqueta,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          toast.success("Se adicionó el Punto de Revancha");
          loadPuntos(revancha);
        } else {
          toast.error("Fallo al adicionar el Punto de Revancha" + res.message, {
            autoClose: false,
          });
        }
      });
  };
  const handleCloseEditarPuntoDialog = () => {
    setOpenEditarPuntoDialog(false);
    setCurrentPunto();
  };

  const handleClickEditarPunto = (row) => {
    setCurrentPunto(row);
    setOpenEditarPuntoDialog(true);
  };

  const handleEditarPunto = ({ etiqueta }) => {
    revanchaService
      .editarPunto({
        revanchaId: revancha.id,
        puntoRevanchaId: currentPunto.id,
        etiqueta,
      })
      .then((res) => {
        handleCloseEditarPuntoDialog();
        if (res.status >= 200 && res.status < 300) {
          toast.success("Se editó el Punto de Revancha");
          loadPuntos(revancha);
        } else {
          toast.error("Fallo al editar el Punto de Revancha" + res.message, {
            autoClose: false,
          });
        }
      });
  };

  const handleCloseEliminarPuntoDialog = () => {
    setOpenEliminarPuntoDialog(false);
    setCurrentPunto();
  };

  const handleClickEliminarPunto = (row) => {
    setCurrentPunto(row);
    setOpenEliminarPuntoDialog(true);
  };

  const handleEliminarPunto = () => {
    handleCloseEliminarPuntoDialog();
    revanchaService
      .eliminarPunto({
        revanchaId: revancha.id,
        puntoRevanchaId: currentPunto.id,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          toast.success("Se editó el Punto de Revancha");
          loadPuntos(revancha);
        } else {
          toast.error("Fallo al eliminar el Punto de Revancha" + res.message, {
            autoClose: false,
          });
        }
      });
  };

  const handleSubmit = ({ etiqueta }) => {
    currentPunto
      ? handleEditarPunto({ etiqueta })
      : handleAddPunto({ etiqueta });
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - revancha.puntos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCoordenadasLocales = (ubicacion) => {
    if(ubicacion.x !== 0 && ubicacion.y !== 0) {
      var latitud = ubicacion.x;
      var longitud = ubicacion.y;

      var utmEste = fromLatLon(latitud, longitud).easting;
      var utmNorte = fromLatLon(latitud, longitud).northing;

      var utmEsteMina = utm2slc(utmEste, utmNorte).eastingLocal;
      var utmNorteMina = utm2slc(utmEste, utmNorte).northingLocal;

      return {utmEsteMina, utmNorteMina}
    }
    return '-'
  }

  const renderTabla = () => (
    <div className="container-table">
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Id.</StyledTableCell>
              <StyledTableCell align="center">Etiqueta</StyledTableCell>
              <StyledTableCell align="center">Este Mina</StyledTableCell>
              <StyledTableCell align="center">Norte Mina</StyledTableCell>
              <StyledTableCell align="center">Cota Superior</StyledTableCell>
              <StyledTableCell align="center">Cota Inferior</StyledTableCell>
              <StyledTableCell align="center">
                Diferencia de Cotas
              </StyledTableCell>
              <StyledTableCell align="center">
                Fecha 1ra Medición
              </StyledTableCell>
              <StyledTableCell align="center">
                Fecha Última Medición
              </StyledTableCell>
              <StyledTableCell align="center" width="5%"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? revancha.puntos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : revancha.puntos
            ).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {row.id || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.etiqueta || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {(row?.ultimaUbicacion) ? getCoordenadasLocales(row?.ultimaUbicacion).utmEsteMina : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {(row?.ultimaUbicacion) ? getCoordenadasLocales(row?.ultimaUbicacion).utmNorteMina : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.ultimaCotaSuperior || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.ultimaCotaInferior || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.ultimaDiferenciaCotas || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.fechaPrimeraMedicion
                    ? moment(row.fechaPrimeraMedicion).format("DD-MM-YYYY")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.fechaUltimaMedicion
                    ? moment(row.fechaUltimaMedicion).format("DD-MM-YYYY")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="center" width="5%">
                  <PuntoMenuBtn
                    editarAction={() => handleClickEditarPunto(row)}
                    eliminarAction={() => handleClickEliminarPunto(row)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: revancha.puntos.length }]}
                  colSpan={12}
                  count={revancha.puntos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <div>
      {revancha && (
        <>
          <Button
            variant="contained"
            className="btn-add"
            onClick={handleClickAddPunto}
          >
            Adicionar
          </Button>
          <div className="container-table">{renderTabla()}</div>
        </>
      )}
      {openEditarPuntoDialog && (
        <EditarPuntoDialog
          titulo={!currentPunto && "Adicionar Punto de Revancha"}
          open={openEditarPuntoDialog}
          handleClose={handleCloseEditarPuntoDialog}
          handleSubmit={handleSubmit}
          etiquetaPunto={currentPunto?.etiqueta}
        />
      )}
      {openEliminarPuntoDialog && (
        <DeleteItemDialog
          open={openEliminarPuntoDialog}
          handleClose={handleCloseEliminarPuntoDialog}
          handleDeleteItem={handleEliminarPunto}
        />
      )}
    </div>
  );
};
export default Datos;
