import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteItemDialog from "../../../Dialogs/DeleteItemDialog";
import "./Revancha.css";
import DeformacionesAcumuladasMenuBtn from "../../../Dialogs/SeriesInSAR/DeformAcumMenuBtn";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eaeaea",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Alarmas = ({ grupo, alarmas }) => {
  const [loadingAdicionarAlarma, setLoadingAdicionarAlarma] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  const [openAddAlarmaDialog, setOpenAddAlarmaDialog] = useState(false);
  const [alarmaActual, setAlarmaActual] = useState();

  const handleClickAddAlarma = (row) => {
    if (row?.alarma) {
      setAlarmaActual(row);
      alarmas[row.alarma].activo = false;
    }
    setOpenAddAlarmaDialog(true);
  };

  const handleClickDeleteItem = (row) => {
    setAlarmaActual(row);
    setOpenDeleteItemDialog(true);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleDeleteAlarma = () => {};

  return (
    <div>
      <div className="container-table">
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Alarmas</StyledTableCell>
                <StyledTableCell align="center" width="5%">
                  Umbral
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grupo?.alarmas?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {alarmas[row.alarma].etiqueta}
                  </StyledTableCell>
                  <StyledTableCell>-</StyledTableCell>
                  <StyledTableCell align="center">
                    <DeformacionesAcumuladasMenuBtn
                      disabled={loadingAdicionarAlarma}
                      editarAction={() => {
                        handleClickAddAlarma(row);
                      }}
                      eliminarAction={() => {
                        handleClickDeleteItem(row);
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteAlarma}
      />

      {/* {openAddAlarmaDialog ? (
        <AddAlarmaInsarDialog
          open={openAddAlarmaDialog}
          handleClose={handleCloseAddAlarmaDialog}
          handleSubmit={handleAddAlarmaInSar}
          alarmaActual={alarmaActual}
          alarmasActivos={alarmas}
        />
      ) : null} */}
    </div>
  );
};
export default Alarmas;
