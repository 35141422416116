import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import './AddElementoDialog.css'


const AddElementoDialog = ({ open, handleClose, handleSubmit }) => {
    const [fechaMedicion, setFechaMedicion] = useState(new Date());

    const handleChange = (newFecha) => {
        setFechaMedicion(newFecha);
    };

    const close = () => {
        handleClose();
    }

    const saveElement = () => {
        handleSubmit({ fechaMedicion });
    };

    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Adicionar elemento</DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Fecha"
                            inputFormat="MM/dd/yyyy"
                            value={fechaMedicion}
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        Cancelar
                    </Button>
                    <Button disabled={!fechaMedicion}
                        onClick={saveElement}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default AddElementoDialog;