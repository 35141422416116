import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const AddElementoDialog = ({ open, handleClose, handleSubmit, nombre }) => {

    const [etiqueta, setEtiqueta] = useState(nombre || "");

    const handleChangeEtiqueta = (event) => {
        setEtiqueta(event.target.value);
    }

    const close = () => {
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>{(nombre) ? "Editar elemento" : "Adicionar elemento" }</DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>
                    <TextField
                        margin="dense"
                        id="etiqueta"
                        label="Etiqueta"
                        fullWidth
                        value={etiqueta}
                        onChange={handleChangeEtiqueta}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        Cancelar
                    </Button>
                    <Button disabled={!etiqueta}
                        onClick={() => {
                            handleSubmit({ etiqueta })
                        }}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default AddElementoDialog;