import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../Pages/Layers/Acelerometros/Acelerometros.css"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const ReceptorEmailForm = ({ open, handleClose, handleReceptorEmail, edit, usuario }) => {
  const [email, setEmail] = useState(usuario?.email || '');
  const resetValues = () => {
    setEmail("");
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const close = () => {
    resetValues();
    handleClose();
  }

  return (
      <div>
        <Dialog open={open} onClose={() => close()}>
          <DialogTitle>{(edit) ? "Editar Receptor" : "Añadir Receptor" } </DialogTitle>
          <DialogContent style={{ paddingTop: '1rem' }}>

            <TextField
                margin="dense"
                id="email"
                label="Email"
                fullWidth
                value={email}
                onChange={handleChangeEmail}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              close();
            }}>
              Cancelar
            </Button>
            <Button onClick={() => handleReceptorEmail({ ...usuario, email: email})}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
export default ReceptorEmailForm;
