import React, { memo } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { rgbToHex } from '@mui/material';
import InputEscala from './InputEscala';
import PaletaColores from './Dialogs/PaletaColores';

/**RangoColor 
 * 
 * @param id posicion en la que se encuentra rangocolor en caso de estar dentro de un listado.
 * 
 * @param rangocolor objeto con formato {color{r,g,b,a}, rango:{minimo, maximo}}
 * 
 * @param eliminarAccion se ejecuta al presionar iconRight
 * 
 * @param handleChange se ejecuta al cambiar los valores minimo, maximo o color
 */
const RangoColor = memo(({ id, rangocolor, eliminarAccion, handleChange }) => {

    const handleChangeColor = (color) => {
        handleChange(id, {
            ...rangocolor,
            color: color.rgb
        })
    }

    const validarRango = ({ minimo, maximo }) => {
        const valido = minimo !== "" && maximo !== "" && parseFloat(minimo) < parseFloat(maximo)
        handleChange(id, {
            ...rangocolor,
            rango: { minimo, maximo },
            valido
        });
    }

    const handleChangeMinimo = (value) => {
        validarRango({ minimo: value, maximo: rangocolor.rango.maximo })
    }

    const handleChangeMaximo = (value) => {
        validarRango({ minimo: rangocolor.rango.minimo, maximo: value })
    }
    const handleEliminar = () => {
        eliminarAccion(id)
    }

    return (
        <div>
            <div className="escala" >
                <InputEscala
                    label='Desde'
                    numero={rangocolor.rango.minimo}
                    onChange={handleChangeMinimo}
                    error={rangocolor.valido !== undefined && !rangocolor.valido}
                />
                <InputEscala
                    label='Hasta'
                    numero={rangocolor.rango.maximo}
                    onChange={handleChangeMaximo}
                    error={rangocolor.valido !== undefined && !rangocolor.valido}
                />
                < PaletaColores
                    color={rgbToHex(`rgba(${rangocolor.color.r}, ${rangocolor.color.g}, ${rangocolor.color.b}, ${rangocolor.color.a})`)}
                    onChangeComplete={handleChangeColor}
                />
                {
                    eliminarAccion && < HighlightOffIcon sx={{ color: "#911212" }}
                        onClick={handleEliminar}
                    />
                }
            </div>
        </div >
    );
})
export default RangoColor