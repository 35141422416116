import React, { useState, useRef } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./ImportArchivoDialog.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const FILE_TYPES = ".zip";
const FILE_TEXT = "Seleccione un archivo (*.zip)";

const ImportarSerieTiempoDialog = ({ open, handleClose, handleSubmit, periodoActual, periodosActivos }) => {

  const findPeriodoActivo = () => {
    return (!periodosActivos["7 dias"].activo && "7 dias") ||
        (!periodosActivos["12 dias"].activo && "12 dias") ||
        (!periodosActivos["30 dias"].activo && "30 dias") ||
        (!periodosActivos["6 meses"].activo && "6 meses")
  }

  const [archivo, setArchivo] = useState();
  const [periodo, setPeriodo] = useState(null);
  const hiddenArchivo = useRef(null);

  const close = () => {
    handleClose();
  };

  const handleClickImportarArchivo = (event) => {
    hiddenArchivo.current.click();
  };

  const cargarArchivo = (event) => {
    setArchivo(event.target.files[0]);
  };

  const uploadFiles = () => {
    handleSubmit({ periodo, ficheros: archivo });
  };

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Subir archivo</DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
            <Stack spacing={3}>
              <FormControl sx={{ marginTop: 1, minWidth: 120, width: '100%' }}>
                <InputLabel id="item-type-label">Período de Estudio</InputLabel>
                <Select
                    labelId="item-type-select-label"
                    id="item-type-select"
                    value={periodo}
                    label="Período de Estudio<"
                    onChange={(e) => setPeriodo(e.target.value)}>
                  <MenuItem value="7 dias">7 días</MenuItem>
                  <MenuItem value="12 dias">12 días</MenuItem>
                  <MenuItem value="30 dias">30 días</MenuItem>
                  <MenuItem value="6 meses">6 meses</MenuItem>
                </Select>
              </FormControl>
              <div>
                <InputLabel id="select-datos-label">Archivo</InputLabel>
                <div className="select-datos">
                  <input
                    ref={hiddenArchivo}
                    type="file"
                    style={{ display: "none" }}
                    accept={FILE_TYPES}
                    onChange={cargarArchivo}
                  />
                  <Button
                    size="sm"
                    className="rounded mr-2"
                    color={"primary"}
                    variant="contained"
                    onClick={handleClickImportarArchivo}
                    style={{ marginRight: "8px" }}
                  >
                    Selecionar
                  </Button>
                  <span>{`${archivo ? `${archivo.name}` : FILE_TEXT}`}</span>
                </div>
              </div>
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={!archivo} onClick={uploadFiles}>
            Subir
          </Button>
          <Button onClick={close}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ImportarSerieTiempoDialog;
