import React, { useReducer, createContext, useContext } from "react";

const initialState = {
    areas: [
        {
            id: 'root-1',
            name: 'Area. 1 Faena Cordillera',
            description: 'Área destinada a propósitos del tranque de la mina ',
            sectors: [
                {
                    id: '2',
                    name: 'Sector. 7 Depósito de Relaves',
                    description: 'Sector que agrupa los elementos correspondientes al deposito de relaves'
                }
            ]
        }
    ],
};

const ACTIONS = {
    ADD_AREA: "ADD_AREA",
    UPDATE_AREA: "UPDATE_AREA",
    DELETE_AREA: "DELETE_AREA",
    ADD_SECTOR: "ADD_SECTOR",
    UPDATE_SECTOR: "UPDATE_SECTOR",
    DELETE_SECTOR: "DELETE_SECTOR",

};

const reducer = (state, data) => {
    switch (data.action) {
        case ACTIONS.ADD_AREA: {
            const { area } = data.payload;
            return {
                ...state,
                areas: [
                    ...state.areas,
                    area
                ]
            };
        };

        case ACTIONS.UPDATE_AREA: {
            const { area } = data.payload;
            return {
                ...state,
                areas: state.areas.map(a => a.id === area.id ? area : a)
            };
        };
        
        case ACTIONS.DELETE_AREA: {
            const { id } = data.payload;
            return {
                ...state,
                areas: state.areas.filter(a => a.id !== id)
            };
        };

        case ACTIONS.ADD_SECTOR: {
            const { areaId, sector } = data.payload;
            return {
                ...state,
                areas: state.areas.map(a => {
                    if (a.id === areaId) {
                        return {
                            ...a,
                            sectors: [
                                ...a.sectors,
                                sector
                            ]
                        };
                    } else {
                        return a;
                    }
                })
            };
        };

        case ACTIONS.UPDATE_SECTOR: {
            const { areaId, sector } = data.payload;
            return {
                ...state,
                areas: state.areas.map(a => {
                    if (a.id === areaId) {
                        return {
                            ...a,
                            sectors: a.sectors.map(s => s.id === sector.id ? sector : s)
                        }
                    } else {
                        return a;
                    }
                })
            };
        };

        case ACTIONS.DELETE_SECTOR: {
            const { areaId, sectorId } = data.payload;
            return {
                ...state,
                areas: state.areas.map(a => {
                    if (a.id === areaId) {
                        return {
                            ...a,
                            sectors: a.sectors.filter(s => s.id !== sectorId)
                        }
                    } else {
                        return a;
                    }
                })
            };
        };

        default:
            throw new Error();
    }
};

const SectionsContext = createContext(initialState);

const SectionsProvider = ({ children }) => {
    const stateAndDispatch = useReducer(reducer, initialState);
    return (
        <SectionsContext.Provider value={stateAndDispatch}>
            {children}
        </SectionsContext.Provider>
    );
}

export const useSections = () => useContext(SectionsContext);

export { SectionsContext, SectionsProvider, ACTIONS };
