import React from 'react';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const NotificacionMensaje = ({ message, onClose, open }) => {

    return (
        <div className="escala">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open || false}
                onClose={onClose}
                message={message || "Mensaje de notificación"}
                key={'topright'} 
                autoHideDuration={6000} 
                action={<IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>}
                >
            </Snackbar>
        </div>
    );
}
export default NotificacionMensaje;