import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { NumberFormatBase } from 'react-number-format';

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormatBase
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            isNumericString
        />
    );
});

NumberFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const InputEscala = ({ label, numero, onChange, error }) => {

    const handleChange = (evt) => {
        onChange(evt.target.value)
    }

    return (
        <div className="escala">
            <TextField
                error={!numero || error}
                margin="dense"
                label={label}
                fullWidth
                value={numero}
                onChange={handleChange}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }} 
            />
        </div>
    );
}
export default InputEscala;