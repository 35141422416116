import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const FILE_TYPES = ".msd";
const FILE_TEXT = "Seleccione un archivo (*.msd)";

const ImportDataAcelerometroDialog = ({ open, handleClose, handleSubmit }) => {
  // const [ficheros, setFicheros] = useState();
  const [fichero, setFichero] = useState();
  //const [fecha, setFecha] = useState(new Date());

  const hiddenFileInput = useRef(null);

  // const handleChange = (newFecha) => {
  //   setFecha(newFecha);
  // };

  const close = () => {
    handleClose();
  };

  const handleClickImportar = (event) => {
    hiddenFileInput.current.click();
  };

  const cargarFichero = (event) => {
    setFichero(event.target.files);
  };

  const uploadFiles = () => {
    // handleSubmit({ fecha, ficheros: fichero });
    handleSubmit({ ficheros: fichero });
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Importar datos</DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          {/* <input
            ref={hiddenFileInput}
            type='file'
            style={{ display: 'none' }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={cargarArchivos}
          />
          <Button
            size="sm"
            className="rounded mr-2"
            color={"primary"}
            variant="contained"
            onClick={handleClickImportarFicheros}
            style={{ marginRight: '8px'}}
          >
            Selecionar
          </Button>
          <span>{`${ficheros?.length > 0 ? `${ficheros[0].name}` : 'Ningún fichero seleccionado'}`}</span> */}

                <InputLabel id="select-datos-label">Acelerómetro</InputLabel>
                <div className="select-datos">
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    style={{ display: "none" }}
                    accept={FILE_TYPES}
                    onChange={cargarFichero}
                  />
                  <Button
                    size="sm"
                    className="rounded mr-2"
                    color={"primary"}
                    variant="contained"
                    onClick={handleClickImportar}
                    style={{ marginRight: "8px" }}
                  >
                    Selecionar
                  </Button>
                  <span>{fichero ? fichero[0].name : FILE_TEXT}</span>
                </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button disabled={!fichero} onClick={uploadFiles}>
            Subir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ImportDataAcelerometroDialog;
