import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import moment from 'moment';
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import MapaIcon from '../../../../assets/ortofotoBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import AddMapaDialog from '../../../Dialogs/Mapa/AddMapaDialog'
import EditarMapaDialog from '../../../Dialogs/Mapa/EditarMapaDialog';
import { mapaService } from '../../../../services/MapaService';
import GrupoMapaMenuBtn from '../../../Dialogs/Mapa/GrupoMapaMenuBtn';
import './MapaElevaciones.css'
import CategoriasMenuBtn from '../../../Dialogs/CategoriasMenuBtn';
import PublicarCapaDialog from '../../../Dialogs/PublicarCapaTopografiaDialog';
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343434",
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#eaeaea",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const MapaElevaciones = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grupoMapas, setGrupoMapas] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();

  const [openAddMapaDialog, setOpenAddMapaDialog] = useState(false);
  const [openEditMapaDialog, setOpenEditMapaDialog] = useState(false);
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

  const [currentMapa, setCurrentMapa] = useState(null);
  const [openPublicarCapa, setOpenPublicarCapa] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    let mounted = true;
    mounted && cargarGrupoMapas();
    return () => mounted = false;
  }, [id]);

  const cargarGrupoMapas = () => {
    setLoadingGroup(true);
    mapaService.getGrupo(id)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setGrupoMapas(res.data.grupo);
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleClickEditGMapa = () => {
    setOpenEditMapaDialog(true);
  };

  const handleClickDeleteItem = () => {
    setOpenDeleteItemDialog(true);
  };

  const handleCloseEditMapaDialog = () => {
    setOpenEditMapaDialog(false);
  };

  const handleCloseDeleteItemDialog = () => {
    setOpenDeleteItemDialog(false);
  };

  const handleEditGrupoMapas = (nombre) => {
    handleCloseEditMapaDialog();
    setLoadingGroup(true);
    mapaService.updateGrupo({ id, nombre })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cargarGrupoMapas();
        } else {
          console.log(res)
        }
        setLoadingGroup(false);
      })
  };

  const handleDeleteItem = () => {
    handleCloseDeleteItemDialog();
    if (currentMapa) {
      mapaService.eliminarMapa({
        mapaId: currentMapa.id,
        grupoId: id
      })
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setCurrentMapa(null);
            cargarGrupoMapas();
          } else {
            console.log(res)
          }
          setLoadingGroup(false);
        })
    } else {
      mapaService.eliminarGrupo(id)
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
            navigate('/');
          } else {
            console.log(res)
          }
          setLoadingGroup(false);
        })
    }
  };

  //menu-option-row
  const handleClickAddMapa = () => {
    setOpenAddMapaDialog(true);
  };
  const handleCloseAddMapaDialog = () => {
    setOpenAddMapaDialog(false);
    setCurrentMapa(null);
  };

  const handleAddMapa = ({ fecha, ficheros, workSpace, nombreCapa }) => {
    handleCloseAddMapaDialog();
    setImportandoDatos({ importando: true, status: "Importando" })
    const formData = new FormData()
    formData.append("UploadFile", ficheros)
    formData.append("FechaCreado", new Date(fecha).toISOString())
    formData.append("GeoServerWorkSpace", workSpace)
    formData.append("NombreCapa", nombreCapa)
    mapaService.uploadMapa({
      idGrupo: id,
      formData
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        setCurrentMapa(null);
        cargarGrupoMapas();
        setImportandoDatos({ importando: false, status: "Hecho" })
      } else {
        console.log(res)
        setImportandoDatos({ importando: false, status: "Fallido" })
      }
    });
  };

  const handleDescargarMapa = (ort) => {
    mapaService.descargarMapa({
      grupoId: id,
      mapaId: ort.id
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          fileDownload(res.data, `(${moment(ort.fechaCreado).format("DD-MM-YYYY")})mapa.tif`)
        } else { console.log(res) }
      })
  };

  const handleClickPublicarCapa = (ort) => {
    setCurrentMapa(ort)
    setOpenPublicarCapa(true)
  }

  const handleDejarPublicarCapa = () => {
    alert("Esta acción no esta disponible")
  }

  const handleClosePublicarCapa = () => {
    setOpenPublicarCapa(false)
  }

  const handlePublicarCapa = ({ url, nombre, fecha }) => {
    handleClosePublicarCapa()
    mapaService.publicarCapa({mapaId: currentMapa.id, nombre, url, fechaCreado: fecha })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          let modelos = grupoMapas?.modelos.map((ort) => {
            if (ort.id === currentMapa.id) {
              return {
                ...ort,
                publicado: true
              }
            } else {
              return ort;
            }
          })
          setGrupoMapas({ ...grupoMapas, modelos })
          setCurrentMapa()
        } else {
          console.log(res)
        }
      })
  }

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grupoMapas.modelos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabla = () => {
    if (rowsPerPage > 0) {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Publicado</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                    ? grupoMapas.modelos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : grupoMapas.modelos
            ).map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{row.fechaCreado ? moment(row.fechaCreado).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.publicado ?
                    <PublicadoIcon fontSize="small" />
                    :
                    <PublicadoIcon fontSize="small" color="disabled" />
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  <CategoriasMenuBtn
                    disabled={importandoDatos.importando}
                    dejarPublicarAction={row.publicado && (() => handleDejarPublicarCapa(row))}
                    publicarAction={!row.publicado && (() => handleClickPublicarCapa(row))}
                    descargarAction={() => handleDescargarMapa(row)}
                    eliminarAction={() => {
                      setCurrentMapa(row);
                      handleClickDeleteItem();
                    }} />
                </StyledTableCell>
              </StyledTableRow>))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: grupoMapas.modelos.length }]}
                  colSpan={12}
                  count={grupoMapas.modelos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    } else {
      return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No existen mapas</StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    }
  }

  return (
    <div className="area-container">
      {loadingGroup ?
        <div className='loading'><CircularProgress /></div> :
        <>
          <div className="head-area">
            <div className="head-name">
              <img src={MapaIcon} alt="" width={18} height={18} />
              <h2 className="name-area">{grupoMapas ? grupoMapas.nombre : 'Grupo de Mapas inexistente'}</h2>
            </div>
            {grupoMapas
              ?
                <div className="actionsButtons">
                  <Button
                      onClick={cargarGrupoMapas}
                      sx={{ mr: "0px", color: "white", display: "block" }}
                  >
                    <img src={Reload} alt="" />
                  </Button>
                  <GrupoMapaMenuBtn
                    editarAction={handleClickEditGMapa}
                    eliminarAction={handleClickDeleteItem}
                  />
                </div>
              : null}
          </div>
          {grupoMapas ?
            <>
              <Button
                disabled={importandoDatos.importando}
                variant="contained"
                className="btn-add"
                onClick={handleClickAddMapa}
              >{importandoDatos.importando && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}Adicionar
              </Button>
              <div className="container-table">
                {renderTabla()}
              </div>
            </> : null}
        </>
      }

      <DeleteItemDialog
        open={openDeleteItemDialog}
        handleClose={handleCloseDeleteItemDialog}
        handleDeleteItem={handleDeleteItem} />
      {openEditMapaDialog
        ? <EditarMapaDialog
          open={openEditMapaDialog}
          handleClose={handleCloseEditMapaDialog}
          handleEditarGrupo={handleEditGrupoMapas}
          nombre={grupoMapas.nombre}
        /> : null}
      {openAddMapaDialog ?
        <AddMapaDialog
          open={openAddMapaDialog}
          handleClose={handleCloseAddMapaDialog}
          handleSubmit={handleAddMapa}
        /> : null}
        {openPublicarCapa && <PublicarCapaDialog
        open={openPublicarCapa}
        handleClose={handleClosePublicarCapa}
        handleSubmit={handlePublicarCapa}
      />}
    </div>
  );
}
export default MapaElevaciones;


