import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import { ejesmuroService } from '../../../../services/EjesMuroService';
import EditarCapaDialog from "../../../Dialogs/EjesMuro/EditarCapaDialog";
import {toast} from "react-toastify";
import CapaActionsMenuBtn from "../../../Dialogs/CapaActionsMenuBtn";
import InsightsIcon from "@mui/icons-material/Insights";
import {ACTIONS, useDrawerMenu} from "../../../../providers/DrawerMenuProvider";

const CapaEjesMuro = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu()

    const [capaEjeMuro, setCapaEjeMuro] = useState();
    const [ejeMuro, setEjeMuro] = useState();
    const [loadingGroup, setLoadingGroup] = useState(false);

    const [openEditCapaDialog, setOpenEditCapaDialog] = useState(false);
    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

    const toastId = React.useRef(null);
    const notify = (mensaje) =>
        (toastId.current = toast(`${mensaje}`, { autoClose: false }));
    const update = (mensaje, tipo) => {
        toast.update(toastId.current, {
            render: `${mensaje}`,
            type: tipo ? tipo : toast.TYPE.INFO,
            autoClose: 5000,
        });
    };

    useEffect(() => {
        let mounted = true;
        mounted && cargarCapas();
        return () => mounted = false;
    }, [id]);

    const cargarCapas = () => {
        setLoadingGroup(true);
        ejesmuroService.getCapa(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setCapaEjeMuro(res.data);
                } else {
                    console.log(res)
                }
                setLoadingGroup(false);
            })
    };

    useEffect(() => {
        loadEjeMuro();
    }, [capaEjeMuro]);

    const loadEjeMuro = () => {
        if(capaEjeMuro) {
            ejesmuroService.getEjeMuroById(capaEjeMuro.ejeMuroId)
                .then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        setEjeMuro(res.data);
                    } else {
                        console.log(res)
                    }
                    setLoadingGroup(false);
                })
        }
    }

    const handleClickEditCapa = () => {
        setOpenEditCapaDialog(true);
    };

    const handleClickDeleteItem = () => {
        setOpenDeleteItemDialog(true);
    };

    const handleCloseEditCapaDialog = () => {
        setOpenEditCapaDialog(false);
    };

    const handleCloseDeleteItemDialog = () => {
        setOpenDeleteItemDialog(false);
    };

    const handleEditCapaDTMs = (nombre, url) => {
        handleCloseEditCapaDialog();
        setLoadingGroup(true);
        notify("Editando Capa Eje de Muro...")
        ejesmuroService.editarCapa({
            id: id,
            nombre,
            url,
            ejeMuroId: capaEjeMuro.ejeId,
            fechaCreado: capaEjeMuro.fechaCreado
        })
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    cargarCapas();
                    update(
                        "La Capa se editó correctamente 👌",
                        toast.TYPE.SUCCESS
                    );
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al editar la Capa 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
                setLoadingGroup(false);
            })
    };

    const handleDeleteItem = () => {
        handleCloseDeleteItemDialog();
        notify("Eliminando Capa Eje de Muro...")
        ejesmuroService.eliminarCapa(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    update(
                        "La Capa se elimió correctamente 👌",
                        toast.TYPE.SUCCESS
                    );
                    setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU})
                    navigate("/")
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al eliminar la Capa 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
                setLoadingGroup(false);
            })

    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    };

    return (
        <div className="area-container">
            {loadingGroup ?
                <div className='loading'><CircularProgress /></div> :
                <>
                    <div className="head-area">
                        <div className="head-name">
                            <InsightsIcon/>
                            <h2 className="name-area">{capaEjeMuro ? capaEjeMuro.nombre : 'Capa de Eje de Muro inexistente'}</h2>
                        </div>

                        {capaEjeMuro
                            ? <CapaActionsMenuBtn
                                editarAction={handleClickEditCapa}
                                eliminarAction={handleClickDeleteItem}
                            />
                            : null}
                    </div>
                    <>
                        <p> {ejeMuro && <b>Eje de Muro:</b>} {ejeMuro ? ejeMuro.key : '-'}</p>
                        <p> {capaEjeMuro && <b>Id de Publicación:</b>} {capaEjeMuro ? capaEjeMuro.url : ''}</p>
                        <p> {capaEjeMuro && <b>Fecha de
                            Creado:</b>} {capaEjeMuro ? moment(capaEjeMuro.fechaCreado).utc().format("DD/MM/YYYY hh:mm:ss") : '-'}</p>
                    </>
                </>
            }

            <DeleteItemDialog
                open={openDeleteItemDialog}
                handleClose={handleCloseDeleteItemDialog}
                handleDeleteItem={handleDeleteItem} />
            {openEditCapaDialog
                ? <EditarCapaDialog
                    open={openEditCapaDialog}
                    handleClose={handleCloseEditCapaDialog}
                    handleEditarCapa={handleEditCapaDTMs}
                    capa={capaEjeMuro}
                /> : null}
        </div>
    );
}
export default CapaEjesMuro;


