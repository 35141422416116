import axios from '../config/axiosInstance';
import {acelerometrosBaseUrl, geotecniaBaseUrl, planesCargaBaseUrl} from '../config/config';
import errorHandle from './tratarError';

export const dashboardService = {
    getDashboardAcelerometros: async () => {
        return axios('/dashboard', {
            baseURL: acelerometrosBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((err) => err)
    },
    getDashboardPiezometroResumen: () => {
        return axios('/api/v1/dashboard/resumen', {
            baseURL: geotecniaBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((err) => err)
    },
    getDashboardPlanCarga: () => {
        return axios('/api/v1/planes-carga/get-capas-cantidad/5', {
            baseURL: planesCargaBaseUrl,
            method: 'get'
        }).then((result) => result)
            .catch((err) => err)
    },
}