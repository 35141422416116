// import axios from 'axios';
import axios from "../config/axiosInstance";
import { ejesMuroBaseUrl } from "../config/config";
import errorHandle from "./tratarError";

export const ejesmuroService = {
  crearGrupoEjesMuro: ({ nombre }) => {
    return axios("api/v1/ejes-muro/grupos", {
      baseURL: ejesMuroBaseUrl,
      method: "post",
      data: { nombre },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGruposEjesMuro: () => {
    return axios("api/v1/ejes-muro/grupos", {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: (id) => {
    return axios(`/api/v1/ejes-muro/grupos/${JSON.stringify(id)}`, {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  updateGrupo: ({ id, nombre }) => {
    return axios(`/api/v1/ejes-muro/grupos/${id}`, {
      baseURL: ejesMuroBaseUrl,
      data: { id, nombre },
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  deleteGrupo: (id) => {
    return axios(`/api/v1/ejes-muro/grupos/${id}`, {
      baseURL: ejesMuroBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getEjesMuro: async () => {
    return axios("/api/v1/ejes-muro", {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getEjeMuro: (id) => {
    return axios(`/api/v1/ejes-muro/${id}`, {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getEjeMuroById: (id) => {
    return axios(`/api/v1/ejes-muro/ejes/${id}`, {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  updateEjeMuro: (data) => {
    return axios(`/api/v1/ejes-muro/${data.ejesmuroId}`, {
      baseURL: ejesMuroBaseUrl,
      data,
      method: "put",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarEjeMuro: (data) => {
    return axios(`/api/v1/ejes-muro/grupos/${data.grupoEjeId}/ejes/${data.ejeId}`, {
      baseURL: ejesMuroBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  crearEjeMuro: (data) => {
    return axios("/api/v1/ejes-muro", {
      baseURL: ejesMuroBaseUrl,
      method: "post",
      data,
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  descargarEjeMuro: (data) => {
    return axios(
      `/api/v1/ejes-muro/grupos/${data.grupoEjesId}/ejes/${data.ejeId}/descargar`,
      {
        baseURL: ejesMuroBaseUrl,
        method: "get",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ ejeId, nombre, url, creado }) => {
    return axios("api/v1/ejes-muro/capas", {
      baseURL: ejesMuroBaseUrl,
      method: "post",
      data: {
        ejeMuroId: ejeId,
        nombre,
        url,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  uploadEjeMuro: ({ idGrupo, formData }) => {
    return axios(`api/v1/ejes-muro/grupos/${idGrupo}/ejes/upload`, {
      baseURL: ejesMuroBaseUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    })
      .then((result) => result)
      .catch((error) => {
        const err = errorHandle(error);
        return err;
      });     
  },
  getCapas: async () => {
    return axios("api/v1/ejes-muro/capas", {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/v1/ejes-muro/capas/${id}`, {
      baseURL: ejesMuroBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, ejeMuroId, fechaCreado}) => {
    return axios(`api/v1/ejes-muro/capas/${id}`, {
      baseURL: ejesMuroBaseUrl,
      method: "put",
      data: {
        id,
        nombre,
        url,
        ejeMuroId,
        fechaCreado
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/v1/ejes-muro/capas/${id}`, {
      baseURL: ejesMuroBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
};
