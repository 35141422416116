import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import "./AddEstacionMeteorologicaDialog.css";
import SeleccionarCoordenadas from "../SeleccionarCoordenadas";
import { getCoordenadas } from "../../../utils/ll2utm";
import {MenuItem, Select} from "@mui/material";

const AddEstacionMeteorologica = ({ open, handleClose, handleSubmit, currentEstacionMet = null, }) => {
  const [estacionMet, setEstacionMet] = useState({
    identifier: currentEstacionMet?.etiqueta || "",
    location_East: currentEstacionMet?.ubicacion?.x || "",
    location_North: currentEstacionMet?.ubicacion?.y || "",
  });
  const [tipoCoordenada, setTipoCoordenada] = useState("geograficas");

  const resetValues = () => {
    setEstacionMet({
      identifier: "",
      location_East: "",
      location_North: "",
    });
  };

  const handleChangeEtiqueta = (event) => {
    const newData = event.target.value !== '' ? event.target.value : null
    setEstacionMet({
      ...estacionMet,
      identifier: newData
    });
  }

  const handleChangeLocation_East = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setEstacionMet({
      ...estacionMet,
      location_East: newData
    });
  }

  const handleChangeLocation_North = (event) => {
    const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
    setEstacionMet({
      ...estacionMet,
      location_North: newData
    });
  }

  const close = () => {
    resetValues();
    handleClose();
  };

  const handleChangeCoordenadas = (value) => {
    setTipoCoordenada(value);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>
          {`${currentEstacionMet ? "Editar" : "Adicionar"} Estación`}
        </DialogTitle>
        <DialogContent
          className="add-dialog-content"
          style={{ paddingTop: "1rem" }}
        >
          <div className="piezometro-container">
                <TextField
                  margin="dense"
                  id="identifier"
                  label="Identificación de Estación Meteorológica"
                  fullWidth
                  value={estacionMet.identifier}
                  onChange={handleChangeEtiqueta}
                />

                <InputLabel
                  id="geo-ubication-label"
                  style={{ marginTop: "0.8rem" }}
                >
                  Ubicación geográfica
                </InputLabel>
                <div className="geo-ubication-group">
                  <SeleccionarCoordenadas
                    value={tipoCoordenada}
                    onChange={handleChangeCoordenadas}
                  />
                  <div className="coordinates">
                    <TextField
                      margin="dense"
                      id="location_East"
                      type="number"
                      label={
                        tipoCoordenada === "geograficas"
                          ? "Longitud"
                          : "Este [m]"
                      }
                      fullWidth
                      value={estacionMet.location_East}
                      onChange={handleChangeLocation_East}
                    />
                    <TextField
                      margin="dense"
                      id="location_North"
                      type="number"
                      label={
                        tipoCoordenada === "geograficas"
                          ? "Latitud"
                          : "Norte [m]"
                      }
                      fullWidth
                      value={estacionMet.location_North}
                      onChange={handleChangeLocation_North}
                    />
                  </div>
                </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const { location_East, location_North } = getCoordenadas(
                tipoCoordenada,
                  estacionMet.location_East,
                  estacionMet.location_North
              );
              handleSubmit({
                identifier: estacionMet.identifier,
                location_East: location_East,
                location_North: location_North,
              });
              close();
            }}
          >
            {`${currentEstacionMet ? "Aceptar" : "Adicionar"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEstacionMeteorologica;
