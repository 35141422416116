// import axios from 'axios';
import axios from "../config/axiosInstance";
import {grietasBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const grietasService = {
  crearGrupo: async ({ nombre }) => {
    try {
      const result = await axios("/grietas/api/grietas/grupos", {
        method: "post",
        data: { nombre },
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGrupos: async () => {
    try {
      const result = await axios("/grietas/api/grietas/grupos", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGrupo: async (id) => {
    try {
      const result = await axios(`/grietas/api/grietas/grupos/${JSON.stringify(id)}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  updateGrupo: async ({ id, nombre }) => {
    try {
      const result = await axios(`/grietas/api/grietas/grupos/${id}`, {
        data: { id, nombre },
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  deleteGrupo: async (id) => {
    try {
      const result = await axios(`/grietas/api/grietas/grupos/${id}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getGrietas: async () => {
    return axios("/grietas/api/grietas/grupos", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrieta: async (id) => {
    try {
      const result = await axios(`/grietas/api/grietas/${id}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  updateGrieta: async (data) => {
    try {
      const result = await axios(`/grietas/api/grietas/${data.ejesmuroId}`, {
        data,
        method: "put",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  eliminarGrieta: async (data) => {
    try {
      const result = await axios(`/grietas/api/grietas/grupos/${data.grupoId}/grieta/${data.id}`, {
        method: "delete",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  crearGrieta: async (data) => {
    try {
      const result = await axios("/grietas/api/grietas", {
        method: "post",
        data,
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  descargar: async (data) => {
    try {
      const result = await axios(
        `/grietas/api/grietas/grupos/${data.grupoId}/descargar-grieta/${data.id}`,
        {
          method: "get",
        }
      );
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  /**Capas */
  publicarCapa: ({ id, nombre, url, creado }) => {
    return axios("api/grietas/capa", {
      baseURL: grietasBaseUrl,
      method: "post",
      data: {
        levantamientoGrietasId: id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  upload: async ({ idGrupo, formData }) => {
    try {
      const result = await axios(`/grietas/api/grietas/grupos/${idGrupo}/upload-grieta`, {
        method: "post",
        data: formData,
      });
      return result;
    } catch (error) {
      const err = errorHandle(error);
      return err;
    }
  },
  getCapas: async () => {
    try {
      const result = await axios("/grietas/api/grietas/capas", {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  getCapa: async (id) => {
    return axios(`/grietas/api/grietas/capas/${id}`, {
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, url, creado, levantamientoGrietasId}) => {
    return axios(`/grietas/api/grietas/capas/${id}`, {
      method: "put",
      data: {
        levantamientoGrietasId,
        id,
        nombre,
        url,
        fechaCreado: creado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/grietas/capa/${id}`, {
      baseURL: grietasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapaByLevantamientoId: async (levantamientoId) => {
    return axios(`api/grietas/capa-by-levantamientoId/${levantamientoId}`, {
      baseURL: grietasBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
