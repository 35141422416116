import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const SeleccionarCoordenadas = ({ onChange, value }) => {
  const handleChange = (event) => {
    onChange && onChange(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value ? value : "geograficas"}
        onChange={handleChange}
      >
        <FormControlLabel
          value="slc"
          control={<Radio />}
          label="Sistema Local Collahuasi"
        />
        <FormControlLabel value="utm" control={<Radio />} label="WGS84 UTM" />
        <FormControlLabel
          value="geograficas"
          control={<Radio />}
          label="Coordenadas Geográficas"
        />
      </RadioGroup>
    </FormControl>
  );
};
export default SeleccionarCoordenadas;
