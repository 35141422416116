import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import "./AddAcelerometroDialog.css";

import { getCoordenadas } from "../../../utils/ll2utm";
import SeleccionarCoordenadas from "../SeleccionarCoordenadas";

const AddAcelerometroDialog = ({
  open,
  handleClose,
  handleSubmit,
  currentAcelerometro = null
}) => {
  const [acelerometro, setAcelerometro] = useState({
    identifier: currentAcelerometro?.etiqueta || "",
    location_East: currentAcelerometro?.ubicacion?.x || "",
    location_North: currentAcelerometro?.ubicacion?.y || "",
    cota: currentAcelerometro?.cota || "",
    alertX: currentAcelerometro?.umbralAcelerometro?.alertaX || "",
    alertY: currentAcelerometro?.umbralAcelerometro?.alertaY || "",
    alertZ: currentAcelerometro?.umbralAcelerometro?.alertaZ || "",
    alarmX: currentAcelerometro?.umbralAcelerometro?.alarmaX || "",
    alarmY: currentAcelerometro?.umbralAcelerometro?.alarmaY || "",
    alarmZ: currentAcelerometro?.umbralAcelerometro?.alarmaZ || "",
  });
  const [tipoCoordenada, setTipoCoordenada] = useState("geograficas");

  console.log("ACELE", currentAcelerometro)

  const resetValues = () => {
    setAcelerometro({
      identifier: "",
      location_East: "",
      location_North: "",
      cota: "",
      alertX: "",
      alertY: "",
      alertZ: "",
      alarmaX: "",
      alarmaY: "",
      alarmaZ: "",
    });
  };

  const handleChangeAcelerometro = (event) => {
    const { id, value } = event.target;
    setAcelerometro({
      ...acelerometro,
      [id]: value,
    });
  };

  const close = () => {
    resetValues();
    handleClose();
  };

  const handleChangeCoordenadas = (value) => {
    setTipoCoordenada(value);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>
          {`${currentAcelerometro ? "Editar" : "Adicionar"} Acelerómetro`}
        </DialogTitle>
        <DialogContent className="add-dialog-content">
          <div className="acelerometro-container">
                <TextField
                  margin="dense"
                  id="identifier"
                  label="Identificación de acelerómetro"
                  fullWidth
                  size="small"
                  value={acelerometro.identifier}
                  onChange={handleChangeAcelerometro}
                />
                <TextField
                    margin="dense"
                    id="cota"
                    label="Cota"
                    fullWidth
                    size="small"
                    value={acelerometro.cota}
                    onChange={handleChangeAcelerometro}
                />
                <InputLabel
                  id="geo-ubication-label"
                  style={{ marginTop: "0.8rem" }}
                >
                  Ubicación geográfica
                </InputLabel>
                <div className="geo-ubication-group">
                  <SeleccionarCoordenadas
                    value={tipoCoordenada}
                    onChange={handleChangeCoordenadas}
                  />
                  <div className="coordinates">
                    <TextField
                      margin="dense"
                      id="location_East"
                      size="small"
                      label={
                        tipoCoordenada === "geograficas" ? "Longitud" : "Este [m]"
                      }
                      fullWidth
                      value={acelerometro.location_East}
                      onChange={handleChangeAcelerometro}
                    />
                    <TextField
                      margin="dense"
                      id="location_North"
                      size="small"
                      label={
                        tipoCoordenada === "geograficas" ? "Latitud" : "Norte [m]"
                      }
                      fullWidth
                      value={acelerometro.location_North}
                      onChange={handleChangeAcelerometro}
                    />
                  </div>
                </div>

                <InputLabel
                    id="umbral-configuration-label"
                    style={{ marginTop: "0.5rem" }}
                >
                  Configurar umbrales
                </InputLabel>
                <div className="umbral-configuration-group">
                  <TextField
                      margin="dense"
                      id="alertX"
                      type="number"
                      label="Alerta en X"
                      fullWidth
                      size="small"
                      value={acelerometro.alertX}
                      onChange={handleChangeAcelerometro}
                  />

                  <TextField
                      margin="dense"
                      id="alarmX"
                      label="Alarma en X"
                      type="number"
                      fullWidth
                      size="small"
                      value={acelerometro.alarmX}
                      onChange={handleChangeAcelerometro}
                  />

                  <TextField
                      margin="dense"
                      id="alertY"
                      label="Alerta en Y"
                      type="number"
                      fullWidth
                      size="small"
                      value={acelerometro.alertY}
                      onChange={handleChangeAcelerometro}
                  />

                  <TextField
                      margin="dense"
                      id="alarmY"
                      label="Alarma en Y"
                      type="number"
                      fullWidth
                      size="small"
                      value={acelerometro.alarmY}
                      onChange={handleChangeAcelerometro}
                  />

                  <TextField
                      margin="dense"
                      id="alertZ"
                      label="Alerta en Z"
                      type="number"
                      fullWidth
                      size="small"
                      value={acelerometro.alertZ}
                      onChange={handleChangeAcelerometro}
                  />

                  <TextField
                      margin="dense"
                      id="alarmZ"
                      label="Alarma en Z"
                      type="number"
                      fullWidth
                      size="small"
                      value={acelerometro.alarmZ}
                      onChange={handleChangeAcelerometro}
                  />
                </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const { location_East, location_North } = getCoordenadas(
                tipoCoordenada,
                acelerometro.location_East,
                acelerometro.location_North
              );
              handleSubmit({
                identifier: acelerometro.identifier,
                latitude: location_North.toString().replace(",", "."),
                longitude: location_East.toString().replace(",", "."),
                cota: acelerometro.cota,
                alertX: acelerometro.alertX,
                alertY: acelerometro.alertY,
                alertZ: acelerometro.alertZ,
                alarmX: acelerometro.alarmX,
                alarmY: acelerometro.alarmY,
                alarmZ: acelerometro.alarmZ
              });
              close();
            }}
          >
            {`${currentAcelerometro ? "Aceptar" : "Adicionar"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddAcelerometroDialog;
