import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import PublicadoIcon from '@mui/icons-material/FlagOutlined';
import BatimetriasBlackIcon from '../../../../assets/batimetriasBlack.png';
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import { batimetriasService } from '../../../../services/BatimetriasService';
import AddElementoDialog from '../../../Dialogs/Batimetrias/AddElementoDialog';
import {toast} from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import GrupoMenuBtn from "../../../Dialogs/Batimetrias/GrupoMenuBtn";
import EditarGrupoDialog from "../../../Dialogs/Batimetrias/EditarGrupoDialog";
import ElementoMenuBtn from "../../../Dialogs/Batimetrias/ElementoMenuBtn";
import ImportarOrtofotoDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/ImportarOrtofotoDialog";
import ImportarIsobataDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/ImportarIsobataDialog";
import CrearMapaDistanciaDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/CrearMapaDistanciaDialog";
import CrearMapaFactorFormaDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/CrearMapaFactorFormaDialog";
import CrearMapaSuperficieRelaveDialog
    from "../../../Dialogs/Batimetrias/DatosBatimetrias/CrearMapaSuperficieRelaveDialog";
import CrearDatosSuperficieDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/CrearDatosSuperficieDialog";
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";
import Reload from "../../../../assets/reloadIconBlack.png";
import {TableFooter, TablePagination} from "@mui/material";
import {MostrarDatosMision} from "../../../Dialogs/Batimetrias/MostrarDatosMision";
import EditarDatosSuperficieDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/EditarDatosSuperficieDialog";
import EditarMapaDistanciaDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/EditarMapaDistanciaDialog";
import EditarMapaSuperficieRelaveDialog
    from "../../../Dialogs/Batimetrias/DatosBatimetrias/EditarMapaSuperficieRelaveDialog";
import EditarMapaFactorFormaDialog from "../../../Dialogs/Batimetrias/DatosBatimetrias/EditarMapaFactorFormaDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#343434",
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Batimetrias = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [grupoBatimetria, setGrupoBatimetria] = useState(null);
    const [misionesBatimetria, setMisionesBatimetria] = useState(null);
    const [currentElemento, setCurrentElemento] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [openEditarGrupoDialog, setOpenEditarGrupoDialog] = useState(false);
    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [importandoDatos, setImportandoDatos] = useState({ importando: false, status: 'Importando' });
    const [openImportatOrtofotos, setOpenImportarOrtofotos] = useState(false);
    const [openImportatIsobata, setOpenImportarIsobata] = useState(false);
    const [openCrearMapaDistancia, setOpenCrearMapaDistancia] = useState(false);
    const [openCrearMapaFactorForma, setOpenCrearMapaFactorForma] = useState(false);
    const [openCrearMapaSuperficieRelave, setOpenCrearMapaSuperficieRelave] = useState(false);
    const [openCrearDatosSuperficie, setOpenCrearDatosSuperficie] = useState(false);
    const [openEditarMapaDistancia, setOpenEditarMapaDistancia] = useState(false);
    const [openEditarMapaFactorForma, setOpenEditarMapaFactorForma] = useState(false);
    const [openEditarMapaSuperficieRelave, setOpenEditarMapaSuperficieRelave] = useState(false);
    const [openEditarDatosSuperficie, setOpenEditarDatosSuperficie] = useState(false);
    const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();
    const [openMostrarDatosMision, setOpenMostrarDatosMision] = useState(false);
    const [mapaFactorForma, setMapaFactorForma] = useState(null)
    const [mapaDistancia, setMapaDistancia] = useState(null)
    const [datosSuperficie, setDatosSuperficie] = useState(null)
    const [mapaSuperficieRelave, setMapaSuperficieRelave] = useState(null)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const toastId = React.useRef(null);
    const notify = (mensaje) =>
        (toastId.current = toast(`${mensaje}`, { autoClose: false }));
    const update = (mensaje, tipo) => {
        toast.update(toastId.current, {
            render: `${mensaje}`,
            type: tipo ? tipo : toast.TYPE.INFO,
            autoClose: 5000,
        });
    };

    useEffect(() => {
        let mounted = true;
        mounted && cargar();
        return () => mounted = false;
    }, [id]);

    const cargar = () => {
        setCargando(true);
        batimetriasService.getGrupoBatimetriaById(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setGrupoBatimetria(res.data.grupos);
                } else {
                    console.error(res);
                }
                setCargando(false);
            })
        batimetriasService.getMisionesBatimetriaByGrupoId(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setMisionesBatimetria(res.data.misionesBatimetria);
                    //console.log("MISION", res.data.misionesBatimetria);
                } else {
                    console.error(res);
                }
            })
    }

    const handleClickDeleteItem = (medicion) => {
        setCurrentElemento(medicion);
        setOpenDeleteItemDialog(true);
    };

    const handleCloseDeleteItemDialog = () => {
        setOpenDeleteItemDialog(false);
    };

    const handleDeleteItem = () => {
        handleCloseDeleteItemDialog();
        if(currentElemento) {
            batimetriasService.eliminarMisionBatimetria(currentElemento.id)
                .then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        navigate('/');
                    } else { console.log(res) }
                })
                .catch(err => {
                    console.error(err)
                })
        }else {
            batimetriasService.eliminarGrupo(id)
                .then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU});
                        navigate('/');
                    } else { console.log(res) }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    };

    //menu-option-row
    const handleClickAdd = () => {
        setOpenAddDialog(true);
    }

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleAdicionar = ({fechaMedicion}) => {
        handleCloseAddDialog(false)
        notify("Adicionando nueva Misión de Batimetría...")
            batimetriasService.crearMisionBatimetria({ grupoId: id, fechaMedicion: fechaMedicion })
                .then(res => {
                    if (res.status >= 200 && res.status < 300) {
                        cargar();
                        update(
                            "La Misión de Batimetría se creo correctamente 👌",
                            toast.TYPE.SUCCESS
                        );
                        setImportandoDatos({ importando: false, status: "Hecho" })
                    } else {
                        console.log(res)
                        update(
                            `Ocurrio un error al crear la Misión de atimetría 🤯 ${res.message}`,
                            toast.TYPE.ERROR
                        );
                        setImportandoDatos({ importando: false, status: "Fallido" })
                    }
                });
    }

    const handleClickEditarGrupo = () => {
        setOpenEditarGrupoDialog(true);
    };

    const handleCloseEditarGrupoDialog = () => {
        setOpenEditarGrupoDialog(false);
    };

    const handleEditarGrupo = ({name} ) => {
        handleCloseEditarGrupoDialog(false)
        notify("Editando Batimetría...")
        batimetriasService.actualizarGrupo({grupoId: id, nombre: name})
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    cargar();
                    update(
                        "La Batimetría se editó corretamente 👌",
                        toast.TYPE.SUCCESS
                    );
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al editar la Batimetría 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
            })
    }

    const handleClickMostrarDatosMision = (medicion) => {
        setCurrentElemento(medicion)
        setOpenMostrarDatosMision(true);
    }

    const handleCloseMostrarDatosMision = () => {
        setCurrentElemento(null)
        setOpenMostrarDatosMision(false);
    }

    const handleClickImportarOrtofotos = (medicion) => {
        setCurrentElemento(medicion)
        setOpenImportarOrtofotos(true);
    };

    const handleCloseImportarOrtofotos = () => {
        setOpenImportarOrtofotos(false);
    };

    const handleImportarOrtofotos = ({geoServerWorkSpace, ficheros}) => {
        handleCloseImportarOrtofotos(false)
        setImportandoDatos({ importando: true, status: "Importando" })
        const formData = new FormData()
        formData.append("UploadFile", ficheros)
        formData.append("GeoServerWorkSpace", geoServerWorkSpace)
        notify("Importando Datos...")
        batimetriasService.importarOrtofoto({
            misionId: currentElemento.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Los datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los datos 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const handleClickImportarIsobata = (medicion) => {
        setCurrentElemento(medicion)
        setOpenImportarIsobata(true);
    };

    const handleCloseImportarIsobata = () => {
        setOpenImportarIsobata(false);
    };

    const handleImportarIsobata = ({ficheros}) => {
        handleCloseImportarIsobata(false)
        setImportandoDatos({ importando: true, status: "Importando" })
        const formData = new FormData()
        formData.append("UploadFile", ficheros)
        notify("Importando Datos...")
        batimetriasService.importarIsobata({
            misionId: currentElemento.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Los datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los datos 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const handleClickCrearMapaDistancia = (medicion) => {
        setCurrentElemento(medicion)
        setOpenCrearMapaDistancia(true);
    };

    const handleCloseCrearMapaDistancia = () => {
        setOpenCrearMapaDistancia(false);
    };

    const handleCrearMapaDistancia = ({ distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7, fichero }) => {
        handleCloseCrearMapaDistancia(false)
        notify("Creando Mapa de Distancia...")
        batimetriasService.crearMapaDistancia({
            grupoId: id,
            misionId: currentElemento.id,
            itemId: "",
            urlStorage: "",
            distanciaSpigot2,
            distanciaSpigot3,
            distanciaSpigot6,
            distanciaSpigot7
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron lso datos del nuevo Mapa de Distancia corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero) {
                    handleImportarMapaDistancia({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al crear el nuevo Mapa de Distancia 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleClickEditarMapaDistancia = (medicion) => {
        setCurrentElemento(medicion)
        setOpenEditarMapaDistancia(true);
    };

    const handleCloseEditarMapaDistancia = () => {
        setOpenEditarMapaDistancia(false);
    };

    const handleEditarMapaDistancia = ({ itemId, urlStorage, distanciaSpigot2, distanciaSpigot3, distanciaSpigot6, distanciaSpigot7, fichero }) => {
        handleCloseEditarMapaDistancia(false)
        notify("Editando Mapa de Distancia...")
        batimetriasService.editarMapaDistancia({
            grupoId: id,
            misionId: currentElemento.id,
            itemId,
            urlStorage,
            distanciaSpigot2,
            distanciaSpigot3,
            distanciaSpigot6,
            distanciaSpigot7
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron lso datos del nuevo Mapa de Distancia corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero) {
                    handleImportarMapaDistancia({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al editar el nuevo Mapa de Distancia 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleImportarMapaDistancia = ({fichero}) => {
        console.log('FICHERO', fichero);
        const formData = new FormData()
        formData.append("UploadFile", fichero)
        notify("Importando Datos...")
        batimetriasService.importarMapaDistancia({
            misionId: currentElemento.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Los datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({importando: false, status: "Hecho"})
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los datos 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({importando: false, status: "Fallido"})
            }
        });
    }

    const handleClickCrearMapaFacForma = (medicion) => {
        setCurrentElemento(medicion)
        setOpenCrearMapaFactorForma(true);
    };

    const handleCloseCrearMapaFactorForma = () => {
        setOpenCrearMapaFactorForma(false);
    };

    const handleCrearMapaFactorForma = ({ area, largo, anchoPromedio, factorForma, fichero }) => {
        handleCloseCrearMapaFactorForma(false)
        notify("Creando Mapa Factor Forma...")
        batimetriasService.crearMapaFactorForma({
            grupoId: id,
            misionId: currentElemento.id,
            itemId: "",
            urlStorage: "",
            area,
            largo,
            anchoPromedio,
            factorForma
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron lso datos del nuevo Mapa Factor de Forma corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero){
                    handleImportarMapaFactorForma({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al crear el nuevo Mapa Factor de Forma 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleClickEditarMapaFacForma = (medicion) => {
        setCurrentElemento(medicion)
        setOpenEditarMapaFactorForma(true);
    };

    const handleCloseEditarMapaFactorForma = () => {
        setOpenEditarMapaFactorForma(false);
    };

    const handleEditarMapaFactorForma = ({ itemId, urlStorage, area, largo, anchoPromedio, factorForma, fichero }) => {
        handleCloseEditarMapaFactorForma(false)
        notify("Editando Mapa Factor Forma...")
        batimetriasService.crearMapaFactorForma({
            grupoId: id,
            misionId: currentElemento.id,
            itemId,
            urlStorage,
            area,
            largo,
            anchoPromedio,
            factorForma
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron lso datos del nuevo Mapa Factor de Forma corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero){
                    handleImportarMapaFactorForma({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al editar el nuevo Mapa Factor de Forma 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleImportarMapaFactorForma = ({fichero}) => {
        const formData = new FormData()
        formData.append("UploadFile", fichero)
        notify("Importando Datos...")
        batimetriasService.importarMapaFactorForma({
            misionId: currentElemento.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Los datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los datos 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const handleClickCrearMapaSuperficieRelave = (medicion) => {
        setCurrentElemento(medicion)
        setOpenCrearMapaSuperficieRelave(true);
    };

    const handleCloseCrearMapaSuperficieRelave = () => {
        setOpenCrearMapaSuperficieRelave(false);
    };

    const handleCrearMapaSuperficieRelave = ({ superficieOcupacionRelave, fichero }) => {
        handleCloseCrearMapaSuperficieRelave(false)
        notify("Creando Mapa Factor Forma...")
        batimetriasService.crearMapaSuperficieRelave({
            grupoId: id,
            misionId: currentElemento.id,
            itemId: "",
            urlStorage: "",
            superficieOcupacionRelave
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron los datos del nuevo Mapa Factor de Forma corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero) {
                    handleImportarMapaSuperficieRelave({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al crear el nuevo Mapa Factor de Forma 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleClickEditarMapaSuperficieRelave = (medicion) => {
        setCurrentElemento(medicion)
        setOpenEditarMapaSuperficieRelave(true);
    };

    const handleCloseEditarMapaSuperficieRelave = () => {
        setOpenEditarMapaSuperficieRelave(false);
    };

    const handleEditarMapaSuperficieRelave = ({ itemId, urlStorage, superficieOcupacionRelave, fichero }) => {
        handleCloseEditarMapaSuperficieRelave(false)
        notify("Editando Mapa Factor Forma...")
        batimetriasService.crearMapaSuperficieRelave({
            grupoId: id,
            misionId: currentElemento.id,
            itemId,
            urlStorage,
            superficieOcupacionRelave
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron los datos del nuevo Mapa Factor de Forma corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero) {
                    handleImportarMapaSuperficieRelave({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al Editar el nuevo Mapa Factor de Forma 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleImportarMapaSuperficieRelave = ({fichero}) => {
        const formData = new FormData()
        formData.append("UploadFile", fichero)
        notify("Importando Datos...")
        batimetriasService.importarMapaSuperficieRelave({
            misionId: currentElemento.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Los datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los datos 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const handleClickCrearDatosSuperficie = (medicion) => {
        setCurrentElemento(medicion)
        setOpenCrearDatosSuperficie(true);
    };

    const handleCloseCrearDatosSuperficie = () => {
        setOpenCrearDatosSuperficie(false)
    };

    const handleCrearDatosSuperficie = ({ superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
                                            cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
                                            volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro, fichero }) => {
        handleCloseCrearDatosSuperficie(false)
        notify("Creando Datos de Superficie...")
        batimetriasService.crearDatosSuperficie({
            grupoId: id,
            misionId: currentElemento.id,
            urlStorageAgua: "", itemIdAgua: "", urlStorageCuerposAgua: "", itemIdCuerposAgua: "",
            superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
            cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
            volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron los Datos Superficie corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero) {
                    handleImportarDatosSuperficie({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al crear los Datos de Superficie 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleClickEditarDatosSuperficie = (medicion) => {
        setCurrentElemento(medicion)
        setOpenEditarDatosSuperficie(true);
    };

    const handleCloseEditarDatosSuperficie = () => {
        setOpenEditarDatosSuperficie(false)
    };

    const handleEditarDatosSuperficie = ({ urlStorageAgua, itemIdAgua, urlStorageCuerposAgua, itemIdCuerposAgua, superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
                                            cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
                                            volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro, fichero }) => {
        handleCloseEditarDatosSuperficie(false)
        notify("Editando Datos de Superficie...")
        batimetriasService.editarDatosSuperficie({
            grupoId: id,
            misionId: currentElemento.id,
            urlStorageAgua, itemIdAgua, urlStorageCuerposAgua, itemIdCuerposAgua,
            superficieCuerpoAgua, superficieEspejoAgua, superficiePeloAgua, perimetro, cotaPeloAgua,
            cotaProfundidadMaxima, zonaBajaProfundidad291, zonaBajaProfundidad08, profundidadMaxima,
            volumenPeloAgua, volumenPeloAgua1Metro, volumenPeloAgua2Metro
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Se salvaron los Datos Superficie corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                if(fichero) {
                    handleImportarDatosSuperficie({fichero})
                }
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al editar los Datos de Superficie 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
            }
        });
    }

    const handleImportarDatosSuperficie = ({fichero}) => {
        const formData = new FormData()
        formData.append("UploadFile", fichero)
        notify("Importando Datos...")

        batimetriasService.importarDatosSuperficie({
            misionId: currentElemento.id,
            formData
        }).then(res => {
            if (res.status >= 200 && res.status < 300) {
                setCurrentElemento(null);
                cargar();
                update(
                    "Los datos se importaron corretamente 👌",
                    toast.TYPE.SUCCESS
                );
                setImportandoDatos({ importando: false, status: "Hecho" })
            } else {
                console.log(res)
                update(
                    `Ocurrio un error al importar los datos 🤯 ${res.message}`,
                    toast.TYPE.ERROR
                );
                setImportandoDatos({ importando: false, status: "Fallido" })
            }
        });
    }

    const renderOrtofoto = (ortofoto) => {
        if(ortofoto === null)
            return (
                <Tooltip title="No existe Ortofoto creado">
                    <span>--</span>
                </Tooltip>
            );

        if (ortofoto.geoserverName || ortofoto.url)
            return(
                <Tooltip title="Datos de Ortofoto publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Ortofoto no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const renderIsobata = (isobata) => {
        if(isobata === null)
            return (
                <Tooltip title="No existe Isobata creado">
                    <span>--</span>
                </Tooltip>
            );

        if (isobata.itemId)
            return(
                <Tooltip title="Datos de Isobata publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Isobata no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const renderSuperficieAgua = (superficieAgua) => {
        if(superficieAgua === null)
            return (
                <Tooltip title="No existe Superficie de Agua creado">
                    <span>--</span>
                </Tooltip>
            );

        if (superficieAgua.itemIdAgua)
            return(
                <Tooltip title="Datos de Superficie de Agua publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Superficie de Agua no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const renderSuperficieCuerpoAgua = (superficieAgua) => {
        if(superficieAgua === null)
            return (
                <Tooltip title="No existe Superficie de Cuerpo de Agua creado">
                    <span>--</span>
                </Tooltip>
            );

        if (superficieAgua.itemIdCuerposAgua)
            return(
                <Tooltip title="Datos de Superficie de Cuerpo de Agua publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Superficie de Cuerpo de Agua no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const renderMapaSuperficieRelave = (mapaSuperficieRelave) => {
        if(mapaSuperficieRelave === null)
            return (
                <Tooltip title="No existe Mapa de Superficie de Relave creado">
                    <span>--</span>
                </Tooltip>
            );

        if (mapaSuperficieRelave.itemId)
            return(
                <Tooltip title="Datos de Mapa de Superficie de Relave publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Mapa de Superficie de Relave no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const renderMapaFactorForma = (mapaFactorForma) => {
        if(mapaFactorForma === null)
            return (
                <Tooltip title="No existe Mapa de Factor de Forma creado">
                    <span>--</span>
                </Tooltip>
            );

        if (mapaFactorForma.itemId)
            return(
                <Tooltip title="Datos de Mapa de Factor de Forma publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Mapa de Factor de Forma no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const renderMapaDistancia = (mapaDistancia) => {
        if(mapaDistancia === null)
            return (
                <Tooltip title="No existe Mapa de Distancia creado">
                    <span>--</span>
                </Tooltip>
            );

        if (mapaDistancia.itemId)
            return(
                <Tooltip title="Datos de Mapa de Distancia publicado">
                    <PublicadoIcon fontSize="small" />
                </Tooltip>
            )
        else{
            return (
                <Tooltip title="Datos de Mapa de Distancia no publicado">
                    <PublicadoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )
        }
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - misionesBatimetria.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderTabla = () => {
        if (rowsPerPage > 0) {
            return <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">No.</StyledTableCell>
                            <StyledTableCell align="center">Fecha Medición</StyledTableCell>
                            <StyledTableCell align="center">Ortofoto</StyledTableCell>
                            <StyledTableCell align="center">Isobata</StyledTableCell>
                            <StyledTableCell align="center">Superficie de Agua</StyledTableCell>
                            <StyledTableCell align="center">Mapa Superficie Relave</StyledTableCell>
                            <StyledTableCell align="center">Mapa Factor Forma</StyledTableCell>
                            <StyledTableCell align="center">Mapa Distancia</StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? misionesBatimetria.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : misionesBatimetria
                        ).map((medicion, index) => (
                            <StyledTableRow key={medicion.id}>
                                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                <StyledTableCell align="center">{medicion.fechaMedicion ? moment(medicion.fechaMedicion).utc().format("DD-MM-YYYY") : '-'}</StyledTableCell>
                                <StyledTableCell align="center">
                                    { renderOrtofoto(medicion.ortofoto) }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    { renderIsobata(medicion.isobata) }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    { renderSuperficieAgua(medicion.datosSuperficieAgua) }
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    { renderSuperficieCuerpoAgua(medicion.datosSuperficieAgua) }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    { renderMapaSuperficieRelave(medicion.mapaSuperficieRelave) }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    { renderMapaFactorForma(medicion.mapaFactorForma) }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    { renderMapaDistancia(medicion.mapaDistancia) }
                                </StyledTableCell>

                                <StyledTableCell align="right">
                                    <ElementoMenuBtn
                                        importarOrtofotoAction={() => handleClickImportarOrtofotos(medicion)}
                                        importarIsobataAction={() => handleClickImportarIsobata(medicion)}
                                        crearDatosSuperficie={() => handleClickCrearDatosSuperficie(medicion)}
                                        crearMapaDistancia={() => handleClickCrearMapaDistancia(medicion)}
                                        crearMapaFactorForma={() => handleClickCrearMapaFacForma(medicion)}
                                        crearMapaSuperficieRelave={() => handleClickCrearMapaSuperficieRelave(medicion)}
                                        editarDatosSuperficie={() => handleClickEditarDatosSuperficie(medicion)}
                                        editarMapaDistancia={() => handleClickEditarMapaDistancia(medicion)}
                                        editarMapaFactorForma={() => handleClickEditarMapaFacForma(medicion)}
                                        editarMapaSuperficieRelave={() => handleClickEditarMapaSuperficieRelave(medicion)}
                                        mostrarDatosMisionBatimetria={() => handleClickMostrarDatosMision(medicion)}
                                        eliminarAction={() => handleClickDeleteItem(medicion)}
                                        disabled={importandoDatos.importando}
                                        mision ={medicion}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>))
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: misionesBatimetria.length }]}
                                colSpan={12}
                                count={misionesBatimetria.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        } else {
            return <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">No existen puntos de descargas</StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        }
    }

    return (
        <div className="area-container">
            {cargando ?
                <div className='loading'><CircularProgress /></div> :
                <>
                    <div className="head-area">
                        <div className="head-name">
                            <img src={BatimetriasBlackIcon} alt="" width={18} height={18} />
                            <h2 className="name-area">{grupoBatimetria ? grupoBatimetria.nombre : 'Errores al cargar la Batimetría'}</h2>
                        </div>

                        {grupoBatimetria
                            ?
                            <div className="actionsButtons">
                                <Button
                                    onClick={cargar}
                                    sx={{ mr: "0px", color: "white", display: "block" }}
                                >
                                    <img src={Reload} alt="" />
                                </Button>
                                <GrupoMenuBtn
                                    editarAction={handleClickEditarGrupo}
                                    eliminarAction={() => handleClickDeleteItem(null)}
                                />
                            </div>
                            : null}
                    </div>

                    {misionesBatimetria ?
                        <>
                            <Button
                                disabled={importandoDatos.importando}
                                variant="contained"
                                className="btn-add"
                                onClick={() => handleClickAdd(null)}
                            >{importandoDatos.importando && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}Adicionar
                            </Button>

                            <div className="container-table">
                                {renderTabla()}
                            </div>
                        </> : null}
                </>
            }

            <DeleteItemDialog
                open={openDeleteItemDialog}
                handleClose={handleCloseDeleteItemDialog}
                handleDeleteItem={handleDeleteItem} />
            {openEditarGrupoDialog
                ? <EditarGrupoDialog
                    open={openEditarGrupoDialog}
                    handleClose={handleCloseEditarGrupoDialog}
                    handleSubmit={handleEditarGrupo}
                    nombre={grupoBatimetria.nombre}
                /> : null}
            {openAddDialog ?
                <AddElementoDialog
                    open={openAddDialog}
                    handleClose={handleCloseAddDialog}
                    handleSubmit={handleAdicionar}
                /> : null}
            {openImportatOrtofotos ?
                <ImportarOrtofotoDialog
                    open={openImportatOrtofotos}
                    handleClose={handleCloseImportarOrtofotos}
                    handleSubmit={handleImportarOrtofotos}
                /> : null}
            {openImportatIsobata ?
                <ImportarIsobataDialog
                    open={openImportatIsobata}
                    handleClose={handleCloseImportarIsobata}
                    handleSubmit={handleImportarIsobata}
                /> : null}
            {openCrearMapaDistancia ?
                <CrearMapaDistanciaDialog
                    open={openCrearMapaDistancia}
                    handleClose={handleCloseCrearMapaDistancia}
                    handleSubmit={handleCrearMapaDistancia}
                /> : null}
            {openEditarMapaDistancia ?
                <EditarMapaDistanciaDialog
                    open={openEditarMapaDistancia}
                    handleClose={handleCloseEditarMapaDistancia}
                    handleSubmit={handleEditarMapaDistancia}
                    mapaDistancia={currentElemento.mapaDistancia}
                /> : null}
            {openCrearMapaFactorForma ?
                <CrearMapaFactorFormaDialog
                    open={openCrearMapaFactorForma}
                    handleClose={handleCloseCrearMapaFactorForma}
                    handleSubmit={handleCrearMapaFactorForma}
                /> : null}
            {openEditarMapaFactorForma ?
                <EditarMapaFactorFormaDialog
                    open={openEditarMapaFactorForma}
                    handleClose={handleCloseEditarMapaFactorForma}
                    handleSubmit={handleEditarMapaFactorForma}
                    mapaFactorForma={currentElemento.mapaFactorForma}
                /> : null}
            {openCrearMapaSuperficieRelave ?
                <CrearMapaSuperficieRelaveDialog
                    open={openCrearMapaSuperficieRelave}
                    handleClose={handleCloseCrearMapaSuperficieRelave}
                    handleSubmit={handleCrearMapaSuperficieRelave}
                /> : null}
            {openEditarMapaSuperficieRelave ?
                <EditarMapaSuperficieRelaveDialog
                    open={openEditarMapaSuperficieRelave}
                    handleClose={handleCloseEditarMapaSuperficieRelave}
                    handleSubmit={handleEditarMapaSuperficieRelave}
                    superficieRelave={currentElemento.mapaSuperficieRelave}
                /> : null}
            {openCrearDatosSuperficie ?
                <CrearDatosSuperficieDialog
                    open={openCrearDatosSuperficie}
                    handleClose={handleCloseCrearDatosSuperficie}
                    handleSubmit={handleCrearDatosSuperficie}
                /> : null}
            {openEditarDatosSuperficie ?
                <EditarDatosSuperficieDialog
                    open={openEditarDatosSuperficie}
                    handleClose={handleCloseEditarDatosSuperficie}
                    handleSubmit={handleEditarDatosSuperficie}
                    datosSuperficie={currentElemento.datosSuperficieAgua}
                /> : null}
            {openMostrarDatosMision ?
                <MostrarDatosMision
                    open={openMostrarDatosMision}
                    handleClose={handleCloseMostrarDatosMision}
                    misionBatimetriaActual={currentElemento}
                /> : null}
        </div>
    );
}
export default Batimetrias;
