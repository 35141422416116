import axios from "../config/axiosInstance";
import {estacionesMetBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const estacionesMetService = {
    /* GRUPOS ESTACIONES */
    getGroups: async () => {
        return axios("/api/estacion-meteorologica/grupos", {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    createGroup: async ({ nombre, areaId }) => {
        try {
            const result = await axios("/api/estacion-meteorologica/grupo", {
                baseURL: estacionesMetBaseUrl,
                method: "post",
                data: { nombre, areaId },
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    getGroup: async (grupoId) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/grupo/${grupoId}`, {
                baseURL: estacionesMetBaseUrl,
                method: "get",
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    updateGroup: async (data) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/grupos/${data.grupoId}`, {
                baseURL: estacionesMetBaseUrl,
                data: data,
                method: "put",
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    publicarGroup: async ({ grupoId }) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/grupos/${grupoId}/publicar`, {
                baseURL: estacionesMetBaseUrl,
                data: { grupoId },
                method: "post",
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    deleteGroup: (grupoId) => {
        return axios(`/api/estacion-meteorologica/grupo/${grupoId}`, {
            baseURL: estacionesMetBaseUrl,
            data: {grupoId},
            method: "delete",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    /* ESTACIONES */
    getEstacionesByGrupoId: async ({grupoId}) => {
            return await axios(`/api/estacion-meteorologica/estaciones/grupos/{grupoId}`, {
                baseURL: estacionesMetBaseUrl,
                method: "get"
            }).then((result) => result)
                .catch((error) => errorHandle(error));
    },
    crearEstacionesMet: async (data) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/estacion`, {
                baseURL: estacionesMetBaseUrl,
                method: "post",
                data: data
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    editarEstacionesMet: async (data) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/estacion/grupo/${data.grupoId}/estacion/${data.estacionId}`, {
                baseURL: estacionesMetBaseUrl,
                method: "put",
                data: data
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    crearExcelConfig: async (data) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/estacion/${data.estacionId}/excel-config`, {
                baseURL: estacionesMetBaseUrl,
                method: "post",
                data: data
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    editarExcelConfig: async (data) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/estacion/${data.estacionId}/excel-config`, {
                baseURL: estacionesMetBaseUrl,
                method: "put",
                data: data
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    importarDatos: async ({estacionId, formData }) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/estacion/${estacionId}/importar-datos`, {
                baseURL: estacionesMetBaseUrl,
                method: "post",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                },
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    deleteEstacion: async (estacionId) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/estacion/${estacionId}`, {
                baseURL: estacionesMetBaseUrl,
                method: "delete",
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    getConfiguracionExcelByEstacion: async ({estacionId}) => {
        return axios(`/api/estacion-meteorologica/estacion/${estacionId}/excel-config`, {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    /* CAPAS */
    getCapas: async () => {
        return axios("/api/estacion-meteorologica/capas", {
            baseURL: estacionesMetBaseUrl,
            method: "get",
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    getCapa: async (id) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/capas/${id}`, {
                baseURL: estacionesMetBaseUrl,
                method: "get",
            });
            return result;
        } catch (error) {
            const err = errorHandle(error);
            return err;
        }
    },
    editarCapa: async ({id, nombre, itemId, fechaCreado }) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/capas/${id}`, {
                baseURL: estacionesMetBaseUrl,
                method: "put",
                data: {
                    id,
                    nombre,
                    itemId,
                    fechaCreado
                },
            });
            return result;
        } catch (error) {
            const err = errorHandle(error);
            return err;
        }
    },
    eliminarCapa: async ({ capaId }) => {
        try {
            const result = await axios(`/api/estacion-meteorologica/capas/${capaId}`, {
                baseURL: estacionesMetBaseUrl,
                method: "delete",
            });
            return result;
        } catch (error) {
            const err = errorHandle(error);
            return err;
        }
    },
};