import React, { useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import '../AddElementoDialog.css'

const FILE_TYPES = ".tif";
const FILE_TEXT = "Seleccione un archivo (*.tif)";

const CrearMapaFactorFormaDialog = ({ open, handleClose, handleSubmit, }) => {
    const [fichero, setFichero] = useState();
    const [area, setArea] = useState()
    const [largo, setLargo] = useState()
    const [anchoPromedio, setAnchoPromedio] = useState()
    const [factorForma, setFactorForma] = useState()

    const hiddenFileInput = useRef(null);

    const handleArea = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setArea(newData);
    }

    const handleLargo = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setLargo(newData);
    }

    const handleAnchoPromedio = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setAnchoPromedio(newData);
    }

    const handleFactorForma = (event) => {
        const newData = event.target.value !== '' ? parseFloat(event.target.value) : null
        setFactorForma(newData);
    }

    const close = () => {
        handleClose();
    }

    const handleClickImportar = (event) => {
        hiddenFileInput.current.click();
    };

    const cargarFichero = (event) => {
        setFichero(event.target.files[0]);
    }

    const crearMapaDistancia = () => {
        handleSubmit({ area, largo, anchoPromedio, factorForma, fichero })
    }

    return (
        <div>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Crear Datos Mapa Factor de Forma</DialogTitle>
                <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
                    <Stack spacing={3}>
                        <div>
                            <TextField
                                margin="dense"
                                id="area"
                                label="Area"
                                fullWidth
                                value={area}
                                type="number"
                                onChange={handleArea}
                            />

                            <TextField
                                margin="dense"
                                id="largo"
                                label="Largo"
                                fullWidth
                                value={largo}
                                type="number"
                                onChange={handleLargo}
                            />

                            <TextField
                                margin="dense"
                                id="anchoPromedio"
                                label="Ancho Promedio"
                                fullWidth
                                value={anchoPromedio}
                                type="number"
                                onChange={handleAnchoPromedio}
                            />

                            <TextField
                                margin="dense"
                                id="factorForma"
                                label="Factor de Forma"
                                fullWidth
                                value={factorForma}
                                type="number"
                                onChange={handleFactorForma}
                            />

                            <InputLabel id="select-datos-label">Archivo</InputLabel>
                            <div className="select-datos">
                                <input
                                    ref={hiddenFileInput}
                                    type='file'
                                    style={{ display: 'none' }}
                                    // accept={FILE_TYPES}
                                    onChange={cargarFichero}
                                />
                                <Button
                                    size="sm"
                                    className="rounded mr-2"
                                    color={"primary"}
                                    variant="contained"
                                    onClick={handleClickImportar}
                                    style={{ marginRight: '8px' }}
                                >
                                    Selecionar
                                </Button>
                                <span>{fichero ? fichero.name : FILE_TEXT}</span>
                            </div>
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        close();
                    }}>
                        Cancelar
                    </Button>
                    <Button disabled={!fichero || !area  || !largo || !anchoPromedio || !factorForma}
                            onClick={crearMapaDistancia}>
                        Subir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default CrearMapaFactorFormaDialog;