import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import {MenuItem, Select} from "@mui/material";
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export const CambiarEstadoPlanCarga = ({open, handleClose, handleSubmit, estadoActualId, estados}) => {
    const [newEstado, setNewEstado] = useState()
    const handleNewState = (event) => {
        setNewEstado(parseInt(event.target.value));
    }

    const onDataSubmit = () => {
        handleSubmit({ newEstado })
    }

    const close = () => {
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle> Camiar Estado Plan Carga </DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid xs={11} item>
                            <InputLabel id="item-type-label">Estado Plan Carga</InputLabel>
                            <Select
                                labelId="item-type-select-label"
                                id="item-type-select"
                                fullWidth
                                value={estadoActualId}
                                label="Estado Plan de Carga"
                                onChange={handleNewState}
                            >
                                {estados?.map((estado, index) => (
                                    <MenuItem key={index} value={estado?.id ? estado?.id : 0}>
                                        {estado?.name ? estado.name : "-"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        Cancelar
                    </Button>
                    <Button disabled={!newEstado} onClick={onDataSubmit}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}