import React, {useState, useRef, useEffect} from 'react';
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#343434",
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#eaeaea",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MostrarPtosLevantamiento = ({open, puntosResponse, handleClose}) => {
    const [puntos, setPuntos] = useState(null);

    useEffect(() => {
        if(puntosResponse !== null || true) {
            setPuntos(puntosResponse);
        }
    }, [puntosResponse])

    const close = () => {
        handleClose();
    }
    const renderTabla = () => {
        if (puntos !== null && puntos.length > 0) {
            return <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Latitud</StyledTableCell>
                            <StyledTableCell align="center">Longitud</StyledTableCell>
                            <StyledTableCell align="center">Altitud</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {puntos.map((punto, index) => (
                            <StyledTableRow key={punto.id}>
                                <StyledTableCell align="center">{punto.y ? punto.y : "-"}</StyledTableCell>
                                <StyledTableCell align="center">{punto.x ? punto.x : '-'}</StyledTableCell>
                                <StyledTableCell align="center">{punto.z ? punto.z : '-'}</StyledTableCell>
                            </StyledTableRow>))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        } else {
            return <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">No existen puntos de de levantamiento</StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        }
    }

    return(
        <div>
            <Dialog open={open} onClose={close}>
                <DialogTitle>Puntos de Levantamiento</DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>
                    <div className="container-table">
                        {renderTabla()}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MostrarPtosLevantamiento;