import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import DeleteItemDialog from '../../../Dialogs/DeleteItemDialog';
import { auxiliaresService } from '../../../../services/AuxiliaresService';
import EditarCapaDialog from "../../../Dialogs/Auxiliares/EditarCapaDialog";
import {toast} from "react-toastify";
import CapaActionsMenuBtn from "../../../Dialogs/CapaActionsMenuBtn";
import AuxiliaresBlackIcon from '../../../../assets/slopeBlack.png';
import {useDrawerMenu, ACTIONS} from "../../../../providers/DrawerMenuProvider";

const CapaAuxiliares = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu()

    const [capaActual, setCapaActual] = useState();
    const [loadingGroup, setLoadingGroup] = useState(false);

    const [openEditCapaDialog, setOpenEditCapaDialog] = useState(false);
    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);

    const toastId = React.useRef(null);
    const notify = (mensaje) =>
        (toastId.current = toast(`${mensaje}`, { autoClose: false }));
    const update = (mensaje, tipo) => {
        toast.update(toastId.current, {
            render: `${mensaje}`,
            type: tipo ? tipo : toast.TYPE.INFO,
            autoClose: 5000,
        });
    };

    useEffect(() => {
        let mounted = true;
        mounted && cargarCapas();
        return () => mounted = false;
    }, [id]);

    const cargarCapas = () => {
        setLoadingGroup(true);
        auxiliaresService.getCapa(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    setCapaActual(res.data);
                } else {
                    console.log(res)
                }
                setLoadingGroup(false);
            })
    };

    const handleClickEditCapa = () => {
        setOpenEditCapaDialog(true);
    };

    const handleClickDeleteItem = () => {
        setOpenDeleteItemDialog(true);
    };

    const handleCloseEditCapaDialog = () => {
        setOpenEditCapaDialog(false);
    };

    const handleCloseDeleteItemDialog = () => {
        setOpenDeleteItemDialog(false);
    };

    const handleEditCapaActual = (nombre, url) => {
        handleCloseEditCapaDialog();
        setLoadingGroup(true);
        notify("Editando Capa Auxiliar...")
        let capa = capaActual;
        auxiliaresService.editarCapa({
            id: id,
            nombre,
            url,
            auxiliarId: capa.auxiliarId,
            fechaCreado: capa.fechaCreado
        })
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    cargarCapas();
                    update(
                        "La Capa se editó correctamente 👌",
                        toast.TYPE.SUCCESS
                    );
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al editar la Capa 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
                setLoadingGroup(false);
            })
    };

    const handleDeleteItem = () => {
        handleCloseDeleteItemDialog();
        notify("Eliminando Capa Auxiliar...")
        auxiliaresService.eliminarCapa(id)
            .then(res => {
                if (res.status >= 200 && res.status < 300) {
                    update(
                        "La Capa se elimió correctamente 👌",
                        toast.TYPE.SUCCESS
                    );
                    setDrawerMenuContext({action: ACTIONS.CLOSE_ALL_DRAWER_MENU})
                    navigate("/")
                } else {
                    console.log(res)
                    update(
                        `Ocurrio un error al eliminar la Capa 🤯 ${res.message}`,
                        toast.TYPE.ERROR
                    );
                }
                setLoadingGroup(false);
            })

    };

    return (
        <div className="area-container">
            {loadingGroup ?
                <div className='loading'><CircularProgress /></div> :
                <>
                    <div className="head-area">
                        <div className="head-name">
                            <img src={AuxiliaresBlackIcon} alt="" width={18} height={18} />
                            <h2 className="name-area">{capaActual ? capaActual.nombre : 'Capa de Auxiliar inexistente'}</h2>
                        </div>

                        {capaActual
                            ? <CapaActionsMenuBtn
                                editarAction={handleClickEditCapa}
                                eliminarAction={handleClickDeleteItem}
                            />
                            : null}
                    </div>
                    <>
                        <p> {capaActual && <b>Auxiliar:</b>} {capaActual ? capaActual.auxiliar : '-'}</p>
                        <p> {capaActual && <b>Id de Publicación:</b>} {capaActual ? capaActual.url : ''}</p>
                        <p> {capaActual && <b>Fecha de
                            Creado:</b>} {capaActual ? moment(capaActual.fechaCreado).format("DD/MM/YYYY hh:mm:ss") : '-'}</p>
                    </>
                </>
            }

            <DeleteItemDialog
                open={openDeleteItemDialog}
                handleClose={handleCloseDeleteItemDialog}
                handleDeleteItem={handleDeleteItem} />
            {openEditCapaDialog
                ? <EditarCapaDialog
                    open={openEditCapaDialog}
                    handleClose={handleCloseEditCapaDialog}
                    handleEditarCapa={handleEditCapaActual}
                    capa={capaActual}
                /> : null}
        </div>
    );
}
export default CapaAuxiliares;


