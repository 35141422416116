// import axios from 'axios';
import axios from "../config/axiosInstance";
import {puntosDescargaBaseUrl} from "../config/config";
import errorHandle from "./tratarError";

export const puntosDescargaService = {
  crearGrupo: ({ nombre }) => {
    return axios("api/v1/grupos", {
      baseURL: puntosDescargaBaseUrl,
      method: "post",
      data: { nombre, areaId: 1 },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupos: async () => {
    return axios("api/v1/grupos", {
      baseURL: puntosDescargaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getGrupo: async (id) => {
    return axios(`api/v1/grupos/${id}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  actualizarGrupo: async ({ grupoId, nombre }) => {
    return axios(`api/v1/grupos/${grupoId}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "put",
      data: {
        grupoPuntoDescargaId: parseInt(grupoId),
        nombre,
        areaId: 1,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarGrupo: (id) => {
    return axios(`api/v1/grupos/${id}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "delete",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getElementos: async () => {
    return axios("/puntos-descarga/api/", {
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getElemento: async (id) => {
    try {
      const result = await axios(`/puntos-descarga/api/${id}`, {
        method: "get",
      });
      return result;
    } catch (error) {
      return errorHandle(error);
    }
  },
  crearElemento: ({ grupoId, etiqueta }) => {
    return axios("/api/v1/puntos-descarga", {
      baseURL: puntosDescargaBaseUrl,
      method: "post",
      data: {
        grupoPuntoDescargaId: grupoId,
        etiqueta,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  editarElemento: ({ grupoId, puntoId, etiqueta }) => {
    return axios(
      `api/v1/puntos-descarga/grupo/${grupoId}/punto-descarga/${puntoId}`,
      {
        baseURL: puntosDescargaBaseUrl,
        method: "put",
        data: {
          grupoPuntoDescargaId: grupoId,
          puntoDescargaId: puntoId,
          etiqueta,
        },
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  eliminarElemento: ({ grupoId, puntoId }) => {
    return axios(
      `/api/v1/puntos-descarga/grupo/${grupoId}/punto-descarga/${puntoId}`,
      {
        baseURL: puntosDescargaBaseUrl,
        method: "delete",
      }
    )
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getExcelConfiguration: () => {
    return axios(`api/v1/grupos/get-configuration-excel`, {
      baseURL: puntosDescargaBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  crearConfiguracionDeExcel: async ({ initialRow, etiquetaCell, ubicacionNorteCell, ubicacionEsteCell,
                                cotaInferiorCell, cotaSuperiorCell }) => {
    return axios(`api/v1/grupos/create-configuration-excel`, {
      baseURL: puntosDescargaBaseUrl,
      method: "post",
      data: {
        initialRow,
        etiquetaCell,
        ubicacionNorteCell,
        ubicacionEsteCell,
        cotaInferiorCell,
        cotaSuperiorCell
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarConfiguracionDeExcel: async ({ initialRow, etiquetaCell, ubicacionNorteCell, ubicacionEsteCell,
                                cotaInferiorCell, cotaSuperiorCell }) => {
    return axios(`api/v1/grupos/update-configuration-excel`, {
      baseURL: puntosDescargaBaseUrl,
      method: "put",
      data: {
        initialRow,
        etiquetaCell,
        ubicacionNorteCell,
        ubicacionEsteCell,
        cotaInferiorCell,
        cotaSuperiorCell
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  /**Capas */
  publicarCapa: ({ grupoId, nombre, itemId, creado }) => {
    return axios("api/v1/capas", {
      baseURL: puntosDescargaBaseUrl,
      method: "post",
      data: {
        grupoPuntoDescargaId: grupoId,
        nombre,
        itemId,
        creado,
      },
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  subirArchivo: async ({ grupoPuntoDescargaId, formData }) => {
    return axios(
      `api/v1/grupos/${grupoPuntoDescargaId}/importar-dato-punto-descarga`,
      {
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "text/plain",
        },
      }
    )
      .then((result) => {return result;})
      .catch((error) => errorHandle(error));
  },
  subirPlantilla: async ({ grupoPuntoDescargaId, formData }) => {
    return axios(
        `api/v1/grupos/${grupoPuntoDescargaId}/importar-plantilla-csv`,
        {
          baseURL: puntosDescargaBaseUrl,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "text/plain",
          },
        }
    )
        .then((result) => {return result;})
        .catch((error) => errorHandle(error));
  },
  getCapas: async () => {
    return axios("api/v1/capas", {
      baseURL: puntosDescargaBaseUrl,
      method: "get",
    })
      .then((result) => result)
      .catch((error) => errorHandle(error));
  },
  getCapa: async (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "get",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  editarCapa: async ({id, nombre, itemId, creado}) => {
    let capaId = parseInt(id);
    return axios(`api/v1/capas/${id}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "put",
      data: {
        id: capaId,
        nombre,
        itemId,
        creado,
      },
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapa: async (id) => {
    return axios(`api/v1/capas/${id}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
  eliminarCapaByGrupoId: async (grupoId) => {
    return axios(`api/v1/capas/grupo/${grupoId}`, {
      baseURL: puntosDescargaBaseUrl,
      method: "delete",
    })
        .then((result) => result)
        .catch((error) => errorHandle(error));
  },
};
