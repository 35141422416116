import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

export const MostrarOtrosDatosMisionDialog = ({superficieRelaveActual, datosOrtofoto, datosIsobata}) => {
    const [superficieRelave, setSuperficieRelave] = useState((superficieRelaveActual) ? superficieRelaveActual : null);
    const [isobata, setIsobata] = useState((datosIsobata) ? datosIsobata : null);
    const [ortofoto, setOrtofoto] = useState((datosOrtofoto) ? datosOrtofoto : null);

    return (
        <Stack spacing={3}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Superficie Ocupación [m²]: </b>
                        <u>{(superficieRelave.superficieOcupacionRelave) ? superficieRelave.superficieOcupacionRelave : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>ArcGIS ItemId Superficie Relave: </b>
                        <u>{(superficieRelave.itemId) ? superficieRelave.itemId : "-" }</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Blob Storage Url Superficie Relave: </b>
                        <u>{(superficieRelave.urlStorage) ? superficieRelave.urlStorage : "-"}</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>ArcGIS ItemId Isobata: </b>
                        <u>{(isobata.itemId) ? isobata.itemId : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Ortofoto Geoserver Url: </b>
                        <u>{(ortofoto.url) ? ortofoto.url : "-" }</u>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={1}>
                    <Grid xs={5}>
                        <b>Blob Storage Url Isobata: </b>
                        <u>{(isobata.urlStorage) ? isobata.urlStorage : "-"}</u>
                    </Grid>

                    <Grid xs={5}>
                        <b>Ortofoto Geoserver name: </b>
                        <u>{(ortofoto.geoserverName) ? ortofoto.geoserverName : "-" }</u>
                    </Grid>
                </Grid>
            </div>
        </Stack>
    )
}
