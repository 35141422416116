import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import './Index.css'
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Skeleton} from "@mui/material";
import {
    CheckCircleOutline, NotificationsNone,
    QueryBuilder,
    WarningAmber,
    WifiTetheringOffOutlined,
    WifiTetheringOutlined
} from "@mui/icons-material";
import moment from "moment";
import {dashboardService} from "../services/DashboardService";
import {sismosService} from "../services/SismosService";
import {revanchaService} from "../services/RevanchaService";
import DashboardRigth from './DashBoardSections/DashBoardRigth';
import DashboardLeft from './DashBoardSections/DashBoardLeft';
const Dashboard = () => {
    const [resumenDashBoard, setResumenDashBoard] = useState({});
    const [aclerometroDataDashBoard, setAcelerometroDataDashBoard] = useState([]);
    const [sismosDataDashboard, setSismosDataDashboard] = useState([])
    const [revanchasDataDashboard, setRevanchasDataDashboard] = useState([])
    const [dashboardLoaded, setDashboardLoaded] = useState(false)
    const [planesCargaDashBoard, setPlanesCargaDashBoard] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            await getDashBoardPiezometroResumen();
            await getDataAcelerometrosBoard();
            await getTodaySismos();
            await getDataRevanchasBoard();
            await getDataPlanesCargaBoard();
        }

        fetchData();
    }, [dashboardLoaded]);

    const getDashBoardPiezometroResumen = async () => {
        const resumenPiezometroDashString = sessionStorage.getItem("resumenPiezometroDash");
        const resumenPiezometroDash = JSON.parse(resumenPiezometroDashString);

        await dashboardService.getDashboardPiezometroResumen().then(res => {
            if (res.status >= 200 && res.status < 300) {
                const resumenData = res.data.resumen;
                sessionStorage.setItem("resumenPiezometroDash", JSON.stringify(resumenData));
                setResumenDashBoard(resumenData);
                return resumenData;
            } else {
                sessionStorage.setItem("resumenPiezometroDash", JSON.stringify([]));
                return [];
            }
        })
    };

    const getDataAcelerometrosBoard = async () => {
        var dashAcelerometrosString = sessionStorage.getItem("dashBoardAcelerometro");
        var dashAcelerometros = JSON.parse(dashAcelerometrosString);

        await dashboardService.getDashboardAcelerometros().then(response => {
            if (response.status >= 200 && response.status < 300) {
                sessionStorage.setItem("dashBoardAcelerometro", JSON.stringify(response.data));
                setAcelerometroDataDashBoard(response.data);
                return response.data;
            } else {
                console.log(response)
                sessionStorage.setItem("dashBoardAcelerometro", JSON.stringify([]));
                return [];
            }
        });
    }

    const getTodaySismos = async () => {
        var dashSismoString = sessionStorage.getItem("dashBoardSismos");
        var dashSismo = JSON.parse(dashSismoString);

        const fecha = moment(new Date()).format("YYYY-MM-DD");
        let dataSismos = [];
        await sismosService.getSismosRangoFecha({
            desde: fecha,
            hasta: fecha,
        }).then(resSismos => {
            if (resSismos.status >= 200 && resSismos.status < 300) {
                const esArreglo = Array.isArray(resSismos?.data);
                dataSismos = esArreglo
                    ? resSismos.data?.sort(
                        (a, b) => new Date(a.fecha).getTime() < new Date(b.fecha).getTime()
                    )
                    : [];
                sessionStorage.setItem("dashBoardSismos", JSON.stringify(dataSismos));
                setSismosDataDashboard(dataSismos)
            } else {
                console.error(resSismos);
            }
        });
    }

    const getDataRevanchasBoard = async () => {
        var dashRevanchasString = sessionStorage.getItem("dashBoardRevanchas");
        var dashRevanchas = JSON.parse(dashRevanchasString);

        await revanchaService.getGrupos().then(response => {
            if (response.status >= 200 && response.status < 300) {
                sessionStorage.setItem("dashBoardRevanchas", JSON.stringify(response.data));
                setRevanchasDataDashboard(response.data.grupos);
                return response.data;
            } else {
                console.log(response)
                sessionStorage.setItem("dashBoardRevanchas", JSON.stringify([]));
                return [];
            }
        });
    }

    const getDataPlanesCargaBoard = async () => {
        var dashPlanesCargaString = sessionStorage.getItem("dashBoardPlanesCarga");
        var dashPlanesCarga = JSON.parse(dashPlanesCargaString);

        await dashboardService.getDashboardPlanCarga().then(response => {
            if (response.status >= 200 && response.status < 300) {
                sessionStorage.setItem("dashBoardPlanesCarga", JSON.stringify(response.data.capas));
                setPlanesCargaDashBoard(response.data.capas);
                return response.data;
            } else {
                console.log(response)
                sessionStorage.setItem("dashBoardPlanesCarga", JSON.stringify([]));
                return [];
            }
        });
    }

    return (
        <div className="area-container">
            <div className="menu-info">
                <div className="dashboard-info">
                    <div className="dashboard-info-card">
                        <div className="dashboard-info-card-piezometro">
                            <div className="dashboard-card-content">
                                <div className="dashboard-card-left">
                                    <DashboardLeft resumenDashBoard={resumenDashBoard} planesCargaDashBoard={planesCargaDashBoard}/>
                                </div>
                                <div className="dashboard-card-right">
                                    <DashboardRigth  aclerometroDataDashBoard={aclerometroDataDashBoard} sismosDataDashboard={sismosDataDashboard} revanchasDataDashboard={revanchasDataDashboard}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;