import React, { useReducer, createContext, useContext } from "react";

const initialState = {
    systemReferences: [],
};

const ACTIONS = {
    ADD_SYS_REF: "ADD_SYS_REF",
    UPDATE_SYS_REF: "UPDATE_SYS_REF",
    DELETE_SYS_REF: "DELETE_SYS_REF",

};

const reducer = (state, data) => {
    switch (data.action) {
        case ACTIONS.ADD_SYS_REF: {
            const { sysRef } = data.payload;
            return {
                ...state,
                systemReferences: [
                    ...state.systemReferences,
                    {
                        id: sysRef.name,
                        ...sysRef
                    }
                ]
            };
        };

        case ACTIONS.UPDATE_SYS_REF: {
            const { sysRef } = data.payload;
            return {
                ...state,
                systemReferences: state.systemReferences.map(sr => sr.id === sysRef.id ? sysRef : sr)
            };
        };
        
        case ACTIONS.DELETE_SYS_REF: {
            const { id } = data.payload;
            return {
                ...state,
                systemReferences: state.systemReferences.filter(sr => sr.id !== id)
            };
        };

        default:
            throw new Error();
    }
};

const SystemReferencesContext = createContext(initialState);

const SystemReferencesProvider = ({ children }) => {
    const stateAndDispatch = useReducer(reducer, initialState);
    return (
        <SystemReferencesContext.Provider value={stateAndDispatch}>
            {children}
        </SystemReferencesContext.Provider>
    );
}

export const useSystemReferences = () => useContext(SystemReferencesContext);

export { SystemReferencesContext, SystemReferencesProvider, ACTIONS };
