import axios from '../config/axiosInstance';
import {insarBaseUrl} from '../config/config';
import errorHandle from './tratarError';

export const insarService = {
    /**Capturas Insar endpoint*/
    getSeries: async () => {
        try {
            const result = await axios('/insar/api/capturas', {
                method: 'get'
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    getSerie: async (id) => {
        try {
            const result = await axios(`/insar/api/capturas/${id}`, {
                method: 'get'
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    crearSerie: async ({ etiqueta, fechaCreado }) => {
        try {
            const result = await axios('/insar/api/capturas', {
                method: 'post',
                data: { etiqueta, fechaCreado }
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    editarSerie: async ({ id, etiqueta, fechaCreado }) => {
        try {
            const result = await axios(`/insar/api/capturas/${id}`, {
                data: { id, etiqueta, fechaCreado },
                method: 'put',
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    importarSerieTiempo: ({ capturaId, fechaCaptura, uploadFile }) => {
        return axios.postForm(`${insarBaseUrl}/api/capturas/${capturaId}/upload-serie-tiempo`, {
            fechaCaptura,
            uploadFile
        })
            .then((result) => result)
            .catch((error) => errorHandle(error));
    },
    eliminarSerie: async (id) => {
        try {
            const result = await axios(`/insar/api/capturas/${id}`, {
                method: 'delete'
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },

    /**Series de Tiempo endpoint*/
    getSeriesTiempo: async ({ capturaInSARId }) => {
        try {
            const result = await axios(`/insar/api/capturas/${capturaInSARId}/series-tiempo`, {
                method: 'get'
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    getSerieTiempo: async ({ capturaInSARId, serieTiempoId }) => {
        try {
            const result = await axios(`/insar/api/capturas/${capturaInSARId}/series-tiempo/${serieTiempoId}`, {
                method: 'get'
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    crearSerieTiempo: async ({ fechaCreado, tipoSerieId, uri, capturaInSARId, escala }) => {
        try {
            const result_2 = await axios(`/insar/api/capturas/${capturaInSARId}/series-tiempo`, {
                method: 'post',
                data: { fechaCreado: new Date(fechaCreado).toISOString(), tipoSerieId, uri, capturaInSARId, escala }
            });
            return result_2;
        } catch (error) {
            return errorHandle(error);
        }
    },
    editarSerieTiempo: async ({ uri, storageUri, fechaCaptura, capturaInSARId }) => {
        try {
            const result_2 = await axios(`/insar/api/capturas/${capturaInSARId}/series-tiempo`, {
                method: 'put',
                data: { uri, storageUri, fechaCaptura: new Date(fechaCaptura).toISOString(), capturaInSARId }
            });
            return result_2;
        } catch (error) {
            return errorHandle(error);
        }
    },
    editarEscalaColores: async (capturaId, escala) => {
        try {
            const result = await axios(`/insar/api/capturas/${capturaId}/series-tiempo/update-escala-colores`, {
                data: { capturaId, escala },
                method: 'put',
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },

    /**Deformaciones Acumuladas endpoint*/
    getDeformacionesAcumuladas: async ({ capturaInSARId }) => {
        try {
            const result = await axios(`/insar/api/DeformacionesAcumuladas/${capturaInSARId}`, {
                method: 'get'
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    editarDeformacionesAcumuladas: async ({ deformacionId, periodo, uri = null, storageUri = null, escala }) => {
        try {
            const result = await axios(`/insar/api/DeformacionesAcumuladas/${deformacionId}`, {
                method: 'put',
                data: { deformacionId, periodo, uri, storageUri, escala }
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    importarDeformacionAcumulada: async ({ capturaId, formData }) => {
        try {
            const result = await axios(`/insar/api/DeformacionesAcumuladas/${capturaId}/upload-deformacion-acumulada`, {
                method: "post",
                data: formData,
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    crearDeformacionesAcumuladas: async ({ capturaId, periodo, uri, storageUri, escala }) => {
        try {
            const result = await axios('/insar/api/DeformacionesAcumuladas', {
                method: 'post',
                data: { periodo, capturaId, uri, storageUri, escala }
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
    eliminarDeformacionesAcumuladas: async (defAcumId) => {
        try {
            const result = await axios(`/insar/api/DeformacionesAcumuladas/${defAcumId}`, {
                method: 'delete',
            });
            return result;
        } catch (error) {
            return errorHandle(error);
        }
    },
};
