import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import InformationIcon from "../../../assets/informationBlack.png";

import Tooltip from "@mui/material/Tooltip";
import { MenuItem } from "@mui/material";

const GrupoMenuBtn = ({
  editarAction,
  eliminarAction,
  importarAction,
  importarExcelAction,
  dejarPublicarAction,
  crearTipoAction,
  publicarAction,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Menu">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            bgcolor: open ? "rgba(0, 0, 0, 0.04)" : "transparent",
          }}
        >
          <img src={InformationIcon} alt="informacionIcon" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            marginLeft: "-5px",
            "& .MuiMenu-list": {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          onClick={() => {
            editarAction && editarAction();
            handleClose();
          }}
        >
          Editar Nombre Grupo
        </MenuItem>
          {/*<MenuItem*/}
          {/*    onClick={() => {*/}
          {/*        crearTipoAction && crearTipoAction();*/}
          {/*        handleClose();*/}
          {/*    }}*/}
          {/*>*/}
          {/*    Adicionar Tipo de Piezometro*/}
          {/*</MenuItem>*/}
          {publicarAction && <MenuItem onClick={() => { publicarAction(); handleClose() }}>Publicar</MenuItem>}
          {dejarPublicarAction && <MenuItem onClick={() => { dejarPublicarAction(); handleClose() }}>Dejar de publicar</MenuItem>}
        <MenuItem
          onClick={() => {
            importarAction && importarAction();
            handleClose();
          }}
        >
          Importar datos
        </MenuItem>
          {/*<MenuItem*/}
          {/*    onClick={() => {*/}
          {/*        importarExcelAction && importarExcelAction();*/}
          {/*        handleClose();*/}
          {/*    }}*/}
          {/*>*/}
          {/*    Importar datos desde Excel*/}
          {/*</MenuItem>*/}
        <MenuItem
          onClick={() => {
            eliminarAction && eliminarAction();
            handleClose();
          }}
        >
          Eliminar
        </MenuItem>
      </Menu>
    </>
  );
};
export default GrupoMenuBtn;
