import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import './AddSectionDialog.css';

import { sectionService } from '../../services/SectionsService';

const AddSectionDialog = ({ open, handleClose, handleAddSection }) => {

  const [areas, setAreas] = useState();
  const [loadingAreas, setLoadingAreas] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [itemType, setItemType] = useState('');
  const [area, setArea] = useState('');
  const [locationX, setLocationX] = useState('');
  const [locationY, setLocationY] = useState('');

  useEffect(() => {
    if (itemType === "sector") {
      loadAreas();
    }
  }, [itemType]);

  const loadAreas = () => {
    setLoadingAreas(true);
    sectionService.getAllAreas()
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setAreas(res.data.areas);
        } else {
          console.error(res)
        }
        setLoadingAreas(false);
      })
  };

  const resetValues = () => {
    setName("");
    setDescription("");
    setItemType("");
    setArea("");
    setLocationX("");
    setLocationY("");
  }

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  }

  const handleChangeItem = (event) => {
    setItemType(event.target.value);
  }

  const handleChangeArea = (event) => {
    setArea(event.target.value);
  }

  const handleChangeLocationX = (event) => {
    setLocationX(event.target.value);
  }

  const handleChangeLocationY = (event) => {
    setLocationY(event.target.value);
  }

  const close = () => {
    resetValues();
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Adicionar sección</DialogTitle>
        <DialogContent className="add-dialog-content" style={{ paddingTop: '1rem' }}>
          <FormControl sx={{ marginTop: 1, minWidth: 120, width: '100%' }}>
            <InputLabel id="item-type-label">Item</InputLabel>
            <Select
              labelId="item-type-select-label"
              id="item"
              value={itemType}
              // defaultValue={10}
              label="Item"
              onChange={handleChangeItem}
            >
              <MenuItem value="area">Área</MenuItem>
              <MenuItem value="sector">Sector</MenuItem>
            </Select>
          </FormControl>
          {
            itemType === "sector" ?
              (
                <FormControl sx={{ marginTop: 1, minWidth: 120, width: '100%' }}>
                  <InputLabel id="item-type-label">Área</InputLabel>
                  <Select
                    labelId="item-type-select-label"
                    id="area"
                    value={area}
                    // defaultValue={10}
                    label="Área"
                    onChange={handleChangeArea}
                  >
                    {
                      loadingAreas ?
                        <MenuItem disabled>Cargando...</MenuItem> :
                        areas?.map(a => (
                          <MenuItem key={a.id} value={a.id}>{a.nombre}</MenuItem>
                        ))
                    }
                  </Select>
                </FormControl>
              ) : null
          }

          <TextField
            margin="dense"
            id="name"
            label="Nombre"
            fullWidth
            value={name}
            onChange={handleChangeName}
          />
          <TextField
            margin="dense"
            id="description"
            label="Descripción"
            multiline
            rows={4}
            fullWidth
            value={description}
            onChange={handleChangeDescription}
          />
          <InputLabel id="location-group-label">Ubicación</InputLabel>
          <div className="location-group">
            <TextField
              margin="dense"
              id="location-x"
              label="X"
              fullWidth
              value={locationX}
              onChange={handleChangeLocationX}
            />
            <TextField
              margin="dense"
              id="location-y"
              label="Y"
              fullWidth
              value={locationY}
              onChange={handleChangeLocationY}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            close();
          }}>
            Cancelar
          </Button>
          <Button onClick={() => {
            handleAddSection({
              nombre: name,
              descripcion: description,
              itemType,
              area,
              ubicacion: {
                x: locationX,
                y: locationY
              }
            });
            close();
          }}>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AddSectionDialog;